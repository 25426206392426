export default {
  // buttons
  YES: 'Yes',
  NO: 'No',
  SUBMIT: 'Submit',
  CALL: 'Call',
  LAUNCH: 'Launch',
  DEACTIVATE: 'Deactivate',
  DEACTIVATE_COMPANY: 'Deactivate Company',
  DEACTIVATE_BRAND: 'Deactivate Brand',

  DONE: 'Done',
  GET_OTP: 'Get OTP',
  GO_BACK: 'Go back',
  GET_EMAIL_OTP: 'GET SECURITY CODE BY EMAIL',
  GET_MOBILE_OTP: 'GET SECURITY CODE BY TEXT',
  LOGIN: 'Login',
  CHECK_IN: 'Check In',
  ENTER_TABLE_NUMBER: 'Enter My Table Number',
  SCAN_TABLE_CODE: 'Scan My Table Code',
  LEAVE_TABLE: 'Leave Table',
  MODIFIER_DETAILS: 'Modifier Details',
  HOLIDAYS: 'Holidays',
  STORE_HOLIDAYS: 'Store Holidays',
  BRAND_HOLIDAYS: 'Brand Holidays',
  UPDATE_STORE_HOLIDAYS: 'Update Store Holidays',
  UPDATE_HOLIDAY: 'Update Holiday',
  ADD_HOLIDAY: 'Add Holiday',
  ADD_STORE_HOLIDAYS: 'Add Store Holidays',
  QUESTION_TARGET: 'Question Target',
  SURVEY_TARGET: 'Survey Target',
  MANAGE_MENU_ITEMS: 'Manage Menu Items',
  CAMPAIGN_OCCURRENCE: 'Campaign Occurrence',
  PUBLISH: 'Publish',
  PUBLISH_CONFIRMATION: 'this will publish the campaign to all recipients. this action cannot be undone. do you want to continue?',
  CAMPAIGN_TRIGGERS: 'Campaign Triggers',
  TRIGGER_TYPE: 'Trigger Type',
  ADD_CAMPAIGN_TRIGGER: 'Add Campaign Triggers',
  EDIT_CAMPAIGN_TRIGGER: 'Edit Campaign Triggers',
  LOADING: 'Loading',
  AMOUNT_OFF: 'Amount off',
  PERCENT_OFF: 'Percent off',
  ADD_MENU_ITEMS: 'Add Menu Items',
  CAMPAIGN_REWARDS: 'Campaign Reward',
  CAMPAIGN_RULES: 'Campaign Rules',
  RESEND_OTP: 'Resend OTP',
  SELECT_VOUCHER: 'Select Voucher',
  RESEND_IN: 'Resend in',
  POSID: 'POS ID',
  SECONDS: 'seconds',
  BACK: 'Back',
  SELECT_ALL: 'Select All',
  CLEAR_ALL: 'Clear All',
  SAVE_CHANGES: 'Save changes',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  YES_CONTINUE: 'Yes, continue',
  YES_DELETE: 'Yes, Delete',
  SMS: 'SMS',
  EMAIL: 'Email',
  SIGN_UP: 'SIGN UP',
  RESET_PASSWORD: 'Reset Password',
  BRAND_CODE: 'Brand Code',
  SUBMITING: 'Submiting',
  SPLIT_EQUALY: 'Split Equaly',
  SELECT_BY_ITEMS: 'Select Items To Pay',
  PREVIOUS: 'Previous',
  SKIP: 'Skip',
  VERIFY: 'Verify',
  CONTINUE: 'Continue',
  TEXT_RECEIPT: 'Text Receipt',
  EMAIL_RECEIPT: 'Email Receipt',
  NO_RECEIPT: 'No Receipt',
  YOUR_RECEIPT: 'Your Receipt',
  GENERATE_OTP: 'Generate OTP',
  OTHER: 'Other',
  PAY_NOW: 'Pay Now',
  ADD_TIP: ' Add Tip',
  SAVE: 'Save',
  SAVE_ALL: 'Save All',
  PRICE: 'Price',
  POS_ID: 'POS Id',
  TEST: 'TEST',
  PREVIEW: 'Preview',
  COPY: 'Copy',
  TEST_BTN: 'Test',
  BEGIN_SURVEY: 'BEGIN SURVEY',
  UPLOAD: 'Upload',

  // CHARITY: 'Charity',
  EDIT_CHARITY: 'Edit Charity',
  ENABLE_FIXED_AMOUNT: 'Enable Fixed Amount',
  ENABLE_ROUND_UP: 'Enable Round Up',
  ENABLE_CUSTOM_AMOUNT: 'Enable Custom Amount',
  AMOUNT_1: 'Amount 1',
  AMOUNT_2: 'Amount 2',
  AMOUNT_3: 'Amount 3',
  SYNC_CHECK: 'Sync Check',
  CLOSE_CHECK: 'Close Check',
  MODIFIER_ID: 'Modifier Id',
  ITEM_AMOUNT: 'Item Amount',
  OTHER_CHARGE_AMOUNT: 'Other Charge Amount',
  DISCOUNTAMOUNT: 'Discount Amount',
  SERVICE_CHARGEAMOUNT: 'Service Charge Amount',
  DISPLAY_MESSAGE: 'Display Message',
  NOTIFICATION_EMAIL_ADDRESS: 'Order Notification Email Addresses(enter multiple separated by semi-colon)',
  IMPRESSIONS: 'Impressions',
  TOTAL_IMPRESSIONS: 'Total Impressions',
  COPY_MOBILE_THEME: 'Copy Mobile Theme',
  RESET_THEME: 'Reset Theme',
  SELECT_ALL_STORES: 'Select All Stores',


  // label
  ENTER_EMAILID_MESSAGE: 'Please enter the email you use to sign in to OneDine.',
  SENDING_OTP: 'Sending Otp',
  PLEASE_SELECT_OPTION: 'Please select one of the following options',
  BATTERY_SAVE: 'Battery Save',
  HOME_PAGE: 'Home Page',
  PAYMENT_PAGE: 'Payment Page',
  RECEIPT_PAGE: 'Receipt Page',
  ONLINE_RECEIPT: 'Online Receipt',

  // Form Label
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PHONE_NUMBER: 'Phone Number',
  VEHICLE_INFO_MENU_ITEM: 'Vehicle info menu item',
  ORDER_NOTIFICATION_EMAIL: 'Order Notification Email',
  EMAIL_ADDRESS: 'Email Address',
  ENTER_USERNAME_EMAIL: 'Enter Username or Email',
  ENTER_PASSWORD: 'Enter Password',
  PASSWORD: 'Password',
  ENTER_OTP: 'Enter OTP',
  USER_DATA: 'User Data',
  USER_INFO: 'User Information',
  CHANGE_PASSWORD: 'Change Password',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  COUNTRY_CODE: 'Country Code',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  SUPPORT_COUNTRY_CODE: 'Support Country Code',
  ENTER_MOBILE_NUMBER: 'Enter Mobile Number',
  ENTER_EMAIL_ID: 'Enter Email Id',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  ENTER_FIRST_NAME: 'Enter First name',
  ENTER_LAST_NAME: 'Enter Last name',
  ENTER_DESIRED_USERNAME: 'Enter desired username',
  ENTER_CONFIRM_PASSWORD: 'Enter Confirm Password',
  ENTER_THE_OTP: 'Enter the OTP',
  EMAILS: 'Email',
  CODE: 'Code',
  CITY: 'City',
  COMMENTS: 'Comments',
  BRANCH: 'Branch',
  NAME: 'Name',
  SURNAME: 'Surname',
  CARD_HOLDER: 'Card Holder',
  TABLE_NUMBER: 'Table Number',
  MOBILE_NUMBER: 'Mobile Number',
  CARD_HOLDER_NAME: 'Card Holder Name',
  ADDRESS_TYPE: 'Address type',
  AGREED_CONDITION: 'i agree with the tearms and conditions',
  FORGOT_PASSWORD: 'FORGOT YOUR PASSWORD?',
  DETAILS: 'Details',
  TABLE_MANAGEMENT: 'Table Management',
  TCP_IP_PRINTER: 'TCP/IP Printer',
  FIREBOARD: 'Fireboard',
  FIREBOARD_3_0: 'Fireboard 3.0',
  PRINT_MANAGEMENT: 'Print Management',
  VENUE_MODE: 'Venue Mode',
  CONNECTION: 'Connection',
  NOTIFICATION_EMAIL_ADDRESSES: 'Notification Email Addresses (enter multiple seperated by semi-colon)',
  CAMPAIGN_FROM_EMAIL_NAME: 'Campaign From Email Name',
  CAMPAIGN_FROM_EMAIL_ADDRESS: 'Campaign From Email Address',
  CAMPAIGN_FROM_SMS_NAME: 'Campaign From SMS Name',
  CAMPAIGN_FROM_SMS_COUNTRY_CODE: 'Campaign From SMS Country Code',
  CAMPAIGN_FROM_SMS_PHONE_NUMBER: 'Campaign From SMS Phone Number',
  CAMPAIGN_COMMUNICATION_SETTINGS: 'Campaign Communication Settings',
  PRINTER_OPTION: 'Printer (optional)',
  CHANNEL_URLS: 'Channel Urls',
  CONFIRM_GUEST_DATA_SENT: 'Confirm Guest Data Sent',
  SALES_FORCE_TICKETID: 'Sales Force Ticket Id',
  MARKET_PLACE_NAME_MENU_ITEM: 'Marketplace name menu item',

  // Custom Icons
  MENUS: 'Menus',
  ORDER: 'Order',
  MY_BAG: 'My Bag',
  MORE: 'More',
  MY_CHECK: 'My Check',
  MWEB_SPINNER: 'Spinner',
  CUSTOM_IMAGES: 'Custom Images',
  WELCOME_MSG: 'Welcome Message',
  HERO_IMAGE: 'Hero Image',
  PAYMENT_BUTTON: 'Payment Button',
  IMAGES: 'Images',
  LABELS: 'Labels',
  APP_BUTTON: 'Default Button',
  FAVOURITE_ICON: 'Favorite Icon',
  MOBILE_APP_LABELS: 'Mobile Web Labels',
  SERVER_APP_LABELS: 'Server App Labels',
  SERVER_APP_CUSTOMIZATION: 'Server App Customization',
  CUSTOMIZATION_APP_ICONS: 'Customize App Icons',
  TABLE_TOP_LABELS: 'Table Top Labels',
  KIOSK_LABELS: 'Kiosk Labels',
  LANGUAGE_NAME: 'Language Name',
  LANGUAGE_ENABLE: 'Language Enable',
  TEMPLATES: 'Templates',
  COPY_SERVER_THEME: 'Copy Server Theme',
  COLORS: 'Colors',

  // Navigations Tab labels
  HOME: 'Home',
  OFFERS_PROMOTIONS: 'Offers & Promotions',
  WALLET: 'Wallet',
  ORDER_HISTORY: 'Oder History',
  SIGN_OUT: 'Sign Out',

  // Others
  YOUR_CHECK_TOTAL: 'Your Check Total',
  SUB_TOTAL: 'Sub Total',
  SUBTOTAL: 'Subtotal',
  TAX: 'Tax',
  TAXS: 'Taxes',
  ADD_TAX: 'Add Tax Rate',
  UPDATE_TAX: 'Update Tax Rate',
  TOTAL_AMOUNT: 'Total Amount',
  TOTAL: 'Total',
  TIPS: 'Tip',
  TIP: 'Tip',
  UNPAID_AMOUNT: 'Unpaid Amount',
  TIPS_SETUP: 'Tip Setup',
  TIPS_RULES: 'Tip Rules',
  DISCOUNTS: 'Discounts',
  RESOLUTION: 'Resolution',
  LOCATION: 'Location',
  ACTIONS: 'Actions',
  EXPIRES_ON: 'Expires on:',
  READ_ONLY: '(read only)',
  EACH: ' each',
  YOUR_CHECK_SPLIT: 'Your check has been splited among ',
  MEMBERS: 'members with ',
  YOUR_ITEMS_CHECK_LIST: 'Your items check list',
  SERVICE_CHARGE_AMOUNT: 'Service Charge',
  DISCOUNT_AMOUNT: 'Discount',
  TIP_AMOUNT: 'Tip',
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
  TOTALS: 'TOTAL',
  CHECK_TOTAL: 'Check total',
  ENTER_NAME: 'Name',
  ENTER_INGENICO_USERNAME: 'Ingenico Username',
  ENTER_INGENICO_PASSWORD: 'Ingenico Password',
  ENTER_PRINTER_ADDRESS: 'Printer IP Address',
  ENTER_PRINTER_PORT: 'Printer Port',
  NO_RECORDS_FOUND: 'No Records Found',
  PRINTER_TYPE: 'Printer Type',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
  REFRESH: 'Refresh',
  NO_RECORDS: 'No Records',
  VIEW_REPORT: 'View Report',
  SEARCH: 'Search',
  WELCOME_TO_ONEDINE: 'Welcome to OneDine ...!!!',
  TABLET_PAYMENTS: 'Tablet Payments',
  INGENICO: 'Ingenico',
  CARD_CONNECT_BOLT: 'Card Connect Bolt',
  NMI_PAYMENTS: 'NMI Payments',
  NMI_PAYMENT: 'NMI Payment',
  POS_DEVICE_ID: 'POS Device Id',
  POI_DEVICE_ID: 'POI Device Id',
  PROVISION_DEVICE: 'Provision Device',
  DE_PROVISION_DEVICE: 'De-provision Device',
  DEVICE_CODE: 'Device Code',
  PAYMENT_SENSE: 'Payment Sense',
  HOST_ADDRESS: 'Host Address',

  AVAILABLE: 'Available',
  IN_STOCK: 'In Stock',
  STOCK_QUANTITY: 'Stock Quantity',
  ID: 'Id',
  SELECT_A_DAY: 'Select a Day',
  X: 'x',
  OTP: 'Otp',
  TSYS_DEVICE_ID: 'TSYS_Device_ID',
  TSYS_USERNAME: 'TSYS_Username',
  TSYS_PASSWORD: 'TSYS Password',
  TSYS_MID: 'TSYS_MID',
  TSYS: 'TSYS',
  MOBILE_PAYMENTS: 'Mobile Payments',
  PAYMENT_TYPE: 'Payment Type',
  Test_payment_system: 'Test payment system',
  This_will_save_the_configurations: 'This will save the configurations',
  do_you_want_to_continue: 'do you want to continue',
  PAYTRONIX_LOYALTY_SETUP: 'Paytronix Loyalty Setup',
  PAYTRONIX_GIFT_CARD_SETUP: 'Paytronix Gift Card Setup',
  GIFT_CARD_PAYMENT_BUTTON_TEXT: 'Gift card payment button text',
  SUPPRESS_TIP_CARD_CLASSES: 'Suppress Tip Card Classes',
  OFFER_BUTTON_TEXT: 'Offers button text',
  OFFER_FORM: 'Offer Form',
  UPDATE_OFFER_FORM: 'Update Offer Form',
  API_KEY: 'Api Key',
  API_SECRET: 'Api Secret',
  API_URL: 'API URL',
  API_TOKEN: 'API Token',
  PREPARING_DOWNLOAD: 'Preparing Download',
  DOWNLOAD: 'Download',
  TIP_FIELD_MESSAGE: 'Tip Field Message',
  ACCOUNT_ID: 'Account Id',
  CLIENT_SECRET: 'Client Secret',
  CLIENT_ID: 'Client Id',
  ENROLLMENT_TEXT: 'Enrollment Text',
  SIGNUP_URL: 'SignUp Url',
  SIGNUP_TEXT: 'SignUp Text',
  SIGNIN_URL: 'SignIn Url',
  SIGNIN_TEXT: 'SignIn Text',
  LOCATION_TOKEN: 'Location Token',
  OK: 'OK',
  WELCOME_MESSAGE: 'Welcome message(leave blank to skip this page)',
  CONFIRMATION_MESSAGE: 'Confirmation message(leave blank to skip this page)',
  ROUTING: 'Routing',
  DIVISION: 'Division',
  MERCHANT_ID: 'Merchant Id',
  ONBOARDING_URL: 'Onboarding Url',
  MERCHANT_NUMBER: 'Merchant Number',
  MERCHANT_NAME: 'Merchant Name',
  IMAGE_URl: 'Image Url',
  GIFT_CARD_WALLET_CODE: 'Gift card wallet code',
  COMP_CARD_BIN_RANGE: 'Comp card BIN range',
  BLOCKED_CARDS_BIN_RANGE: 'Blocked cards BIN range',
  PNP_SETUP: 'OneDine Offer Management System setup',
  SUPPORTS_OFFERS: 'Supports offers',
  SUPPORTS_CHECKS_IN_OFFER: 'Supports Check in Offer',
  NO_TABLE_FOUND: 'No Table Found',
  Click_below_to_login_with_username_and_password: 'Click below to login with username and password',
  TABLE_GROUP: 'Table Group',
  CHANGE_A_USER_ROLE: 'This revokes the user access to this company. This action is not reversible.',
  QUESTION_MARK: '?',
  SAVE_CONFIGURATIONS: 'This will save the configurations',
  VISIBILITY: 'Visibility',
  VALIDATING: 'validating',
  CARD_NUMBER: 'card number',
  EXPIRY_DATE: 'Expiry Date',
  CVV: 'Cvv',
  COUNTRY: 'Country',
  ONEDINE_WEAR_APP: 'OneDine Wear App',
  INVALID_CARD_DETAILS: 'Invalid card details',
  INSTRUCTION: 'Instructions',
  SERVER_APP_FB: 'Server App / Fireboard',
  MAUI_SERVER_APP_FB: '2023 Update - Server App / Fireboard',
  OPEN_A_BROWSER: 'Open a browser on your android tablet.',
  NAVIAGTE_SITE: 'Navigate to the site',
  DOWNLOAD_FIREBOARD: 'Download the app by clicking on the link below',
  WEAR_APP: 'Wear App',
  INSTALL_STEPS: 'Installation step will vary',
  CONTACT_SUPPORT: 'Please Contact OneDine Support',
  ONEDINE_MDMD: 'OneDine MDM',
  ONEDINE_SERVERAPP: 'OneDine Server App/Fireboard',
  CLICK_DOWNLOAD: 'Click to download',
  CHANNEL_CONFIGURATION: 'Channel Configuration',
  CHANNEL_SETUP: 'Channel Setup',
  STORE_CHANNELS: 'Store Channels',
  CHANNEL: 'Channel',
  STORE_ID: 'Store Id',
  MAX_PARTY_SIZE: 'Max Party Size',
  UPDATE_CHANNEL: 'Update Channel',
  GENERATE: 'Generate',
  ADD_IMAGE: 'Add Image',
  FREE_FORM_DETAILS: 'Free Form Details',
  NUMBER: 'Number',
  OPEN: 'Open',
  VOID: 'Void',
  OPEN_DATE: 'Open Date',
  CLOSE_DATE: 'Close Date',
  GUEST_COUNT: 'Guest Count',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  ITEMS: 'Items',
  LOYALTY: 'Loyalty',
  CHECK_DETAILS: 'CHECK DETAILS',
  DELETE: 'Delete',
  SURE_DELETE: 'Are you sure you want to delete the',
  CHECK_DATE: 'Check date',
  ORDER_TIME: 'Order_time',
  CHECK_ID: 'Check Id',
  ORDER_DATE: 'Order Date',
  CHECKIN_TIME: 'Checkin time',
  SALES_TAX: 'Tax',
  SALE_REFUND: 'Refund',
  COPYRIGHTS: 'OneDine, LLC © 2019',
  DEVICES: 'Devices',
  EDIT_BRAND: 'Edit_Brand',
  ADD_STORES: 'Add Stores',
  VIEW_STORES: 'View Stores',
  STORES: 'Stores',
  EDIT_STORE: 'Edit store',
  VIEW_DEVICES: 'View devices',
  VIEW: 'View',
  UPDATE_JOB: 'Update Job',
  AUTENTICATING: 'Autenticating',
  ASSIGN_STORE: 'Assign Store to Mall',
  REQUEST: 'Request',
  RESET: 'Reset',
  TEXT_TO_PAY: 'TEXT TO PAY',
  WANT_TO_REQUEST: 'Are you sure you want to request',
  CURRENT_NUMBER: 'CURRENT_NUMBER',
  VALIDATE_SIGNUP: 'Validate signup by Text message',
  SUPPORTS_LOYALTY: 'Supports loyalty',
  SUPPORTS_OFFER: 'Supports offers',
  VALIDATE_LOYALTY_SECURITY: 'Validate Loyalty security code',
  ENABLE_FIRST_NAME_FOR_SIGNUP: 'Enable first name for signup',
  ENABLE_LAST_NAME_FOR_SIGNUP: 'Enable last name for signup',
  REQUIRE_PASSWORD_FOR_SIGNIN: 'Require password for sign in',
  VALIDATE_GIFT_CARD_SECURITY: 'Validate Gift Card security code',
  VALIDATE_GIFT_CARD_SECURITY_ON_CARD_SWIPE: 'Validate Gift Card security code on card swipe',
  SUPPORTS_GIFT_CARD: 'Supports gift card sale',
  SUPPORTS_GIFT_CARD_PAYMENT: 'Supports gift card payment',
  SUPPRESS_TIPS: 'Suppress Tips',
  ACCOUNT_SIGNUP_METHODS_FOR_LOYALTY: 'Account Signup methods for loyalty',
  ACCOUNT_LOOKUP_METHODS_FOR_LOYALTY: 'Account Lookup methods for loyalty',
  GIFT_ENTRY_CARD_METHODS_FOR_LOYALTY: 'Gift Entry Card methods for loyalty',
  GIFT_ENTRY_CARD_METHODS: 'Gift Entry Card methods',
  TENDER_ID: 'Tender Id',
  ACCOUNT_NUMBER: 'Account Number',
  VENDOR_NAME: 'Vendor Name',
  NOTE: 'Note',
  ADDRESS: 'Address',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
  GROUP_NAME: 'Group Name',
  CALORIES: 'Calories',
  CALS_SEPARATOR_START: 'Cals Separator Start',
  CALS_SEPARATOR_END: 'Cals Separator End',
  CALS_FROM: 'Cals From',
  MIN_CALORIES: 'Min Calories',
  MAX_CALORIES: 'Max Calories',
  ALLOW_SPECIAL_INSTRUCTION: 'Allow special instructions',
  DISCLAIMER: 'Disclaimer',
  PRICE_PER_UNIT: 'Price Per Unit',
  ENTERPRISE: 'Enterprise',
  SETTING: 'Setting',
  MINIMUM: 'Minimum',
  MAXIMUM: 'Maximum',
  WEIGHT: 'Weight',
  INCLUDED: 'Included',
  EXCLUDE_MODIFIER: 'Exclude Modifier',
  INCLUDE_MODIFIER: 'Include Modifier',
  ADD_AUTOMATICALLY: 'Add Automatically',
  MOD_FUNCTION: 'Mod Function (POS)',
  HIDE_FROM_MENU: 'Hide from menu',
  APPLY_MOD_FUNCTION: 'Apply Mod Function',
  MODIFIER_FUNCTION: 'Modifier Group',
  ITEM_SORT: 'Item Sort',
  MENU: 'Menu',
  AUTO_INCLUDE_MODIFIER: 'Auto include modifier',
  AUTO_INCLUDE_MENU: 'Auto include menu item',
  MODIFIER: 'Modifier',
  NO_NODE: 'No node found at the given path.',
  NODE_WITH_NO_CHILDREN: 'Path referenced children of node with no children.',
  CANNOT_ADD_TO_CHILDREN_DEFINED_BY_A_FUNCTION: 'Cannot add to children defined by a function',
  NO_NODE_FOUND_WITH_THE_GIVEN_KEY: 'No node found with the given key.',
  NO_SUITABLE: 'No suitable position found to insert.',
  EXTERNAL_ID: 'External Id',
  MASTER_ID: 'Master Id',
  MASTER_NAME: 'Master Name',
  ACTIVATED: 'Activated',
  LAST_ACCESSED_DATE: 'Last Accessed Date',
  ACTIVATION_CODE: 'Activation Code',
  VERSION: 'Version',
  REREGISTER: 'Reregister',
  DEREGISTER: 'Deregister',
  ALLOW_TABS: 'Allow Tabs',
  ALLOW_TABLES: 'Allow Tables',
  ALLOW_TABLE: 'Allow Table',
  ALLOW_OFF_PREMISE: 'Allow Off Premise',
  ALLOW_CASH_PAYMENT_WITHOUT_LOGIN: 'Allow Cash Payment Without Login',
  CHECK_VISIBILITY: 'Check Visibility',
  STATION_GROUPS: 'Station Groups',
  TITLE: 'Title',
  MAX_DISCOUNT_AMOUNT: 'Max Discount Amount ($)',
  MIN_TICKET_AMOUNT: 'Min Ticket Amount ($)',
  LIFETIME_DAYS: 'Lifetime (Days)',
  COUPON_CODE: 'Coupon Code',
  RULE_TYPE: 'Rule Type',
  DISCOUNT_TYPE: 'Discount Type',
  DISCOUNTED_AMOUNT: 'Discount Amount ($)',
  ONLINE_ORDER_SEND_TIME: 'Online Order Send Time',
  SEND_TEXT_TO_PAY_FOR_FAILED_PAYMENTS: 'Send Text To Pay For Failed Payments',
  MIN_ORDER_QUANTITY: 'Min Order Quantity',
  MAX_ORDER_QUANTITY: 'Max Order Quantity',
  POS_AGENT_API_KEY: 'POS Agent API Key',
  POS_AGENT_TARGET_VERSION: 'POS Agent Target Version',
  DISALLOW_IFDISCOUNT_EXISTS: 'Disallow if discount exists',
  ONLY_USE_POS_MENUS: 'Only use POS menus',
  ONLY_AUTO_PRINTOFF_PERMISE_CHECKS: 'Only Auto Print Off-Premise Checks',
  LOCK_PAYMENT_SCREEN: 'Lock payment screen',
  SELECT_SCREEN: 'Select Screen',
  PRICE_LEVEL: 'Price Level',
  ADD_CAROUSEL_IMAGE: 'Add Carousel Image',
  EDIT_CAROUSEL_IMAGE: 'Edit Carousel Image',
  CAROUSEL_IMAGES: 'Carousel Images',
  GLOBAL: 'Global',
  UUID: 'UUID',
  PAYMENT_SYSTEM_NOT_SETUP_DO_YOU_WANT_TO_CONTINUE: 'Payment System not setup. Do you want to continue?',
  API_KEY_LABEL: 'API Key',
  API_SECRET_KEY_LABEL: 'API Secret Key',
  MENU_SCREEN: 'Menu Screen',
  SURVEY_SCREEN: 'Survey Screen',
  ALLOW_VOID: 'Allow Void',
  SOFTWARE_HOUSE_ID: 'Software House ID',
  INSTALLER: 'Installer ID',
  GAME_CARD: 'Game Card',
  CATERING_ORDER_SEND_TIME: 'Catering Order Send Time',

  ALLOW_TEAM_CHECKS: 'Allow Team Checks',
  RESTRICT_TEAM_CHECKS_TO_JOB: 'Restrict Team Checks to Job',
  DONOT_CLOSE_CHECKS_PAYMENT: 'Do not close checks on payment',
  // ALLOW_QR_SCAN: 'Allow QR Scan',
  SUPPRESS_SERVER_APP: 'Suppress Server App',
  // ASSUME_PAYMENT_LIABILITY: 'Assume payment liability',
  SUPPRESS_CHECK_CLOSE_ON_PAYMENT: 'Suppress Check Close on payment',
  JOB_TYPE_ID: 'Job Type ID',
  SHORT_NAME: 'Short Name',
  JOB_TYPE: 'Job Type',
  JOB_SETUP: 'Job setup',
  RESTRICT_TEAM_CHECK: 'Restrict Team Check',
  JOB_LIST: 'Job List',
  MALL_LOGO_IMAGE: 'Venue Logo Image',
  UPLOAD_FILE: 'Upload File',
  SELECT_FONT_FILE: 'Select Font File',
  HEADER_FONT: 'Header Font',
  MALLS: 'Malls',
  NO_STORES_ASSIGNED_TO_THIS_MALL: 'NO STORES ASSIGNED TO THIS MALL',
  COMPANY: 'Company',
  NO_STORES_FOUND_FOR_THIS_BRAND: 'NO STORES FOUND FOR THIS BRAND',
  WELCOME: 'Welcome',
  TEXT_NOTIFICATION_NUMBER: 'TEXT NOTIFICATION NUMBER',
  MENU_ITEM: 'Menu Item',
  USER_NOT_FOUND: 'User not found!',
  RESTRICT_TO_PAYMENT_ONLY: 'Restrict to payment mode only',
  EMPLOYEE_JOB: 'Job',
  EMPLOYEE_JOB_TYPE: 'Job Type',
  ALLOW_EMPLOYEE_JOB_ASSIGNMENT: 'Allow employee job assignment',
  ALLOW_EMPLOYEE_TABLE_ASSIGNMENT: 'Allow employee table assignment',
  LINKED_USER: 'Linked User',
  LINK_EMPLOYEE: 'Link Employee',
  SELECT_MEMBER: 'Select Member',
  ROUTE_BY_ORDER_TYPE: 'Route by order type',
  FONT_FAMILY: 'Font Family',
  HEADER_FONT_FAMILY: 'Header Font Family',
  FONT_SIZE: 'Font Size',
  HEADER_FONT_SIZE: 'Header Font Size',
  CHECKOUT_FONTS: 'Checkout Fonts',
  SELECT_A_ROLE: 'Select a Role',
  SELECT_ROLE_TYPE: 'Select a Role Type',

  // Job
  ALLOW_COMPLETING_PRE_AUTHS: 'Allow Completing Pre Auths',

  // // company
  COMPANY_ID: 'Company Id',
  TAG_TYPES: 'Tag Types',
  TAG_CATEGORY: 'Tag Category',
  COMPANY_NAME: 'Company',
  DESCRIPTION: 'Description',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  ADDRESS_LINE_3: 'Label name for room, suit etc.',
  STATE: 'State',
  USE_SECURITY_CODE: 'USE SECURITY CODE',
  ZIP_CODE: 'Zip Code',
  ZIP_POSTAL_CODE: 'Zip/Postal code',
  UPDATE_COMPANY: 'Update Company',
  ADD_COMPANY: 'Add Company',
  COMPANY_LIST: 'Companies List',
  COMPANY_IMPORT_LIST: 'Import Company List',
  INCLUDE_CHECK_NUMBER: 'Include Check Number',
  INCLUDE_ORDER_TYPE: 'Include Order Type',
  INCLUDE_REVENUE_CENTER: 'Include Revenue Center',
  INCLUDE_SERVER_NUMBER: 'Include Server Number',
  CUSTOM_FOOTER_MESSAGE: 'Custom Footer Message',
  COPY_ACCOUNTING_DATA: 'Copy Accounting Data',
  BRANDS: 'Brands',

  // mall
  MALL_NAME: 'Venue',
  MALL_LIST: 'Venue List',
  ADD_MALL: 'Add Venue',
  UPDATE_MALL: 'Update Venue',
  SINGLE_PAYMENT_ON_CHECKOUT: 'Single Payment On Checkout',
  CONSOLIDATE_MENU: 'Consolidate Menu',
  CONSOLIDATE_CHECKS: 'Consolidate Checks',
  VENUES: 'Venues',
  POST_CHECKS_TO_PRIMARY_STORE: 'Post Checks To Primary Store',

  // Venue
  VENUE: 'Venue',
  ASSIGN_STORE_TO_VENUE: 'Assign store to venue',

  // STORES
  BATCH_SERVICE: 'Batch Services',
  SETUP_BATCH_SERVICE_MESSAGE: 'Batch service not setup. Click the button to setup batch services',
  SETUP: 'Setup',
  CYCLE_BATCH_SERVICE: 'Cycle Batch Service',
  RESTART_SERVICE: 'This will restart the service.',

  // Booking System
  BOOKING_SYSTEM: 'Booking System',
  ENTER_ID: 'Enter ID',

  // BATCH REPORT
  BATCH: 'Batch',
  BRAND: 'Brands',
  STORE: 'Store',


  // Brand
  BRAND_NAME: 'Brand Name',
  UPDATE_BRAND: 'Update Brand',
  ADD_BRAND: 'Add Brand',
  BRAND_LIST: 'Brands List',
  GATEWAY: 'Gateway',
  BUSINESS_DATE: 'Business Date',
  TABLE_ID: 'Table Id',
  TABLE_NAME: 'Table Name',
  PRIMARY_MENU_SYNC_STORE: 'Primary Menu Sync Store (optional)',
  ACCESS_LEVEL: 'Access Level',
  ADMINISTRATOR: 'Administrator',

  SUPPORTS_COUPONS: 'Supports Coupons',
  COUPONS_BUTTON_TEXT: 'Coupons button text',
  KEYBOARD_STYLES: 'Keyboard Styles',
  MOBILEWEB_LANGUAGE_LIST: 'MobileWeb Language List',
  MOBILEWEB_NEW_LANGUAGE_LIST: 'MobileWeb New Language List',
  SERVERAPP_LANGUAGE_LIST: 'ServerApp Language List',
  KIOSK_LANGUAGE_LIST: 'Kiosk Language List',
  KDS_LANGUAGE_LIST: 'Kds Language List',
  ORDERUP_LANGUAGE_LIST: 'OrderUp Language List',
  WAITLIST_LANGUAGE_LIST: 'WaitList Language List',

  // Charity
  CHARITY: 'Charity',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  ENABLED: 'Enabled',
  UPDATE_CHARITY: 'Update Charity',
  ADD_CHARITY: 'Add Charity',
  CHARITY_LIST: 'Charity List',
  ADD_MENU_ITEM: 'ADD MENU ITEM',
  TESTING: 'testing',
  NPS_SCORE: 'NPS SCORE',
  OVERALL_SATISFACTION: 'OVERALL SATISFACTION',
  NPS_DISTRIBUTION: 'NPS DISTRIBUTION',
  AVERAGE_RATING: 'AVERAGE_RATING',
  OSAT_BY_DAY_OR_TIME: 'OSAT BY DAY/TIME',
  DAY_OR_TIME: 'Day/Time',
  PERCENTAGE: '%',
  RATE_OVERALL: 'How would you rate your overall visit today?(OSAT)',
  CONFIDENCE_LEVEL: 'ConfidenceLevel',
  FEMALE: 'Female(30)',
  MALE: 'Male(100)',
  REFUND_DATE: 'Refund Date',
  AUTH_CODE: 'Auth Code',
  REFUND_REASON: 'Refund Reason',
  REFUND_AMOUNT: 'Refund Amount',
  MEMO: 'Memo',
  CONFIGURATION: 'Configuration',
  ADD_SCREEN_IMAGE: 'Add Screen Image',
  DEFAULT_NO_TIP: 'Default is no tip',
  CHECK_NUMBER: 'Check number',
  SUBTOTAL_AMOUNT: 'Subtotal amount',
  TAX_AMOUNT: 'Tax amount',
  DELIVERY_ORDERS: 'Delivery orders',
  DELIVERY_TIME: 'Delivery time',
  QR_CODE_ON_RECEIPT: 'Print QR code on receipt',
  // Device
  UPDATE_DEVICE: 'Update Device',
  ADD_DEVICE: 'Add Device',
  RE_REGISTER_DEVICE: 'Reregister Device',
  DEVICES_LIST: 'Devices List',
  SEND_MESSAGE: 'Send Message',
  MDM_DEVICES_LIST: 'MDM Devices List',
  MDM_DEVICES: 'MDM Devices',
  DEVICE: 'Device',
  SELECT_DEVICE: 'Device type',
  NEW_NUMBER: 'New Number',
  TP_DELIVERY_SETUP: 'Third Party Delivery Setup',
  SELECT_DEVICE_MSG: 'Select a device',
  SELECT_MODIFIER: 'Select Modifier',
  CARD_READER_TYPE: 'Card Reader Type',
  CARD_POINTE_HARDWARE_SERIAL_NUMBER: 'CardPointe Hardware Serial Number',
  CARD_POINTE_USERNAME: 'Card Pointe Username',
  CARD_POINTE_PASSWORD: 'Card Pointe Password',
  TERMINAL_ID: 'Terminal ID',
  // LOCATION_ID: 'Location ID',
  // IP_ADDRESS: 'IP Address',
  // PORT: 'Port',
  ORDER_UP_ROWS: 'Rows',
  ORDER_UP_COLUMNS: 'Columns',
  ORDER_UP_DISPLAY_SEQUENCE: 'Display Sequence (leave blank for single display)',
  PRIMARY_DISPLAY: 'Primary Display',
  DISPLAY_TO_MIRROR: 'Display To Mirror (optional)',
  CLIENT_KEY: 'Client Key',
  BULK_ADD_OTG: 'Bulk Add OTG',
  NUMBER_OF_DEVICES: 'Number of Devices',
  RECIPE: 'Recipe',
  ADD_RECIPE: 'Add Recipe',
  EDIT_RECIPE: 'Edit Recipe',
  ENABLE_BETA_TESTING: 'Enable Beta Testing',
  ENABLE_FEATURE_1: 'Enable Feature 1',
  ENABLE_FEATURE_2: 'Enable Feature 2',
  STORE_DEVICE_ID: 'ID',

  // POSMenu
  MENU_SCHEDULE: 'Menu Schedule',
  EDIT: 'Edit',
  EDIT_IMAGE: 'Edit Image',
  SCHEDULE: 'Schedule',
  LOCATIONS: 'Locations',
  IMAGE: 'Image',
  VIEW_IMAGE: 'View Image',
  ADD: 'Add',
  POS_MENU_SETUP: 'POS Menu Setup',
  ALL_DAY: 'All day',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  ADD_SCHEDULE: 'Add Schedule',
  CUSTOM_MENU_SETUP: 'Custom Menu Setup',
  MENU_LOCATION: 'Menu Location Setup',
  SCREEN_LOCATION: 'Screen Location Setup',

  // GUEST APP
  GUEST_APP: 'Guest App',
  ORDERING: 'Ordering',
  PAYMENT: 'Payment',
  MISCELLANEOUS: 'Miscellaneous',
  GUEST: 'Guest',
  GUEST_NAME: 'Guest name',
  MENU_BROWSER: 'Browsable Menu',
  POS_PRICE: 'POS Price',
  POS_AVAILABLE: 'POS Available',

  // SUPPORT_PREAUTH: 'Supports pre-auth',
  SUPPORT_CUSTOM_PREAUTH: 'Supports custom pre-auth amount',
  SUPPORT_GRATUITY_FOR_PREAUTH: 'Include gratuity if pre-auth not paid',
  // DO_NOT_ALLOW_PAY_NOW: 'Do not allow payment if pre auth enabled',
  AUTO_SEND_RECEIPT_SMS_PRE_AUTH_CLOSED: 'Auto send receipt SMS when pre auth closed by manager',
  DISABLE_PAYPAL: 'Disable Paypal',
  DISABLE_APPLEPAY: 'Disable Apple Pay',
  DISABLE_STRIPE: 'Disable Stripe',
  DISPLAY_MOD_DESC: 'Display Modifier Descriptions',
  MODIFIER_AUTO_POS_MAPPING: 'Use Modifier For POS Auto Mapping',
  POS_ORDER_NUMBER_MENU_ITEM: 'POS Order Number Menu Item',
  SUPRESS_AUTO_TEXT_MENU_ITEM: 'Suppress Auto Text Menu Item',
  AUTO_SEND_RECEIPT_SMS_WHEN_PRE_AUTH_CLOSED_BY_MANAGER: 'Auto send receipt SMS when pre auth closed by manager',
  MENUITEM_AUTO_POS_MAPPING: 'Use Menu Item Name For POS Auto Mapping',
  ALLOW_SPLIT_PAYMENT_BY_SERVER_APP_STAGE: 'Allow split payment by Server App stage',

  // LOGIN
  USERNAME_OR_EMAIL: 'Enter Username or Email',

  // MAPPING
  DISCOUNT: 'Discount',
  EMPLOYEE: 'Employee',
  ORDER_TYPE: 'Order Type',
  ORDER_PROFILE: 'Order Profile',
  ITEM_ORDER_TYPE: 'Item Order Type (Aloha)',
  KIOSK_EMPLOYEE: 'Kiosk Employee',
  AUTO_ADD_MENU_ITEM: 'Auto Add Menu Item',
  POST_PAYMENT_AUTO_ADD_MENU_ITEM: 'Post Payment Auto Add Menu Item',
  REVENUE_CENTER: 'Revenue Center',
  STATION: 'Station',
  DEFAULT_TENDER_TYPE: 'Default Tender Type',
  AMEX_TENDER_TYPE: 'AMEX Tender Type',
  DISCOVER_TENDER_TYPE: 'Discover Tender Type',
  JCB_TENDER_TYPE: 'jCB Tender Type',
  GIFT_CARD_TENDER_TYPE: 'Gift Card Tender Type',
  MASTERCARD_TENDER_TYPE: 'Mastercard Tender Type',
  VISA_TENDER_TYPE: 'VISA Tender Type',
  PAYPAL_TENDER_TYPE: 'PayPal Tender Type',
  APPLEPAY_TENDER_TYPE: 'Apple Pay Tender Type',
  COMP_CARD_TENDER_TYPE: 'Comp Card Tender Type',
  CASH_TENDER_TYPE: 'Cash Tender Type',
  HOUSE_ACCOUNT: 'House Account',
  GIFT_CARD: 'Gift Card',
  TABLE: 'Table',
  TO_GO_ITEM_ORDER_TYPE: 'To Go Item Order Type',
  RUSH_ITEM_ORDER_TYPE: 'Rush Item Order Type',
  DO_NOT_MAKE_ITEM_ORDER_TYPE: 'Do Not Make Item Order Type',
  CURBSIDE_REVENUE_CENTER: 'Curbside Revenue Center',
  CURBSIDE_ORDER_TYPE: 'Curbside Order Type',
  CURBSIDE_ITEM_ORDER_TYPE: 'Curbside Item Order Type',
  WALK_UP_REVENUE_CENTER: 'Walk Up Revenue Center',
  WALK_UP_ORDER_TYPE: 'Walk Up Order Type',
  WALK_UP_ITEM_ORDER_TYPE: 'Walk Up Item Order Type',
  ONLINE_REVENUE_CENTER: 'Online Revenue Center',
  ONLINE_ORDER_TYPE: 'Online Order Type',
  ONLINE_ITEM_ORDER_TYPE: 'Online Item Order Type',
  CALL_IN_REVENUE_CENTER: 'Call In Revenue Center',
  CALL_IN_ORDER_TYPE: 'Call In Order Type',
  CALL_IN_ITEM_ORDER_TYPE: 'Call In Item Order Type',
  MARKET_PLACE_REVENUE_CENTER: 'Marketplace Revenue center',
  MARKET_PLACE_ORDER_TYPE: 'Marketplace Order Type',
  MARKET_PLACE_ITEM_ORDER_TYPE: 'Marketplace Item Order Type',
  PHONE_MEMO_ITEM: 'Phone Number Menu Item',
  DELIVET_TO_MENO_ITEM: 'Deliver To Menu Item',
  PICK_UP_TIME_MENO_ITEM: 'Pickup Time Menu Item',
  POS_TEXT_PAY_PH_NO_MENU_ITEM: 'Pos Text To Pay Phone Number Menu Item',
  POS_TEXT_CHECKIN_PH_NO_MENU_ITEM: 'Pos Text To Check In Phone Number Menu Item',
  POS_TEXT_CHECKIN_WITHOUT_PH_NO_MENU_ITEM: 'Pos Text To Check In Without Payment Phone Number Menu Item',
  POS_MANUAL_TEXT_TO_PAY_OR_CHECKIN_MENU_ITEM: 'Pos Manual Text To Pay Or Check In Menu Item',
  POS_DELIVER_TO_MENU_ITEM: 'Pos Deliver to Menu Item',
  POS_VEHICLE_INFO_MENU_ITEM: 'Pos Vehicle Info Menu Item',
  POS_PICKUP_TIME_MENU_ITEM: 'Pos Pickup Time Menu Item',
  POS_TEXT_PAY_PH_NO_MENU_MODIFIER: 'Pos Text To Pay Phone Number Modifier',
  POS_TEXT_CHECKIN_PH_NO_MENU_MODIFIER: 'Pos Text To Check In Phone Number Modifier',
  POS_TEXT_CHECKIN_WITHOUT_PH_NO_MENU_MODIFIER: 'Pos Text To Check In Without Payment Phone Number Modifier',
  POS_MANUAL_TEXT_TO_PAY_OR_CHECKIN_MODIFIER: 'Pos Manual Text To Pay Or Check In Modifier',
  POS_DELIVER_TO_MENU_MODIFIER: 'Pos Deliver to Modifier',
  POS_VEHICLE_INFO_MENU_MODIFIER: 'Pos Vehicle Info Modifier',
  POS_PICKUP_TIME_MENU_MODIFIER: 'Pos Pickup Time Modifier',
  POS_ORDER_NUMBER_MENU_MODIFIER: 'Pos Order Number Modifier',
  SYNC_CHECK_WITH_ONE_DINE_MARKER: 'Sync check with OneDine marker',
  SYNC_MARKER_MENU_ITEM: 'Sync Marker Menu Item',
  CHECK_TRANSFER_MENU_ITEM: 'Check Transfer Menu Item',
  DELIVERY_FEE_MENU_ITEM: 'Delivery Fee Menu Item',
  DELIVERY_TIP_MENU_ITEM_ID: 'Delivery Tip Menu Item',
  DELIVERY_FEE_SCREEN_ITEM: 'Delivery Fee Screen Item',
  DELIVERY_FEE_SERVICE_CHARGE: 'Delivery Fee Service Charge',
  COMMON_MENU_SCREEN: 'Common Menu Screen (Toast)',
  OPEN_DOLLAR_MENU_ITEM: 'Open Dollar Menu Item',
  LOYALTY_TRACKING_AUTO_ADD_MENU_ITEM: 'Loyalty Tracking Auto Add Menu Item',
  UTENSILS_MENU_ITEM: 'Utensils Menu Item',
  REVENUE: 'Revenue',
  SAVE_AND_ADD_ANOTHER: 'Save and add another',
  UPDATE: 'UPDATE',
  AGREE_TO_PAY: 'I agree to pay',
  ISSUER_AGREEMENT: 'according to my card issuer agreement.',
  TO: 'to',
  ENDING_IN: 'ending in',
  SORRY: 'Sorry !!!.',
  NO_DATA_FOUND: 'No data found',
  REPORTS: 'Reports',
  SUMMARY: 'Summary',
  ADD_TABLE_GROUP: 'Add Table Group',
  ADD_TAG: 'Add Tag',
  NO_MENU_ITEMS: 'No menu Items',
  DASHBOARD: 'DashBoard',
  PASS: 'Pass',
  FAIL: 'Fail',
  LAST_UPDATED: 'Last Updated:',
  MENU_ITEMS: 'Menu Items',
  MODIFIER_GROUPS: 'Modifier Groups',
  SCREENS: 'Screens',
  ALL: 'All',
  KIOSK: 'Kiosk',
  TABLE_TOP: 'Table Top',
  OR: 'OR',
  POS_MAPPING: 'POS Mapping',
  HASH: '#',
  DAY_OF_THE_WEEK: 'Day of the week',
  RESPONSE: 'Response',
  MINUS_ONE: '-1',
  TWO: '+2',
  FIVE: '+5',
  NINTYFIVE: '95%',
  NO_RECORD_FOUND: 'NO RECORD FOUND',
  EXPORT: 'Export',
  EXPORT_TO_EXCEL: 'Export to excel',
  NPS_OSAT: 'NPS/OSAT',
  QUESTIONS: 'QUESTIONS',
  QUESTION_ID: 'Question id',
  SURVEY: 'Survey',
  CLOSED: 'Closed',
  HOUR: '24 Hours',
  MDM: 'MDM',
  STORE_HOUR: 'Store Hours',
  ADD_STORE_HOURS: 'Add Store Hours',
  SECTIONS: 'Sections',
  STORE_SECTION: 'Store Sections',
  ADD_STORE_SECTION: 'Add Store Section',
  UPDATE_STORE_SECTION: 'Update Store Section',
  DRAWER: 'Drawer',
  STORE_DRAWERS: 'Store Drawers',
  ADD_STORE_DRAWER: 'Add Store Drawer',
  UPDATE_STORE_DRAWER: 'Update Store Drawer',
  STORE_TAGS: 'Store Tags',
  TMS_STORE_TAGS: 'Store Tags',
  BRAND_TAGS: 'Brand Tags',
  PH: 'PH',
  E: 'E',
  CURBSIDE_CODE: 'Curbside code',
  CURBSIDE_QR: 'Curbside Link',
  WALK_UP_CODE: 'Walk up code',
  WALK_UP_QR: 'Walk Up Link',
  PICK_UP_DATE: 'Pick Up Date',
  ONLINE_LINK: 'Online Link',
  VENUE_CURB_CODE: 'Venue Curbside code',
  VENUE_CURB_QR: 'Venue Curbside Link',
  VENUE_WALKUP_CODE: 'Venue Walkup Code',
  CHECK_NUMBER_QR: 'Check Number Link',
  VENUE_WALKUP_LINK: 'Venue Walkup Link',
  VENUE_ONLINE_LINK: 'Venue Online Link',
  TABLE_BOOKING_QR: 'Table Booking QR',
  CHARITY_SETTINGS: 'Charity Settings',
  OPENED_AT: ' Opened at',
  CLOSED_AT: 'Closed at',
  EMPLOYEE_NO: 'Employee #',
  ITEM_ID: 'Item Id',
  SENT: 'Sent',
  SENT_AT: 'Sent at',
  QUANTITY: 'Quantity',
  DISCOUNT_ID: 'Discount Id',
  TAX_RATE: 'Tax rate',
  BADGE: 'Badge',
  OVERRIDE_PRICE: 'Override Price',
  OVERRIDE: 'Override',
  POS_INCLUDED: 'POS included',
  MIN_PRICE: 'Min Price',
  MAX_PRICE: 'Max Price',
  MODIFIER_NAME: 'Group Level Name',
  MODIFIER_PRICE: 'Group Level Price',
  UPSELL: 'Upsell',
  DISMISSIBLE: 'Note is Dismissible',
  UPSELL_FOR_SEAT: 'Upsell For Seat',
  QUICK_ORDER: 'Quick order',
  NOTIFY_ON_SEND: 'Notify on send',
  ALLOW_SINGLE_SELECTION: 'Allow single selection',
  SEND_CANCELLATION_SMS: 'Send Cancellation SMS',
  DOUBLE_WIDTH: 'Item as double width',
  SCREENS_TILES: 'Screens as tiles',
  COMPACT_TILE_VIEW: 'Compact Tile View',
  SCROLL_SCREENS_CONTINUOUSLY: 'Scroll screens continuously',
  DISPLAY_ITEM_COUNT: 'Display Item Count',
  SWIPE_TO_CHANGE_SCREENS: 'Swipe to change screens',
  DISPLAY_SCREEN_NAVIGATION: 'Display screen navigation buttons',
  SCROLL_SCREEN_CONTINUOUSLY: 'Scroll screen continuously',
  SUPPRESS_IMAGES: 'Suppress Images',
  SLIDER: 'Slider',
  PRINT_ON_SEND: 'Print on send',
  POS_DESCRIPTION: 'POS description',
  ALLOW_REFILL: 'Allow refills',
  EXCLUDE_FROM_RECEIPT: 'Exclude from receipt',
  REFILL_MENUITEM: 'Refill Menu Item',
  UP_DATE: 'Update',
  PROMT_ORDER_MODE_FOR_WALK_UP: 'Prompt order mode for walk-up',
  BADGE_START_DATE: 'Badge Start Date',
  BADGE_END_DATE: 'Badge End Date',
  PROMPT_IMMEDIATE_SEND: 'Prompt immediate send',
  MODIFIERNAME: 'Modifier Name',
  ONEDINE_TOTAL: 'OneDine Total',
  ONEDINE_TIP: 'OneDine Tip',
  ONEDINE_PAYMENT: 'OneDine Payment',
  POS_TOTAL_POSTED: 'POS Total Posted',
  POS_TIP_POSTED: 'POS Tip Posted',
  POS_TOTAL_PENDING: 'POS Total Pending',
  POS_TIP_PENDING: 'POS Tip Pending',
  AUTO_GRATUITY_SERVICE_CHARGE: 'Auto Gratuity Service Charge',
  // ALWAYS_START_ON_HOME_PAGE: 'Always Start On Home Page',
  VIEW_ONLY_START_DATE: 'View Only Start Date',
  VIEW_ONLY_END_DATE: 'View Only End Date',
  ORDER_START_DATE: 'Order Start Date',
  ORDER_END_DATE: 'Order End Date',
  MARKET_PLACE_SUBSTITUE_MENU_ITEM: 'Marketplace substitute menu item',
  RIGHT_ALIGN_IMAGE: 'Right Align Image',
  PREP_TIME_UNIT: 'Prep Time Unit',
  COMPACT_ITEM_VIEW: 'Compact item View',
  ADDITIONAL_CHARGES_MENU_ITEM: 'Additional Charges Menu Item',
  ADDITIONAL_CHARGES_SERVICE_CHARGE: 'Additional Charges Service Charge',
  NAVIGATE_TO_DETAIL_ONCLICK: 'Navigate to detail on click',
  ORDER_START_TIME: 'Order Start Time',
  ORDER_END_TIME: 'Order End Time',
  ENABLE_EMPLOYEE_TABLE_ASSIGNMENT: 'Enable employee table/section assignment',
  USE_SECTION_INSTEAD_OF_TFEA: 'Use section instead of tables for employee assignment',
  EXCLUDE_FROM_LABEL: 'Exclude from label',
  ENABLE_ITEM_SPLIT_PAYMENT: 'Enable Item Split Payment',
  EXCLUDE_FROM_TIP: 'Exclude from tip',
  EXCLUDE_FROM_KDS: 'Exclude from KDS',
  ALLOW_86_ITEMS: 'Allow 86 Items For Future Order',
  // ALWAYS_START_ON_ORDER_PAGE: 'Always Start On Order Page',
  SUPRESS_NAME_IN_TILE_VIEW: 'Suppress name in tile view',
  NAV_MENU_FOR_ITEM_GROUPS: 'Nav menu for item groups',
  USE_CIRCULAR_IMAGES: 'Use circular images',
  DISPLAY_AS_VIEW_ONLY: 'Display as view only',
  DISPLAY_MODIFIERS_INVIEW_ONLY: 'Display Modifiers in view only',
  CHARITY_MENU_ITEM: 'Charity Menu Item',
  CENTER_TEXT: 'Center Text',
  LARGE_FONT: 'Large Font',
  FORCE: 'Force',
  TAB_REVENUE_CENTER: 'Tab Revenue Center',
  TAB_ORDER_TYPE: 'Tab Order Type',
  CALL_CENTER_EMPLOYEE: 'Call Center Employee',
  CALL_CENTER_REVENUE_CENTER: 'Call Center Revenue Center',
  CALL_CENTER_ORDER_TYPE: 'Call Center Order Type',
  CALL_CENTER_ITEM_ORDER_TYPE: 'Call Center Item Order Type',
  STORE_MAPPINGS: 'Store Mappings',
  TENDER_TYPE_MAPPINGS: 'Tender Type Mappings',
  THIRD_PARTY_DELIVERY: 'Third Party Delivery / Dispatch (Optional)',
  OFF_PREMISE_MAPPINGS: 'Off Premise Mappings',
  SMS_MAPPINGS: 'SMS Mappings',
  MEMO_MODIFIER: 'Memo Modifier (Squirrel)',
  MARKETPLACE_SUBSTITUTE_MODIFIER: 'Marketplace substitute modifier',
  DOLLAR_MODIFIER: 'Open Dollar Modifier',
  AMAZON_PAY_TENDER_TYPE: 'Amazon Pay Tender Type',
  CABBAGE_PAY_TENDER_TYPE: 'Cabbage Pay Tender Type',
  TAB_ITEM_ORDER_TYPE: 'Tab Item Order Type',
  SPEED_ORDER_REVENUE_CENTER: 'Speed Order Revenue Center',
  SPEED_ORDER_ORDER_TYPE: 'Speed Order Order Type',
  SPEED_ORDER_ITEM_ORDER_TYPE: 'Speed Order Item Order Type',
  ALLERGY: 'Allergy',
  KDS_DO_NOT_MAKE: 'KDS Do Not Make',
  CATERING_REVENUE_CENTER: 'Catering Revenue Center',
  CATERING_ORDER_TYPE: 'Catering Order Type',
  CATERING_ITEM_ORDER_TYPE: 'Catering Item Order Type',

  // TABLE: 'Table',
  DISCRETE_SEND: 'Discrete Send',
  ALLOW_PRE_ORDER: 'Allow Pre Order',

  // PAYMEMT SYSTEM
  SELECT_GUEST_PAYMENT_SYSTEM: 'Guest App Payment System',
  SELECT_SERVER_PAYMENT_SYSTEM: 'Server App Payment System',
  PAYMENT_SYSTEM: 'Payment System',

  // POS SYSTEM
  POS_INTEGRATION_TYPE: 'POS Integration Type',
  POS_SYSTEM: 'POS System',
  POS_TYPE: 'POS',
  POS_SUBTYPE: 'POS Sub Type',
  LOCATION_EXTERNAL_ID: 'Local External ID',
  SC_DIRECTORY: 'SC Directory (e.g. C:\\SC OR L:\\SC)',
  ALTDBF_DIRECTORY: 'ALTDBF Directory (e.g. C:\\ALTDBF OR L:\\ALTDBF)',
  MENU_FORMAT: 'Menu Format',
  BACK_OFFICE: 'Back Office',
  QUICK_MENU: 'Quick Menu',
  NETWORK_DRIVE_LETTER: 'Network Drive Letter (e.g. L:)',
  SERVER_NAME: 'Server Name (e.g. \\\\POSI-SERVER or \\\\192.168.10.250)',
  EOD_TIME: 'EOD Time',
  MENU_LOAD_TIME: 'Menu Load Time',
  SECONDARY_MENU_LOAD_TIME: 'Secondary Menu Load Time',
  DEBUG: 'Debug',
  PRINTING: 'Printing',
  MENU_SYNC: 'Menu Sync',
  URL: 'URL',
  ACCESS_TOKEN: 'Access Token',
  OUT_OF_STOCK_SYNC: 'Out Of Stock Sync',
  EMPLOYEE_CLOCK_IN_ENABLED: 'Employee Clock In Enabled',
  SHARED_POS: 'Shared POS',
  PRIMARY_WHEN_SHARED_POS: 'Primary when shared POS',
  SHARED_POS_DISCRIMINATOR_IDS: 'Shared POS Discriminator ID\'s (comma separated)',
  SHARED_POS_DISCRIMINATOR: 'Shared POS Discriminator',


  // SERVER APP
  SERVER_APP: 'Server App',

  // TABLE
  CAPACITY: 'Capacity',
  PRINTERS: 'Printers',
  PRINTER: 'Printer',
  TAB: 'Tab',
  STARTING: 'Starting',
  ENDING: 'Ending',
  APPLY: 'Apply',
  UPDATE_CAPACITY: 'Update Capacity',
  SELECT_SECTION: 'Select Section',
  UPDATE_SECTION: 'Update Section',
  SECTION: 'Section',
  SEATS: 'Seats',
  AVAILABILITY: 'Availability',

  // SURVEY
  UPDATE_QUESTION: 'Update Question',
  ADD_QUESTION: 'Add Question',
  UPDATE_RESPONSE: 'Update Response',
  ADD_RESPONSE: 'Add Response',
  ADD_QUICK_RESPONSE: 'Add Quick Response',
  VALUE: 'Value',
  SENTIMENT: 'Sentiment',
  UPDATE_SURVEY: 'Update Survey',
  ADD_SURVEY: 'Add Survey',
  CREATE_INVITATION: 'Create Invitation',
  SURVEY_LIST: 'Surveys List',
  ON_GOING: 'On Going',
  CONFIRMATION_IMAGE: 'Confirmation Image',
  CLOSED_IMAGE: 'Closed Image',
  WELCOME_IMAGE: 'Welcome Image',
  ENABLE_CONTACT_ME: 'Enable Contact Me',
  SEGMENT: 'Segment',
  QUESTION_LIST: 'Question List',
  SUBJECT: 'Subject',
  OBJECT_TYPE: 'Object Type',
  TYPE: 'Type',
  WORK_FLOW: 'Work Flow',
  RESPONSE_LIST: 'Response List',
  REQUIRED: 'Required',
  SURVEY_QN_MENUITEMS: 'Survey Question - Menu Items/Modifiers',
  SURVEY_QUESTION_NAME: 'Question Name',
  SURVEY_RESPONSE_NAME: 'Response Name',
  MIN_TIP_PERCENT: 'Min Tip Percent',
  MAX_TIP_PERCENT: 'Max Tip Percent',
  MIN_TABLE_TURN_TIME_IN_MINUTES: 'Min Table Turn Time (in minutes)',
  MAX_TABLE_TURN_TIME_IN_MINUTES: 'Max Table Turn Time (in minutes)',
  MIN_GUEST_COUNT: 'Min Guest Count',
  MAX_GUEST_COUNT: 'Max Guest Count',
  DEFAULT_GUEST_COUNT: 'Default Guest Count',
  SURVEY_WELCOME: 'Survey Welcome Message',
  SURVEY_CONFIRM: 'Survey Confirm Message',
  SURVEY_QUESTION: 'Survey Question Name',
  SURVEY_RESPONSE: 'Survey Response Name',


  // USER
  UPDATE_USER: 'Update User',
  ADD_USER: 'Add User',
  USER_NAME: 'User Name',
  USER_LIST: 'Users List',
  ROLE_NAME: 'Role Name',
  USER: 'Users',
  SURVEYS: 'Surveys',
  TILES: 'Tiles',
  BROWSABLE_MENU: 'Browsable menu',
  USER_ROLE: 'User Role',
  ROLE: 'Role',
  ROLE_TYPE: 'Role Type',
  DEACTIVATE_USER: 'This will deactivate the user and cannot be undone. Do you want to continue?',
  UNLOCK_USER: 'This will unlock the user. Do you want to continue?',

  // OFFER SYSTEM
  LOYALTY_SYSTEM: 'Loyalty system',
  LOYALTY_SYSTEM_TYPE: 'Loyalty system type',

  // STORE
  STORE_NAME: 'Store Name',
  STORE_NUMBER: 'Store Number',
  DISPLAY_NAME: 'Display Name',
  TIME_ZONE: 'Time Zone',
  OPEN_TIME: 'Open Time',
  CLOSE_TIME: 'Close Time',
  RECEIPT_PRINTER: 'Receipt Printer',
  NOTIFICATION_PRINTER_DEVICE_ID: 'Notification Printer',
  LABEL_PRINTER: 'Label Printer',
  KITCHEN_TICKET_PRINTER: 'Kitchen Ticket Printer',
  PICKUP_TIME: 'Pickup time',
  STORE_LIST: 'Stores List',
  STORE_IMPORT_LIST: 'Import Store List',
  UPDATE_STORE: 'Update Store',
  ADD_STORE: 'Add Store',
  STORE_HOURS_SETUP: 'Store Hours Setup',
  STORE_PREMISE_SETUP: 'Off Premise',
  TAX_RATES: 'Tax Rates',
  MENU_SYNC_PRMARY: 'Sync menu from primary',
  DELIVERY_RULE: 'Delivery Rule',
  TIP_RULE: 'Tip Rule',
  OFF_PREMISE_TIP_RULE: 'Off Premise Tip Rule',
  OFF_PREMISE_SURVEY: 'Off Premise Survey',
  KEYWORDS: 'Keywords',
  OFF_PREMISE_RISK_CONFIGURATION: 'Off Premise Risk Configuration',
  OFFPREMISE_OPEN_TIME: 'Off-premise Open Time',
  OFFPREMISE_CLOSE_TIME: 'Off-premise Close Time',
  MARKETPLACE_OPEN_TIME: 'Marketplace Open Time',
  MARKETPLACE_CLOSE_TIME: 'Marketplace Close Time',
  STORE_HOURS_NOTE: 'Off-premise and marketplace hours are optional. Enter values only if different from regular store hours.',
  PRICE_STORE: 'Price',
  HAPPY_HOUR_RULE: 'Happy Hour Rule',
  DEFAULT_KDS_LAYOUT: 'Default KDS Layout',
  OVERRIDE_KDS_LAYOUT: 'Override KDS Layout',
  SUPRESS_COURSING: 'Suppress Coursing',
  SELF_SERVICE: 'Self Service(suppress routing)',
  KITCHEN_NAME: 'Kitchen Name',
  PACKAGING_TIME: 'Packaging Time (seconds)',
  // TIPS
  DEFAULT: 'Default',
  TIP_TYPE: 'Tip Rule Type',
  DISABLE_NO_TIP_OPTION: 'Disable no tip option',
  DISABLE_PRESET_TIP_OPTION: 'Disable preset tip option',
  REQUIRE_TIP_SELECTION: 'Require Tip Selection',
  SUGGESTED_TIP_EXCLUDE_DISCOUNT: 'Suggested tip excludes discount',
  SUGGESTED_TIP_INCLUDES_TAX: 'Suggested tip includes tax',
  ADD_TIP_RULE: 'Add Tip Rule',
  TIP_RULE_LIST: 'Tip Rules',
  IS_DEFAULT: 'Is Default',
  THRESHOLD: 'Threshold',
  OVER_RIDE: 'Override Value',
  EDIT_TIP_RULE: 'Edit Tip Rule',
  PRIMARY_LOCATION_MENU: 'Primary location for venue',
  DISABLE_OTHER_TIP_OPTION: 'Disable other tip option',
  ENABLE_TIP_OTHER_AMOUNT: 'Enable Tip Other Amount',
  ENABLE_TIP_OTHER_PERCENT: 'Enable Tip Other Percent',
  NEW_SERVER_APP_UI: 'New Server App UI',
  EDD_24_HOUR: 'EOD 24 Hour',
  NEW_ANDROID_APP: 'New Android App',
  NEW_PAYMENT_FLOW: 'New Payment Flow',
  ENABLE_SERVER_APP_BETA_VERSION: 'Enable Server App Beta Version',
  TARGET_ANDROID_APP_VERSION: 'Target Android App version',
  // REPORTS
  VOID_REFUND: 'Void/Refund',
  SERVER: 'Server',
  CHECK: 'Check #',
  REFUNDS: 'Refunds',
  CARD_TYPE: 'Card Type',
  CARD: 'Card #',
  REASON: 'Reason',
  TIP_ADJUST_AMOUNT: 'Tip Adjust Amount',
  SEND_EMAIL: 'Send Email',
  API_LOGS: 'API Logs',
  API_LOGS_OLD: 'API Logs - Old',
  DISPATCH_TIP: 'Dispatch Tip',
  MARKETPLACE_ORDERS: 'Marketplace Orders',
  API_LOG_DETAILS: 'API log details',
  RECEIPT_PHONE: 'Receipt Phone',
  RECEIPT_EMAIL: 'Receipt Email',
  CHARGEBACK: 'Chargeback',


  // tiles
  TILE: 'Tile',
  LINK: 'Link',
  ADD_TILE: 'Add Tile',
  EDIT_TILE: 'Edit Tile',
  SCREEN_TILE: 'Tile',
  UPLOAD_MENU_ITEM_FILE: 'Upload MenuItem File',
  UPLOAD_CAROUSEL_FILE: 'Upload Carousel File',
  UPLOAD_PDF: 'Upload PDF',
  SELECT_CAMPAIGN: 'Select Campaign',
  TILES_DISPLAY_NAME: 'Tiles Display Name',
  TILES_DESCRIPTION: 'Tiles Description',

  // menuQUESTION browser
  ITEM_NAME: 'Item Name',
  ITEM_DECRIPTION: 'Item Description',
  SECTION_IMAGE: 'Section Image',
  ITEM_PRICE: 'Item Price',
  ITEM_MAX_PRICE: 'Item Max Price',
  ITEM_DISCLAIMER: 'Item Disclaimer',
  ITEM_CALORIES: 'Item Calories',
  ITEM_MAX_CALORIES: 'Item Max Calories',
  DISH_TYPE: 'Dish Type',
  DIATERY_LIFESTYLE: 'Diatery & Lifestyle',
  INGREDIENTS: 'Ingredients',
  CUISINE: 'Cuisine',
  COOKING_STYLE: 'Cooking Style',
  SPICE_LEVEL: 'Spice Level',
  SECTION_NAME: 'Section Name',
  SECTION_DESCRIPTION: 'Section Description',
  CUSTOM: 'Custom',
  LINKED: 'Linked',
  LINK_TYPE: 'Link Type',
  ORDER_PAGE: 'Order Page',
  CAL_PRICE_LINKED_ITEMS: 'Calculate Price from linked Items',
  LINKED_NAME: 'Linked Name',
  DISPLAY_STYLE: 'Display Style',
  ADD_MENU: 'Add menu',
  MENU_TYPE: 'Menu Type',
  TILE_TYPE: 'Tile Type',
  APP_LINK: 'APP_LINK',
  SELECT_MENU: 'Select Menu',
  TILE_TARGET: 'Tile Target',
  FLOW_REQUIRED: 'Flow Required',
  ENABLE_OFFERS: 'Enable Offers',
  ENABLE_COUPONS: 'Enable Coupons',
  SUPPRESS_BORDER: 'Supress Border',
  HALF_WIDTH: 'Half Width',
  INCLUDED_IN_COMBO: 'Included in combo',
  HIDE_DISABLED_MENUS: 'Hide Disabled Menus',
  ENABLE_OPT_IN: 'Enable Opt In',

  // Sensor-Assignment
  SENSOR_ASSIGNMENT: 'Sensor Assignment',
  CONFIRMATION: 'Confirmation',
  SEND: 'Send',
  SENSOR_GROUP: 'Sensor Group',
  SENSOR_GROUP_NAME: 'Sensor Group Name',
  ADD_SENSOR_GROUP: 'Add Sensor Group',
  UPDATE_SENSOR_GROUP: 'Update Sensor Group',
  NO_SENSORS_FOUND: 'No Sensors Found',
  REQUIRE_PRE_AUTH: 'Require Pre Auth',
  TABLE_ORDER_REQUIRES_PREPAYMENT: 'Table Order Requires Prepayment',
  TABLE_ORDER_REQUIRES_TABLE_ASSIGNMENT: 'Table Order Requires Table Assignment',
  ENABLE_OFF_PREMISE_PAY_AT_RESTAURANT: 'Enable Off Premise Pay At Restaurant',
  ENABLE_OFF_PREMISE_PAYMENT: 'Enable Off Premise Payment',
  // ENABLE_TABLE_REVALIDATION: 'Enable Table Revalidation',
  PRE_AUTH_ON_CHECK_IN: 'Pre Auth On Check In',
  REQUIRE_GN_ON_CHECKOUT: 'Require Guest Name On Checkout',
  APPEND_SEAT_NUMBER_TO_MEMO: 'Append seat number to item memo',
  PROMPT_CHECK_NUMBER_CHECKIN: 'Prompt For Check Number On Check-in',
  CAPTURE_TABLE_NUMBER_CHECKIN: 'Capture Table Number On Check-in',
  ENABLE_VOUCHER_SALE: 'Enable Voucher Sale',
  SUPRESS_AGE_RESTRICTED_ITEMS: 'Supress Age Restricted Items',
  MAX_ORDER_AMOUNT: 'Max Order Amount',
  GUEST_BUDGET: 'Guest Budget',
  BUDGET: 'Budget',
  DO_NOT_AUTO_CLOSE_PAID_CHECKS: 'Do not auto close paid checks',
  SELECT_SENSOR_GROUP: 'Select Sensor Group',
  SMS_REORDER_LINK_FOR_TABLE_ORDER: 'SMS Reorder Link For Table Orders',
  SUPPRESS_PAY_NOW_IF_PRE_AUTH_ENABLED: 'Suppress Pay Now If Pre Auth Enabled',
  MOBILE_VIEW_ADD_SENSOR: 'NOTE: Please click on a table to add a sensor for that table',


  FREQUENCY: 'Frequency',
  INTERVAL: 'Interval',
  STATUS: 'Status',
  LAST_EXECUTION: 'Last Execution',
  NEXT_EXECUTION: 'Next  Execution',
  MESSAGE: 'Message',
  ENABLE_DISABLE: 'Enable/Disable',
  RUN_NOW: 'Run Now',
  CUBESIZE: 'Cubesize',
  ONLINE: 'Online',
  PREP_TIME: 'Prep Time',
  PREP_TIME_MINUTES: 'Prep Time (minutes)',
  ADD_PREP_TIME: 'Add Prep Time',
  PRODUCTION: 'Production',
  PRODUCTION_INCLUDE_ON_PREMISE: 'Include On-Premise',
  PRODUCTION_RATE: 'Production Rate',
  ADVANCE_ORDER_LIMIT: 'Advance order limit',
  DAISY_CHAIN_AUTOSEND_TIME: 'Daisy chain auto send time',
  REQUIRE_NAME_ON_TABLE_ORDERS: 'Require name on table orders',
  CURBSIDE_ORDERS: 'Curbside orders',
  ONLINE_ORDERS: 'Online orders',
  WALK_UP_ORDERS: 'Walk up orders',
  REQUIRE_PATMENT: 'Require payment',
  PAY_AT_RESTAURANT: 'Pay at restaurant',
  PAY_AT_RESTAURANT_BUTTON: 'Pay at restaurant button text',
  OFF_PROMISE_SERVICE_CHARGE: 'Off premise service charge %',
  OFF_PROMISE_AUTO_GRATUITY: 'Off premise Auto gratuity %',
  ON_PROMISE_SERVICE_CHARGE: 'On premise service charge %',
  ON_PROMISE_AUTO_GRATUITY: 'On premise Auto gratuity %',
  SERVICE_CHARGE_MESSAGE: 'Service charge message',
  AUTO_GRATUITY_MESSAGE: 'Auto gratuity message',
  // PREFIX_OFF_PREMISE_ORDER_WITH_TOGO: 'Prefix off-premise order with TOGO',
  // GUEST_NAME_INSTEAD_OF_PHONE_ON_POS_CHECK: 'Guest name instead of phone on POS check',
  AUTO_COMPLETE_CLOSED_POS_CHECKS: 'Auto complete closed POS checks',
  HIDE_TIPS_ON_FIREBOARD: 'Hide tips on Fireboard',
  ENABLE_PRINT_ROUTING: 'Enable Print Routing',
  ONLINE_ORDER_SLOT_INTERVAL: 'Online Order Slot Interval',
  OFF_PROMISE_ADDITINAL_CHARGES: 'Off premise additional charges',
  OFF_PROMISE_MAX_QUANTITY: 'Off premise max quantity',
  OFF_PROMISE_MAX_AMOUNT: 'Off premise max amount',

  CHECK_NUM_SCAN_PAY: 'Check number scan pay',
  SUPPRESS_PICKUP: 'Suppress Pickup',
  TABLE_ORDER: 'Table order',
  TABLE_PAYMENT: 'Table payments',
  TABLE_ORDER_REQUIRES_REPAYMENT: 'Table order requires prepayment',
  SUPPORTS_CUSBSIDE_ORDERING: 'Supports curbside ordering',
  SUPPORTS_ONLINE_ORDERING: 'Supports online ordering',
  SURVEY_WITH_PAYMENTS: 'Survey with payments',
  TIPS_WITH_PAYMENTS: 'Tips with payments',
  REQUIRE_PAYMENY_TO_PLACE_ORDER: 'Require payment to place order',
  ALLOW_PAY_AT_RETAURANT: 'Allow pay at restaurant',
  DO_NOT_AUTO_CLOSE_CHECKS_PAID_CHECKS: 'Do not auto close checks paid checks',
  CLOSE_PAID_CHECKS_ONEDINE_IMMEDIATELY: 'Close paid checks in OneDine immediately',
  CALL_SERVER: 'Call server',
  CALL_ICON_SERVER: 'Call Server',
  SUPPRESS_SIGNIN_SCREEN_FOR_GUEST_USERS: 'Suppress sign-in screen for guest users',
  SUPPRESS_CONTINUE_AS_GUEST: 'Suppress continue as guest',
  ONGOING: 'Ongoing',
  RESULT: 'Result',
  USERNAME: 'Username',
  ACCSESS_STORE_INFORMATION: 'This will send the user a link to access store information',
  LINK_SENT_TO_PHONE_NUMBER: 'The link will be sent to the phone number on file',
  REVOKE_THE_USER_ACCESS_TO_THE_STORE: 'This will revoke the user’s access to the store',
  LOGIN_WITH_CARD: 'Login with card',
  ACCESS_OTHER_EMPLOYEE_CHECKS: 'Access other employee checks',
  USE_ONEDINE_CARD_VERIFICATION: 'Use OneDine card verification',
  RESTRICT_OTHER_CHECKS_BY_JOB_CODE: 'Restrict other checks by job code',
  CLOCKIN_REQUIRED: 'Clock In Required',
  POS_FOR_CLOCK_IN: ' Use POS For Clock In',
  ALLOW_EMPLOYEE_GREETING: 'Allow employee greeting',
  ONEDINE_CLOCKIN_REQUIRED: 'OneDine Clock-in Required',
  PLEASE_SELECT_OPTION_TO_RESET_PASSWORD: 'Please select an option to reset the user password',
  UPDATE_THE_GUEST_PROFILE: 'This will upload the selected file and update the guest profile. Do you want to continue?',

  ENABLE_ADVANCED_SURVEY: 'Enable advanced survey',
  ENABLE_CHARITY: 'Enable Charity',
  DISPLAY_AMOUNT_AS_DEFAULT: 'Display amount as default',
  HIDE_ZERO_DOLLAR_CHECKS: 'Hide zero dollar checks',
  RESET_OUT_OF_STOCK_EOD: 'Reset out of stock by EOD',

  DISABLE_PAYMENT: 'Disable payments',
  ALLOW_CARD_SWIPE_FOR_EMV_CARDS: 'Allow card swipe for EMV cards',
  ALLOW_CARD_SWIPE_FOR_NONEMV_CARDS: 'Allow card swipe for non-EMV cards',
  ALLOW_PRINTED_RECEIPTS: 'Allow printed receipts',
  ENABLE_TIPS: 'Enable tips',
  FOLIO_PAYMENT: 'Folio payment',
  CASH_PAYMENT: 'Cash payment',
  // DISABLE_SIGNATURE_REQUIREMENT: 'Disable signature requirement',
  // ADJUST_PAYMENTS_CHANGED_ON_POS: 'Adjust payments changed On POS',
  WARN_IF_TIP_IS_UNUSUAL: 'Warn if tip is unusual',
  SUPPORTS_PREAUTH_FOR_TABLES: 'Supports pre-auth for tables',
  SUPPORTS_PREAUTH_FOR_TABS: 'Supports pre-auth for tabs',
  SUPPORTS_CUSTOM_PREAUTH_AMOUNT: 'Supports custom pre-auth amount',
  INCLUDE_GRATUITY_PREAUTH_TAB_NOT_CLOSED: 'Include gratuity pre-auth tab not closed',
  // ADJUST_PREAUTH_TOTAL_INTO_TIP: 'Adjust pre-auth total into tip',
  // APPLY_JOB_RULES_WHEN_POSTTING_TO_POS: 'Apply job rules when posting to POS',
  DISABLE_LOGIN_WITH_KEYPAD: 'Disable login with keypad',
  SUPPORTS_OFFLINE_MODE: 'Supports Offline Mode',
  SUPPORTS_ONE_DINE_CLOCK_IN: 'Supports One Dine Clock In',
  SUPPORTS_NOTIFICATION_ON_PAYMENT_FAILURE: 'Supports notification on payment failure',
  DELAY_API_ECFP_PAYMENT_TO_POS: 'Delay API employee checks from posting payments to POS',
  USE_CLOSE_DATE_TO_CALCULATE_PICK_UP_DATE: 'Use close date to calculate pickup date',

  ORDERING_ENABLED: 'Ordering enabled',
  WALKUP_ORDERS_ENABLED: 'Walk up orders enabled',
  CALLIN_ORDERS_ENABLED: 'Call-in orders enabled',
  DELIVERY_ORDERS_ENABLED: 'Delivery orders enabled',
  HOLD_ONLINE_ORDERS_REVIEW: 'Hold online orders for server review',
  // REQUIRE_SIGNATURE_SWIPE: 'Always require signature for swipe',
  SORT_MENU_CATEGORIES_ALPHABETICALLY: 'Sort menu categories alphabetically',
  SORT_MENU_ITEMS_ALPHABETICALLY: 'Sort menu items alphabetically',
  SUPRESS_MODIFIER_PRICES: 'Supress modifier prices',
  DISPLAY_PRICE: 'Display prices',
  // PRINT_EMPLOYEE_NAME_ON_KITCHEN_PRINTER: 'Print employee name on kitchen printer',
  PRINT_SEAT_NUMBER_ON_KITCHEN_PRINTER: 'Print seat number on kitchen printer',
  VALIDATE_JOB_TO_OPEN_TAB: 'Validate job to open tab',
  VALIDATE_MENU_BY_EMPLOYEE_JOB: 'Validate menu by employee job',
  REQUIRE_PRINTER_TO_OPEN_TAB: 'Require printer to open tab',
  ENABLE_SPEED_ORDERING: 'Enable speed ordering',
  ENABLE_REFILL_NOTIFICATIONS: 'Enable refill notifications',
  VALIDATE_BEVERAGE_REQUIREMENT_BY_SEAT: 'Validate beverage requirement by seat',
  VALIDATE_BEVERAGE_REQUIREMENT_BY_CATEGORY: 'Validate beverage requirement by category',
  LOGO: 'Logo',
  MOBILE_LOGO: 'Mobile Logo Image',
  DESKTOP_LOGO: 'Desktop Logo Image',
  RECEIPT_LOGO: 'Receipt Logo',
  KIOSK_LANDING: 'Kiosk Landing',
  KIOSK_CONFIRMATION: 'Kiosk Confirmation',
  TABLE_TOP_LANDING: 'Table Top Landing',
  TABLE_TOP_CONFIRMATION: 'Table Top Confirmation',
  DEFAULT_MENU_ITEM: 'Default Menu Item',
  BACKGROUND_IMAGE: 'Background Image',
  HOME_PAGE_BACKGROUND_IMAGE: 'Home Page Background Image',
  RECEIPT_PAGE_BACKGROUND_IMAGE: 'Receipt Page Background Image',
  LOYALTY_Image: 'Loyalty Image',
  STORE_SETTINGS: 'Store Settings',
  DOWNLOAD_POS_AGENT: 'Download POS Agent',
  SORT_ORDER: 'Sort Order',
  CATEGEGORY: 'Category',
  CATEGORIES: 'Categories',
  OVERRIDE_CATEGORY: 'Override Category',
  REFILLS: 'Refills',
  DISPLAY_PREP_TIME: 'Display prep time',
  CONFIRMATION_PAGE: 'Confirmation Page',
  LOYALTY_SIGNUP_Image: 'Loyalty Sign Up Image',
  LOYALTY_SIGNIN_IMAGE: 'Loyalty Sign In Image',
  COUPON_IMAGE: 'Coupon Image',
  PAYMENT_LOYALTY_SIGNIN_BUTTON: 'Payment Loyalty Sign In Button',
  PAYMENT_LOYALTY_NOOFFERS_BUTTON: 'Payment Loyalty No Offers Button',
  PAYMENT_LOYALTY_OFFERS_BUTTON: 'Payment Loyalty Offers Button',
  PAYMENT_COUPON_BUTTON: 'Payment Coupon Button',
  PAYMENT_GIFT_CARD_BUTTON: 'Payment Gift Card Button',
  TABLE_NOT_ASSIGNED: 'Table Not Assigned',
  GIFT_CARD_IMAGE: 'Gift Card Image',
  MIN: 'Min',
  MAX: 'Max',
  ADVANCE_ORDER_TIME: 'Advance Order Time',
  ADVANCE_ORDER_TIME_UNIT: 'Advance Order Time Unit',
  DISPLAY_ADVANCE_ORDER_TIME: 'Display advance order time',
  TIP_CARD_IMAGE: 'Tip',
  // Screen Schedule
  SCREEN_SCHEDULE: 'Screen Schedule',
  OPT_IN_IMAGE: 'Opt In Image',

  // EMPLOYEEID
  EMPLOYEE_LIST: 'Employee List',
  UPDATE_EMPLOYEE: 'Update Employee',
  EMPLOYEE_ID: 'Id',
  EMPLOYEE_POS_ID: 'Pos Id',
  ALLOW_EMPLOYEE: 'Allow Employee',
  EMPLOYEE_FIRST_NAME: 'First Name',
  EMPLOYEE_LAST_NAME: 'Last Name',
  DEFAULT_JOB_TYPE: 'Default Job Type',
  MESSAGE_ENABLED: 'Message Enabled',
  SELFIE_ENABLED: 'Selfie Enabled',
  TERMINATED: 'Terminated',
  MARKET_PLACE_MARKUP_PERCENT: 'Market place markup percent',
  MENU_ITEM_BUTTON_COLOR: 'Menu Item button color (server app only)',
  EDIT_MENU_ITEM: 'Edit Menu Item',
  SERVER_APP_FLIPPED_VIEW: 'Server App Flipped View',
  SECURITY_CODE: 'Security Code',
  MENU_SCREEN_BUTTON_COLOR: 'Menu screen button color (server app only)',
  EXCLUDE_CHECKS_FROM_KDS: 'Exclude Checks From KDS',

  // Prep Time
  UPDATE_BRAND_PREP_TIME: 'Update Brand Prep Time',
  ADD_BRAND_PREP_TIME: 'Add Brand Prep Time',
  BRAND_PREP_TIME: 'Brand Prep Time',

  // survery images
  WELCOME_HERO_IMAGE: 'Welcome Hero Image',
  CONFIRMATION_HERO_IMAGE: 'Confirmation Hero Image',
  IMAGE_UPLOAD_SUCCESS: 'Image Uploaded Successfully',
  // nutritions
  ITEM_DETAILS: 'Item details',
  NUTRITIONS: 'Nutrition Facts',
  SERVING_SIZE: 'Serving Size',
  SERVING_UNIT: 'Serving Unit',
  AMT_PER_SERVING: 'Amt Per Serving',
  DAILY_VALUE: '% Daily',
  TOTAL_FAT: 'Total Fat',
  SATURATED_FAT: 'Saturated Fat',
  TRANS_FAT: 'Trans Fat',
  CHOLESTROL: 'Cholestrol',
  SODIUM: 'Sodium',
  TOTAL_CARBOHYDRATE: 'Total Carbohydrate',
  DIETARY_FIBER: 'Dietary Fiber',
  TOTAL_SUGARS: 'Total Sugars',
  ADDED_SUGARS: 'Added Sugars',
  PROTIEN: 'Protein',
  VITAMIN_D: 'Vitamin D',
  POTASSIUM: 'Potassium',
  CALCIUM: 'Calcium',
  IRON: 'Iron',
  SELECT_COUNTRY: 'Select Country',
  PROFILE_ENABLED: 'Profile Enabled',

  // title
  ONEDINE_ADMIN: 'OneDine Admin',
  MY_DATA: 'My Data',
  TRANSACTIONS_REPORT: 'Transactions Report',
  REQUEST_DATA: 'Request Data',
  REQUEST_DATE: 'Request Date',
  CHANGE_LOG: 'Change Log',
  CHECKS_REPORT: 'Check Report',
  VERSION_3: 'Version 3.0',
  TAGS: 'Tags',
  TAG_TYPE: 'Tag Type',
  RELEASE_NOTES: 'Release Notes',
  DATA_REQUESTS: 'Data Requests',
  DATA_REQUEST_DETAIL: 'Data Request Detail',
  DATA_REQUEST_REPORT: 'Data Request Report',
  ADD_NEW_VERSION: 'Add New Version',
  UPDATE_RELEASE_NOTE_VERSION: 'Update Release Note Version',
  ADD_DETAILS: 'Add Details',
  RELEASE_DATE: 'Release Date',
  NEW: 'New',
  JOB: 'Jobs',
  ADD_JOBS: 'Add Jobs',
  UPDATE_JOBS: 'Update Jobs',
  TEAM: 'Team',
  TEAMS: 'Teams',
  TEAM_LIST: 'Team List',
  ADD_JOB_TEAM: 'Add Job Team',
  UPDATE_JOB_TEAM: 'Update Job Team',
  CLONE: 'Clone',
  RECONCILIATION_REPORT: 'Reconciliation Report',
  MANAGE_ITEM: 'Manage Items',
  MANAGE_GROUP: 'Manage Group',
  MERGE: 'Merge',
  MANAGE_LINKS: 'Manage Links',
  MODIFIERS: 'Modifiers',
  ORDERING_MENU: 'Ordering menu',
  ONEDINE_OFFERS: 'OneDine Offers',
  OMS_SETUP: 'OneDine OMS',
  PAYTRONIX_LOYALTY: 'Paytronix Loyalty',
  PAYTRONIX_GIFT_CARD: 'Paytronix Gift Card',
  SVS: 'SVS Gift Card',
  VALUE_TEC_GIFT_CARD: 'ValueTec Gift Card',
  PUNCHH: 'Punchh Loyalty',
  SPENDGO: 'Spendgo Loyalty',
  FISHBOWL: 'Fishbowl Coupons',
  BATCH_REPORT: 'Batch Report',
  BATCH_SUMMARY_REPORT: 'Batch Summary Report',
  RECONCILIATION: 'Reconciliation Report',
  SURVEY_REPORT: 'Survey Report',
  SALES_REPORT: 'Sales Report',
  MAPPINGS: 'Mappings',
  POS_MENU: 'Pos menu',
  REGISTER_DEVICE: 'Register device',
  REREGISTER_SENSOR: 'Register sensor',
  TABLE_SENSOR: 'Table sensor',
  NAMED_SENSOR: 'Named sensor',
  VIEW_CHARITY: 'View Charity',
  POS: 'POS',
  ONEDINE_SERVICES: 'OneDine Services',
  CONFIGURE: 'Configure',
  EDIT_MODIFIER_GROUPS: 'Edit Modifier Groups',
  MANAGE_MODIFIER: 'Manage Modifiers',
  EDIT_MODIFIER: 'Edit Modifers',
  CLONE_MENU: 'Clone menu',
  AGE_RESTRICTED: 'Age Restricted',
  VALIDATE_AGE_RESTRICTION_BY_SEAT: 'Validate age restriction by seat',
  WEBSITE: 'Website',
  ALLOW_OPENING_CHECK_IN_TABLE_ORDER: 'Allow opening check in table order',
  FIRE_ONLINE_ORDERS_ON_CHECK_IN: 'Fire online orders on check-in',
  ADD_SENSOR: 'Add Sensor',
  EDIT_SENSOR: 'Edit Sensor',
  NAMED_SENSOR_LIST: 'Named Sensor List',
  ENABLE_THEMING: 'Enable theming',
  VALIDATE_ALCOHOL_SERVER: 'Validate alcohol service',
  UPDATE_BRAND_SETTINGS: 'Update Brand Settings',
  UPDATE_STORE_SETTINGS: 'Update Store Settings',
  PRE_AUTH: 'Pre Auth',
  BATCH_DETAIL_REPORT: 'Batch Detail Report',
  DEFAULT_PRE_AUTH_AMOUNT: 'Default Pre Auth Amount',
  DEFAULT_PRE_AUTH_GRATUITY: 'Default Pre Auth Gratuity in %',
  ALCOHOL_SERVIES: 'Alcohol Service',
  NUMBER_OF_SERVINGS: 'Number of servings',
  VALIDATE_ALCOHOL_TIME: 'Validate Time',
  TIME_MINUTES: 'Time in minutes',
  OTHER_SETTINGS: 'Other settings',
  STORE_NAMED_SENSOR_LIST: 'Store Named Sensor List',
  VENUE_NAMED_SENSOR_LIST: 'Venue Named Sensor List',
  STORE_NAMED_SENSOR: 'Store named sensor',
  VENUE_NAMED_SENSOR: 'Venue named sensor',
  ENABLE_TABLE_TOP_GUEST_MODE: 'Enable table top guest mode',
  RESET_THEME_MESSAGE: 'This will delete current settings and reset to defaults',
  COPY_THEME_MESSAGE: 'This will overwrite the current values with the mobile theme - continue...',
  THEME: 'Theme',
  DELIVERY: 'Delivery',
  DELIVERY_LOCATION_SENSOR: 'Delivery location sensor',
  DELIVERY_LOCATION_SENSOR_LIST: 'Delivery Location Sensor List',
  DEFER_OFF_PREMISE_SURVEY_FOR_ONEDINE_ORDERS: 'Defer Off Premise Survey for OneDine Orders',
  DEFER_OFF_PREMISE_SURVEY_FOR_POS_ORDERS: 'Defer Off Premise Survey for POS Orders',
  OFF_PREMISE_SURVEY_DELAY_IN_MINUTES: 'Off premise survey delay in minutes',
  DEFER_ON_PREMISE_SURVEY: 'Defer On Premise Survey',
  ON_PREMISE_SURVEY_DELAY_IN_MINUTES: 'On premise survey delay in minutes',
  DELAYED_SURVEY_BLOCKED_HOURS: 'Delayed survey blocked hours',
  SEAT_TRACKING_MODIFIERS: 'Seat Tracking Modifiers',
  ADD_MODIFIER: 'Add Modifier',
  TATTLE_SURVEY: 'Tattle Survey',
  RECEIPT_OPTION: 'Receipt Option',
  // ENABLE_VENUE_ORDERING: 'Enable Venue Ordering',
  // ENABLE_FOOD_RUNNER: 'Enable Food Runner',
  WEB_THEME: 'Web Theme',
  MOBILE_THEME: 'Mobile Web Theme',
  SERVER_APP_THEME: 'Server App Theme',
  KIOSK_THEME: 'Kiosk Theme',
  TABLE_TOP_THEME: 'Table Top Theme',
  KDS_THEME: 'KDS Theme',
  MOBILE_APP_THEME: 'Mobile App Theme',
  COPY_SERVER_THEME_MESSAGE: 'This will overwrite the current values with the Server theme - continue...',
  MOBILE_WEB: 'Mobile Web',
  MOBILE_WEB_NEW: 'Mobile Web New',
  AUTO_SEND_RECEIPT: 'Auto Send Receipt',
  CLUTCH_LOYALTY: 'Clutch',
  CLUTCH_LOYALTY_SETUP: 'Clutch Loyalty',
  LOOK_LOYALTY: 'Look Loyalty',
  ENVIRONMENT: 'Environment',
  CARD_SET_ID: 'Card Set Id',
  SIGNUP_METHODS: 'Sign up methods',
  LOOKUP_METHODS: 'Lookup methods',
  SCANNER: 'Scanner',

  // SeatOrder
  TIME: 'Time',
  ITEM: 'Item',
  TABLE_SEAT: 'Table/Seat',
  COMMENT: 'Comment',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  DATE: 'Date',
  SEAT: 'Seat',
  CHECK_NO: 'Check #',
  ORDERS: 'Orders',
  SOCIAL_URL_SETTINGS: 'Social URL Settings',
  BRAND_URL: 'Brand URL',
  FACEBOOK_URL: 'Facebook URL',
  YOUTUBE_URL: 'YouTube URL',
  INSTAGRAM_URL: 'Instagram URL',
  TWITTER_URL: 'Twitter URL',
  PINTREST_URL: 'Pinterest URL',
  LINKEDIN_URL: 'LinkedIn URL',


  TARGET_VERSION: 'Target Version',
  REFILL_MENU_ITEM: 'Refill Menu Item',
  LIMIT_AGE_RESTRICTION_VALIDATION_TO_FIRST_SEND: 'Limit age restriction validation to first send',
  VALIDATE_ALCHOHOL_SERVICE: 'Validate alcohol service',
  ALLOW_SPLIT_PAYMET: 'Allow split payment',
  ALLOW_SPLIT_PAYMET_BY_PERSONS: 'Allow split payment by persons',
  ALLOW_SPLIT_PAYMET_BY_ITEMS: 'Allow split payment by items',
  ALLOW_SPLIT_PAYMET_BY_GUESTS: 'Allow split payment by guests',
  ALLOW_PAY_MY_ITEMS: 'Allow pay my items',
  SERSVICE_CHARGE_EXCLUSION: 'Service Charge Exclusion Menu Item',
  AUTO_GRAVITY_EXCLUSION: 'Auto Gratuity Exclusion Menu Item',
  POST_POSITOUCH_PAYMENTS: 'Post Positouch Payments Immediately',
  // SUPPORTS_GIFT_CARD_PAYMENT: 'Supports gift card payment',
  SUPPORTS_GIFT_CARD_SALE: 'Supports gift card sale',
  // GIFT_CARD_PAYMENT_BUTTON_TEXT: 'Gift card payment button text',
  HEARTLAND: 'Heartland Setup',
  HEARTLAND_GIFT_CARD: 'Heartland Gift Card',
  SUPPRESS_POS_PRICE_CHECK: 'Suppress POS price check',
  SUPPRESS_AUTO_TEXT_THIRD_PARTY_ORDERS: 'Suppress auto text for third-party off-premise orders',
  PRINT_ORDERS_ON_SEND: 'Print orders on send',
  NOTIFY_ORDERS_ON_SEND: 'Notify orders on send',
  SUPPORTS_CHARITY: 'Supports Charity',
  DISPLAY_MODIFIERS_DESCRIPTIONS: 'Display modifier descriptions',
  DISPLAY_CART_AS_BUTTON: 'Display cart as button',
  RETRY_COUNT: 'Retry Count',
  USE_TABLE_REVENUE_CENTER: 'Use table revenue center',
  TEMPLATE: 'Template',
  AUTO_PRINT_GUEST_RECEIPT_ON_ORDER_SEND: 'Fireboard - auto print guest receipt on order send',
  AUTO_PRINT_PACKING_LABELS_ON_ORDER_SEND: 'Fireboard - auto print packing labels on order send',
  AUTO_PRINT_KITCHEN_TICKET_ON_ORDER_SEND: 'Fireboard - auto print kitchen ticket on order send',
  APPEND_SEAT_NUMBER_TO_ITEM_MEMEO: 'Append seat number to item memo',
  GIVEX: 'Givex Setup',
  GIVEX_GIFT_CARD: 'Givex Gift Card',
  VALIDATE_GIFT_CARD_SECURITY_CODE: 'Validate gift card security code',
  ENABLE_CHECK_TRANSFER_TRACKING: 'Enable check transfer tracking',
  ENALBEL_ITEM_SEAT_TRACKING: 'Enable item seat tracking',
  AUTO_GRAVITY_MENU_ITEM: 'Auto Gratuity Menu Item',
  COMBO_DISCOUNT: 'Combo Discount',
  MARKET_OVERRIDE_PRICE: 'Marketplace Override Price',
  COMBO_DISCOUNT_AMOUNT: 'Combo discount Amount',
  COMBO_ITEM: 'Combo Item',
  EXTERNAL_CATEGORIES: 'External Categories (comma separated)',
  RESTRICT_AUTO_TEXT_CURBSIDE_PICKUP_ORDERS: 'Restrict auto text to curbside pickup orders',

  // Campaigns
  CAMPAIGN: 'Campaign',
  CLASS: 'Class',
  CAMPAIGN_SETUP: 'Campaigns Setup',
  CAMPAIGN_LOCATION: 'Campaign Location',
  UPDATE_CAMPAIGN: 'Update Campaign',
  REDEMPTION_METHOD: 'Redemption Method',
  OFFER_TRIGGER: 'Offer Trigger',
  ADD_CAMPAIGN: 'Add Campaign',
  EDIT_CAMPAIGN: 'Edit Campaign',
  CAMPAIGN_CLASS: 'Campaign Class',
  CAMPAIGN_TYPE: 'Campaign Type',
  CAMPAIGN_SAVE_MSG: 'Campaign class and type cannot be changed once the campaign is saved. Are you sure you want to proceed with creating this campaign?',
  EVENT_TRIGGER: 'Event Trigger',
  GUEST_EVENT_TRIGGER: 'Guest Event Trigger',
  CAMPAIGN_STORE: 'Campaign Stores',
  EMAIL_TEMPLATE: 'Email Template',
  CAMPAIGN_SUBJECT: 'Campaign Subject',


  MDM_POLICY: 'MDM Policy',
  APPLY_POLICY: 'Apply Policy',
  INHERIT: 'Inherit',
  ALLOWED_APPS: 'Allowed Apps',
  ANDROID_APP: 'Android App IDs(saparated by comma)',
  // TAGS: 'Tags',
  // TAG_TYPES: 'Tag Types',
  TYPE_ADDRESS: 'Type Address',
  CONFIRM: 'Confirm',
  SET_UP: 'Setup',
  TMS_STATION_TAG_SETUP: 'Table Tag Setup',
  STATION_SETUP: 'Station Setup',
  MAX_CAPACITY: 'Max Capacity',
  MIN_CAPACITY: 'Min Capacity',
  INVENTORY: 'Inventory',
  ALLOW_WAITLIST_ON_SCAN: 'Allow wait list on scan',
  SUPPORTS_DAISY_CHAIN_OF_ORDERS: 'Supports daisy chain of orders',
  SUPPORTS_DAISY_CHAIN: 'Supports daisy chain',
  SUPPRESS_DAISY_CHAINS: 'Suppress daisy chain prompt',
  SUPPRESS_DAISY_CHAIN_NAME: 'Suppress daisy chain name',
  OFFERS: 'Offers',
  TICKETS: 'Tickets',
  CHECK_REPORT: 'Check Report',
  ADMIN_BATCH_DETAIL_REPORT: 'Batch Detail Report',
  SUPPRESS_ONLINE_ORDER_CHECK_IN: 'Suppress check-in for online order',
  SUPPRESS_PREP_TIME_ONLINE: 'Suppress Prep Time Online',
  DEFAULT_NUMBER_OF_GUESTS: 'Default number of guests',
  WALK_UP: 'Walk Up',
  CURBSIDE: 'Curbside',
  DRIVE_THRU: 'Drive thru',
  ONLINE_PICKUP_REVENUE_CENTER: 'Online Pickup Revenue Center',
  ONLINE_PICKUP_ORDER_TYPE: 'Online Pickup Order Type',
  ONLINE_PICKUP_ITEM_ORDER_TYPE: 'Online Pickup Item Order Type',
  ONLINE_DELIVERY_REVENUE_CENTER: 'Online Delivery Revenue Center',
  ONLINE_DELIVERY_ORDER_TYPE: 'Online Delivery Order Type',
  ONLINE_DELIVERY_ITEM_ORDER_TYPE: 'Online Delivery Item Order Type',
  ONLINE_DELIVERY: 'Online Delivery',
  ONLINE_PICKUP: 'Online Pickup',
  MARKET_PLACE: 'Market Place',
  SUPPRESS_SPOT: 'Suppress Spot #',
  SUPPRESS_CHECK_NO: 'Suppress check #',
  SPOT_TEXT: 'Spot # Text',
  CHECK_IN_NOTE: 'Check in Note',
  SPARKFLY_SETUP: 'Sparkfly Offers',
  COUPON_METHODS: 'Coupon Methods',
  IDENTITY: 'Identity',
  KEY: 'Key',
  CHANNEL_ID: 'Channel Id',
  SERVICE_CHARGE: 'Service Charge',
  BLUETOOTH: 'Bluetooth',
  CAMERA: 'Camera',
  SCREEN: 'Screen',
  VIBRATE: 'Vibrate',
  MODIFY_POLICY: 'Modify Policy',
  WIFI: 'WiFi',
  BACKUP_WIFI: 'Backup Wifi',
  REBOOT: 'Reboot',
  VOLUME: 'Volume',
  BRIGHTNESS: 'Brightness',
  SSID: 'SSID',
  APPLICATION: 'Application',
  HOURS: 'Hours',
  ORDER_UP: 'Order Up',
  MOBILE_APP: 'Mobile App',
  CUBBY: 'Cubby',
  // GRS_CURBSIDE_CHECKIN: 'GPS Curbside check in',
  ONLINE_PICKUP_LINK: 'Online Pickup Link',
  ONLINE_DELIVERY_LINK: 'Online Delivery Link',
  SUPPRESS_VEHICLE_INFO: 'Suppress Vehicle Info',
  AUTO_GRATUITY_TIP_RULE: 'Auto Gratuity Tip Rule',


  // localization
  INFO: 'Info',
  CUSTOMIZATION: 'Customization',
  BROWSE: 'Browse',
  FAVOURITE: 'Favorite',

  // Admin Menus
  API_LOGS_REPORT: 'API LOGS REPORT',
  MOBILE_WEB_CUSTOMIZATION: 'MobileWeb Customization',
  MOBILE_WEB_NEW_CUSTOMIZATION: 'MobileWeb New Customization',
  SERVERAPP_CUSTOMIZATION: 'ServerApp Customization',
  KIOSK_CUSTOMIZATION: 'Kiosk Customization',
  KDS_CUSTOMIZATION: 'Kds Customization',
  ORDER_UP_CUSTOMIZATION: 'OrderUp Customization',
  WAITLIST_CUSTOMIZATION: 'WaitList Customization',
  ROLE_TEMPLATES: 'Role Templates',
  MOBILE_NEW_WEB_CUSTOMIZATION: 'Mobile Web New Customization',


  // WareHouse Reports
  POS_SYNC: 'POS Sync',
  WAREHOUSE_REPORTS: 'Warehouse Reports',
  BATCH_WH_REPORT: 'Batch Report',
  RECONCILIATION_WH_REPORT: 'Reconciliation Report',
  TRANSACTIONS_WH_REPORT: 'Transactions Report',
  SURVEY_WH_REPORT: 'Survey Report',
  CHECK_INNER_WH_REPORT: 'Check Inner Report',
  CHECK_OUTER_WH_REPORT: 'Check Report',
  ROLLING_SALES_WH_REPORT: 'Rolling Sales Report',
  ITEM_SALES_WH_REPORT: 'Item Sales Report',
  ITEM_AVAILABILITY_REPORT: 'Item Availablity Report',
  SALES_AMOUNT: 'Amount',

  SUPPORTS_DISCOUNTS: 'Supports discounts',
  SUPPORTS_SERVICE_CHARGES: 'Supports service charges',
  SUPPORTS_CUSTOM_SORTING_OF_MENU_CATEGORIES: 'Support custom sorting of menu categories',
  SUPPORT_CUSTOM_SORTING_OF_MENU_ITEM: 'Support custom sorting of menu items',
  RELEASE_NOTE: 'Release Note',
  WHATS_NEW: 'What\'s New?',
  APPLINK_TYPES: 'AppLink Types',
  PAYMENT_ADJUSTMENT: 'Payment Adjustment',
  PRODUCT: 'Product',
  EXPAND_ALL: 'Expand All',
  COLLAPSE_ALL: 'Collapse All',
  DISPLAY_POS_NAME: 'Display POS name instead of curated name',
  UNIVERSITY: 'University',

  PAYPAL: 'PayPal',
  PAYPAY_SETUP: 'PayPal Setup',
  SUPPORT_PHONE_NUMBER: 'Support Phone Number',
  SUPPORT_EMAIL_ADDRESS: 'Support Email Address',

  STRIPE: 'Stripe',
  STRIPE_SETUP: 'Stripe Setup',
  PAYMENTSYSTEM_TYPE: 'Payment Type',
  PUBLISHABLE_KEY: 'Publishable Key',
  SECRET_KEY: 'Secret Key',
  PAYMENT_SETUP: 'Payment Setup',

  ACCOUNTING_ID: 'Accounting ID',
  ACCOUNTING_NAME: 'Accounting Name',
  INTACCT_ID: 'Intacct ID',
  SALES_FORCE_ID: 'SalesForce ID',
  STRIPE_ID: 'Stripe ID',

  // spreedly
  SPREEDLY_FOR_TOAST: 'Spreedly for Toast',
  SPREEDLY_FOR_TOAST_SETUP: 'Spreedly for Toast Setup',
  SPREEDLY_TOAST_ENVIRONMENT_KEY: 'Environment Key',

  // Delivery
  DELIVERY_SETUP: 'Delivery Setup',
  SELECT_BRAND: 'Select a Brand:',
  UPDTAE_USER_NOTIFICATION: 'Update User Notification',
  CHARGED: 'Charged',

  // offerManagement
  OFFER_TITLE: 'Offer Title',
  OFFER_DESCRIPTION: 'Offer Description',
  OFFER_LIST: 'Brand Offers',

  MENU_ITEM_LINK: 'Menu Item Link',
  RESET_USING_EMAIL: 'Reset using email',
  RESET_USING_SMS: 'Reset using sms',

  ORDER_MODE: 'Order Mode',
  TAKE_OUT: 'Take Out',

  // DELIVERY SETUP
  ADD_RULE: 'Add Tier',
  ADD_DELIVERY_SETUP: 'Add Delivery Rule',
  EDIT_DELIVERY_SETUP: 'Edit Delivery Rule',
  DELIVERY_RULE_LIST: 'Delivery Rules',
  ADD_SLOT: 'Add Slot',
  ADD_DELIVERY_RULE: 'Add Delivery Rule',
  EDIT_DELIVERY_RULE: 'Edit Delivery Rule',
  DELIVERY_SLOT_LIST: 'Delivery Slots',
  DELETE_SELECTED_ITEM: 'Are you sure you want to delete this item?',
  ZONE_TYPE: 'Zone Type',
  DISTANCE: 'Distance',
  DELIVERY_FEE: 'Delivery Fee',
  MIN_ORDER_AMOUNT: 'Min Order Amount',
  FEE_WAIVE_AMOUNT: 'Fee Waive Amount',
  WAIVE_FEE: 'Waive Fee',
  NUMBER_OF_DELIVERIES: 'Number of Deliveries',
  ADD_DELIVERY_SLOT: 'Add Delivery Slot',
  EDIT_DELIVERY_SLOT: 'Edit Delivery Slot',
  MAX_DRIVE_TIME: 'Max Drive Time',
  MAX_DRIVE_TIME_OPTIONAL: 'Max Drive Time (optional)',
  MIN_ORDER_AMOUNT_OPTIONAL: 'Min Order Amount (optional)',
  FEE_WAIVE_AMOUNT_OPTIONAL: 'Fee Waive Amount (optional)',
  EXCLUDE_DRIVE_TIME: 'Exclude Drive Time',
  THIRD_PARTY_DISPATCH: 'Third Party Dispatch (must be setup)',
  THIRD_PARTY: 'Third Party Dispatch',
  OVERRIDE_LEAD_TIME: 'Override Lead Time (minutes)',
  DELIVERY_RULES: 'Delivery Rules',
  DELIVERY_TIER_LIST: 'Delivery Tiers',
  DELIVERY_ADDRESS_TIER_SETUP: 'Delivery Address Tier Setup',
  ADD_DELIVERY_TIER: 'Add Delivery Tier',
  EDIT_DELIVERY_TIER: 'Edit Delivery Tier',
  CHECK_AMOUNT_TIER_SETUP: 'Check Amount Tier Setup',
  MIN_AMOUNT: 'Min Amount',
  MAX_AMOUNT: 'Max Amount',
  FEE_TYPE: 'Fee Type',

  // SENSOR GROUP
  JOIN_INTERVAL: 'Join Interval',
  ALLOW_JOIN_CHECK: 'Allow guests to join check',
  SELECT_OPTION: 'Select Option',
  ENABLE_TABLE_ORDERING: 'Enable Table Ordering',
  ENABLE_HOST_AND_GUEST_MODE: 'Enable Host And Guest Mode',
  DISABLE_FOOD_RUNNER: 'Disable Food Runner',
  ENABLE_EVENT_MODE: 'Enable Event Mode',
  FORCE_ORDERS_MENUES_AS_VIEW_ONLY: 'Force Order Menus As View Only',
  ENABLE_DELIVERY: 'Enable Delivery',
  ENABLE_CHECK_JOIN: 'Enable Check Join',
  TABLE_ORDER_ALLOWS_OPENING_CHECK: 'Table Order Allows Opening Check',
  ENABLE_OVER_CONSUMPTION_NOTIFICATION: 'Enable Over Consumption Notification',
  START_ON_HOME_PAGE: 'Start On Home Page',

  // BRAND_TAGS
  OVER_RIDDEN: 'Overridden',
  RESET_OVERRIDE_TAG: 'This will clear all overrides for this tag and reset to default values. do you want to continue?',
  USE_DEFAULT: 'Use Default',

  ADD_ICON: 'Add Icon',
  ICON: 'Icon',

  // Sys Notifications
  SYSTEM_NOTIFICATIONS: 'System Notifications',
  ADD_SYSTEM_NOTIFICATION: 'Add System Notification',
  CLONE_SYSTEM_NOTIFICATION: 'Clone System Notification',
  UPDATE_SYSTEM_NOTIFICATION: 'Update System Notification',
  SEVERITY: 'Severity',
  SYSTEM_NOTIFICATIONS_PUBLISH_MSG: 'This will publish the notification to all subscribed customers. This action cannot be undone. Are you sure you want to continue?',

  // SEGMENT
  SEGMENT_SETTINGS: 'Segment Settings',
  SEGMENT_SETUP: 'Segment Setup',
  ADD_BRAND_SEGMENT: 'Add Brand Segment',
  UPDATE_BRAND_SEGMENT: 'Update Brand Segment',
  BRAND_SEGMENTS: 'Brand Segments',
  TIME_OF_THE_DAY: 'Time Of the Day',
  SEGMENT_RULES: 'Segment Rules',
  SEGMENT_GUESTS: 'Segment Guests',
  SMS_OPT_IN: 'SMS Opt In',
  EMAIL_OPT_IN: 'Email Opt In',
  SMS_OPT_IN_DATE: 'SMS Opt In Date',
  SMS_OPT_OUT_DATE: 'SMS Opt Out Date',
  EMAIL_OPT_IN_DATE: 'Email Opt In Date',
  EMAIL_OPT_OUT_DATE: 'Email Opt Out Date',
  SEGMENT_GUEST_DETAILS: 'Segment Guest Details',
  SEGMENT_OFFERS: 'Segment Offers',
  CAMPAIGN_NAME: 'Campaign Name',
  OFFER_CODE: 'Offer Code',
  ISSUE_DATE: 'Issue Date',
  EFFECTIVE_DATE: 'Effective Date',
  EXPIRATION_DATE: 'Expiration Date',
  REDEEM_DATE: 'Redeem Date',
  INACTIVE_DATE: 'Inactivate Date',
  REISSUE_DATE: 'Reissue Date',
  LAST_EMAIL_REMINDER_DATE: 'Last Email Reminder Date',
  REISSUE: 'Reissue',
  ISSUE_OFFER: 'Issue Offer',
  REISSUE_OFFER: 'Reissue Offer',
  SELECT_CAMPAIGN_OCCURENCE: 'Select Campaign Occurence',
  GUESTS: 'Guests',
  ADD_SEGMENT_GUEST: 'Add Guest',
  DELETE_SELECTED_GUESTS: 'Delete Selected Guests',
  SEARCH_RESULTS: 'Search Results',
  ADD_SELECTED: 'Add Selected',
  ADD_SEGMENT_GUESTS: 'Add Segment Guests',
  EMAIL_OPT_OUT: 'Email Opt Out',
  SMS_OPT_OUT: 'SMS Opt Out',

  // Offers screen
  OFFERS_LIST: 'Offers List',
  GUEST_SEARCH: 'Guest Search',

  // SMS Templates
  SMS_TEMPLATES: 'Sms Templates',
  ADD_SMS_TEMPLATE: 'Add SMS Template',
  UPDATE_SMS_TEMPLATE: 'Update SMS Template',
  SMS_TYPE: 'Sms Type',
  SMS_TEMPLATE_ID: 'Sms Template Id',
  SELECT: 'Select',
  TOKEN: 'Token',
  MERGE_FIELD: 'Merge Field',
  SELECTED_SMS_TEMPLATE: 'Select an SMS template to customize',
  MEMBER_ID: 'Member Id',
  TERMS_ACCEPTED: 'Terms Accepted',
  TERMS_ACCEPTED_DATE: 'Terms Accepted Date',
  ADD_SEGMENT: 'Add Segment',
  // Campaign Occurrence

  CAMPAIGN_OCCURRENCE_LIST: 'Campaign Occurrence List',
  SELECT_CAMPAIGN_OCCURRENCE: 'Select a campaign',
  ADD_CAMPAIGN_OCCURRENCE: 'Add Campaign Occurrence',
  DELETE_CAMPAIGN_OCCURRENCE: 'Are You sure to Delete Campaign Occurrence',
  UPDATE_CAMPAIGN_OCCURRENCE: 'Update Campaign Occurrence',
  CAMPAIGN_OCCURRENCE_RULE: 'Campaign Occurrence Rule',
  ADD_CAMPAIGN_OCCURRENCE_RULE: 'Add Campaign Occurrence Rule',
  SELECT_STORE: 'Select Store',
  SELECT_SEGMENT: 'Select Segment',
  SEGMENT_NAME: 'Segment Name',
  TICKET_URL: 'Ticket Url',
  NOTES: 'Notes',

  // Email Templates
  EMAIL_TEMPLATES: 'Email Templates',
  ADD_EMAIL_TEMPLATE: 'Add Email Template',
  EMAIL_TYPES: 'Email Types',
  EMAIL_EDITOR: 'Email Editor',
  SELECTED_EMAIL_TEMPLATE: 'Select an Email template to customize',
  EMAIL_TYPE: 'Email Type',
  EMAIL_TEMPLATE_ID: 'Email Template Id',
  UPDATE_EMAIL_TEMPLATE: 'Update Email Template',
  SAMPLE_TEMPLATES: 'Sample Templates',
  REMINDER_EMAIL_TEMPLATES: 'Expiration Reminder Email Template',
  EXPIRATION_REMINDER_DAYS: 'Expiration Reminder Days',
  ADD_BRAND_TEMPLATE: 'Add Brand Template',
  ADD_STORE_TEMPLATE: 'Add Store Template',
  ADD_TEMPLATE: 'Add Template',
  EDIT_TEMPLATE: 'Edit Template',
  DISCOUNT_MAPPING: 'Discount Mapping',

  // SURVEY REPORT
  TOTAL_RESPONSES: 'Total Responses',
  CSAT_SCORE: 'CSAT Score',
  RESPONSES: 'Responses',
  COUNT: 'Count',
  NET_PROMOTER_SCORE: 'Net Promoter Score',
  QUESTION: 'Questions',
  PERCENTS: 'Percent',
  PROMOTERS: 'Promoters',
  PASSIVE: 'Passive',
  RESPONSE_DATE: 'Response Date',
  DETRACTORS: 'Detractors',
  RESPONSE_PERCENT: 'Response Percent',
  SHARE_RESPONSE: 'Share of Response',

  // Category
  CATEGORY: 'Category',
  ADD_CATEGORY: 'Add Category',
  ADD_STATION: 'Add Station',
  ADD_DISCOUNTS: 'Add Discounts',
  ADD_ORDER_TYPES: 'Add Order Types',
  EDIT_CATEGORY: 'Edit Category',
  SETTINGS: 'Settings',
  BIN_DIRECTORY: 'BIN Directory (e.g. C:\\BootDrv\\Aloha\\bin)',
  DATA_DIRECTORY: 'DATA Directory (e.g. C:\\BootDrv\\Aloha\\DATA)',
  MICRO_SERVER_NAME: 'Server Name (e.g. sqlS1234POS)',
  MICRO_DATA_BASE_NAME: 'Database Name (e.g. micros)',
  MICRO_DATA_BASE_UN: 'Database Username (e.g. custom)',
  MICRO_DATA_BASE_PW: 'Database Password',
  ADD_TABLE: 'Add Table',
  QUICK_ADD_TABLE: 'Quick Add Table',
  UPDATE_TABLE: 'Update Table',

  // External Campaign
  EXTERNAL_CAMPAIGN: 'External Campaign',

  // Order Reports
  ORDER_DETAILS: 'Order Details',
  ORDER_REPORT: 'Order Details Report',
  ADVANCE_ORDER_REPORT: 'Advance Order Report',
  ORDER_DISTRIBUTION: 'Order Distribution',
  SENSOR_REPORT: 'Sensor Report',
  BY_TIME_OF_THE_DAY: 'BY TIME OF THE DAY',
  ORDER_REPORT_DETAILS: 'Order Report Details',
  DELIVER_TO: 'Deliver To',
  PAYMENTS_DETAILS: 'Payments Details',
  LOYALTY_DETAILS: 'Loyalty Details',
  OFFER_DETAILS: 'Offer Details',
  SURVEY_DETAILS: 'Survey Details',
  MARKETPLACE_ORDERS_ID: 'Marketplace Order Id',
  DELIVERY_ORDER_ID: 'Delivery Order Id',

  // RESTAURANT_MAGIC
  RESTAURANT_MAGIC: 'Restaurant Magic',
  RESTRAURANT_MAGIC_SETUP: 'Restaurant Magic Setup',

  GUEST_DETAILS: 'Guest Details',
  MESSAGE_TYPE: 'Message Type',
  TRANSACTION_ID: 'Transaction ID',
  CONFIRMED_DATE: 'Confirmed Date',
  SENT_DATE: 'Sent Date',
  AGENT_FILE_NAME: 'Agent File Name',
  AGENT_FILE_SIZE: 'Agent File Size',
  AGENT_VERSION: 'Agent Version',

  // STORE
  ORDER_TYPES: 'Order Types',
  ADD_CATEGORIES: 'Add Categories',
  REVENUE_CENTERS: 'Revenue centers',
  UPDATE_DISCOUNTS: 'Update Discounts',
  UPDATE_REVENUE_CENTERS: 'Update Revenue Centers',
  UPDATE_TENDER_TYPES: 'Update Tender Types',
  ADD_REVENUE_CENTERS: 'Add Revenue centers',
  TENDER_TYPES: 'Tender types',
  ADD_TENDER_TYPES: 'Add Tender types',
  ALLOWS_TIPS: 'Allow Tips',
  ADD_EMPLOYEES: 'Add Employees',
  INVENTORY_AVAILABLE: 'Inventory Available',
  UPDATE_STATION: 'Update Station',
  UPDATE_CATEGORIES: 'Update Categories',
  UPDATE_ORDER_TYPES: 'Update Order Types',
  THIRDPARTY: 'Third Party',
  SORT_REVENUE_CENTERS: 'Sort Revenue centers',
  SORT_ORDER_TYPES: 'Sort Order Types',
  SERVICE_CHARGE_STORE: 'Service Charge',
  SERVICE_CHARGES_LIST: 'Service Charges',
  ADD_SERVICE_CHARGE: 'Add Service Charge',
  UPDATE_SERVICE_CHARGE: 'Update Service Charge',

  CHARGES: 'Charges',
  CHARGE_RULES: 'Charge Rules',
  CHARGE_TIER_LIST: 'Charge Tier List',
  ADD_TIER: 'Add Tier',
  EDIT_CHARGE_RULE: 'Edit Charge Rule',
  ADD_CHARGE_RULE: 'Add Charge Rule',
  CHARGE_TYPE: 'Charge Type',
  CHARGE_VALUE: 'Charge Value',
  // MAX_AMOUNT: 'Max Amount',
  // MIN_AMOUNT: 'Min Amount',
  ADD_CHARGE_TIER: 'Add Charge Tier',
  EDIT_CHARGE_TIER: 'Edit Charge Tier',
  MAX_ONLINE_ORDER_AMOUNT: 'Max Online Order Amount',
  MAX_ONLINE_ORDER_QUANTITY: 'Max Online Order Quantity',

  ADDITIONAL_CHARGES: 'Additional Charges',
  TIP_TIER: 'Tip Tiers',
  PRINT_ROUTING: 'Use for print routing',

  // Chowly
  CHOWLY: 'Chowly',
  CHOWLY_SETUP: 'Chowly Setup',

  // ROKT
  ROKT_ACCOUNT_ID: 'Account ID',
  ROKT_EXTERNAL_ID: 'External ID',

  // Marketplace
  MARKETPLACE: 'Marketplace',
  MARKETPLACE_SETUP: 'Marketplace Setup',

  // PRINT_CATEGORY
  PRINT_CATEGORY: 'Print Category',
  BRAND_PRINT_CATEGORIES: 'Brand Print Categories',
  ADD_PRINT_CATEGORY: 'Add Print Category',
  UPDATE_PRINT_CATEGORY: 'Update Print Category',

  // POS AGENT
  POS_AGENT_CONSOLE: 'POS Agent Console',
  POS_AGENT_STATUS: 'POS Agent Statuses',
  POS_AGENT_GET_AGENT_STATUS: 'Get Agent Status',
  COMPANY_NAMES: 'Company Name',
  BRAND_ID: 'Brand Id',
  LAST_CHECKIN: 'Last Check-in',
  START_DATE_TIME: 'Start Date and Time',
  END_DATE_TIME: 'End Date and Time',

  // Analytics
  ANALYTICS: 'Analytics',

  // Vouchers
  VOUCHERS: 'Vouchers',
  VOUCHER: 'Voucher',
  ADD_VOUCHERS: 'Add Voucher',
  UPDATE_VOUCHERS: 'Update Voucher',
  QUICK_EDIT_DATES: 'Quick Edit Dates',
  QUICK_ADD_TIMES: 'Quick Add Times',
  ADD_TIME: 'Add Time',
  QUANTITY_ALLOCATED: 'Quantity Allocated',
  QUANTITY_SOLD: 'Quantity Sold',
  SEND_MODE: 'Send Mode',

  // Sensor list Name
  STORE_ON_PREMISE_SENSOR: 'Store On-Premise Sensor',
  VENUE_ON_PREMISE_SENSOR: 'Venue On-Premise Sensor',
  OTHER_SENSOR: 'Other Sensor',
  SENSOR_TYPE: 'Sensor Type',
  OTHER_SENSOR_LIST: 'Other Sensor List',
  STORE_ON_PREMISE_SENSOR_LIST: 'Store On-Premise Sensor List',
  VENUE_ON_PREMISE_SENSOR_LIST: 'Venue On-Premise Sensor List',
  SENSOR_CODE: 'Sensor Code',

  // DEVICE GROUP

  DEVICE_GROUP: 'Device Group',
  ADD_DEVICE_GROUP: 'Add Device Group',
  DEVICE_TYPE: 'Device Type',
  EDIT_DEVICE_GROUP: 'Update Device Group',
  RECEIPT_PRINTED: 'Receipt Printed',
  SURVEY_POSTED: 'Survey Posted',
  CARD_PAYMENT: 'Card payment',
  CASH_PAYMET: 'Cash payment',
  PAYMENT_ERROR: 'Payment error',
  POS_POSTING_ERROR: 'POS posting error',
  ITEM_ORDERD: 'Item Ordered',
  ORDER_RECEIVED: 'Order received',
  ORDER_PRINTED: 'Order Printed',
  ORDER_ERROR: 'Order error',
  GUEST_CHECK_IN: 'Guest check  in',
  NOTIFICATION_TYPE_FILTERS: 'Notification Type Filters',
  FILTER_SOURCES: 'Filter Sources',
  TEXTTO_PAY: 'Text To Pay',
  // SCAN_TO_PAY: 'Scan To Pay',
  CHECK_FILTERS: 'Check Filters',
  CHANNEL_NOTIFICATION_FILTERS: 'Channel Notification Filters',
  SHOW_MOST_CURRENT_CHECK_FIRST: 'Show most current check first',
  ENABLE_AUTO_PRINTING: 'Enable auto printing',
  DISPLAY_ERROR_ORDER_WARNING: 'Display error order warning',
  HIDE_CURBSIDE_COUNTER: 'Hide curbside counter',
  HIDE_WALKUP_COUNTER: 'Hide walkup counter',
  HIDE_ONLINEE_COUNTER: 'Hide online counter',
  HIDE_CALL_IN_COUNTER: 'Hide call in counter',
  HIDE_PRE_AUTH_COUNTER: 'Hide pre auth counter',
  HIDE_PREP_TIME_DISPLAY: 'Hide prep time display',
  HIDE_PRODUCTION_DISPLAY: 'Hide production display',
  ENABLE_UPDATING_CHANNEL_SETTINGS: 'Enable updating channel settings',
  ENABLE_UPDATING_ORDER_SETTINGS: 'Enable updating order settings',
  ENABLE_UPDATING_PREP_TIME_PRODUCTION: 'Enable updating prep time and production',
  DISABLE_AUTO_PRINTING: 'Disable auto printing',
  START_MODE: 'Start Mode',
  PAGINATED_VIEW: 'Paginated View',
  GROUPED_VIEW: 'Grouped View',
  TILE_VIEW: 'Tile View',
  TABLE_DELIVERY_WARNING_TIME: 'Table Delivery Warning Time',
  TABLE_DELIVERY_ERROR_TIME: 'Table Delivery Error Time',
  CUBBY_WARNING_TIME: 'Cubby Warning Time',
  CUBBY_ERROR_TIME: 'Cubby Error Time',
  CUBBY_CLEAR_TIME: 'Cubby Clear Time',
  ORDER_UP_WARNING_TIME: 'Order Up Warning Time',
  ORDER_UP_ERROR_TIME: 'Order Up Error Time',
  ORDER_UP_CLEAR_TIME: 'Order Up Clear Time',
  PAYMENT_MODE: 'Payment Modes',
  DINE_IN: 'DineIn',
  TOGO: 'Togo',
  CASH: 'Cash',
  CREDIT_CARD: 'Credit Card',
  PHONE_NUMBER_REQUIRED: 'Phone Number Required',
  INPUT_FIELDS: 'Input Fields',
  EXTERNAL_CREDIT_CARD: 'External Credit Card',
  PAY_AT_COUNTER: 'Pay At Counter',
  USE_CHECKBOX_FOR_LABELS: 'Use checkbox for labels',
  ENABLE_REQUEST_ASSISTANCE: 'Enable Request Assistance',
  ENABLE_ACCESSIBILITY: 'Enable Accessibility',
  SUPPRESS_SERVICE_MODE: 'Suppress Counter Orders From Routing To Order Up',
  // SUPPRESS_PAIR_WATCH: 'Suppress Pair Watch',
  SERVICE_MODE: 'Service Mode',
  LANDSCAPE: 'Landscape',
  RECEIPT_TYPES: 'Receipt Types',
  PRINT: 'Print',
  TABLE_OPEN_TYPE: 'Table Open Type',
  SPEED_ORDER: 'Speed Order',
  SCAN: 'Scan',
  USE_JOB: 'Use Job',
  OFF_PREMISE: 'Off premise',
  SERIAL_NUMBER: 'Serial Number',
  BUILD_NUMBER: 'Build Number',
  TIMEOUT_INTERVAL: 'Timeout Interval (seconds)',
  ORDER_SEND_MODES: 'Order Send Modes',
  NONE: 'None',
  TO_GO: 'To Go',
  DO_NOT_MAKE: 'Do Not Make',
  RUSH: 'Rush',
  SPLIT_TYPES: 'Split Types',
  SPLIT_BY_PERSONS: 'Split By Persons',
  PAY_AN_AMOUNT: 'Pay An Amount',
  OTHER_ORDER_TYPES: 'Other Order Types',
  CALL_IN: 'Call In',
  AUTO_PRINT_ORDER_NUMBER_TICKET: 'Auto Print Order Number Ticket',
  DISPLAY_SUGGESTED_TIPS_ON_PRINTED_RECEIPT: 'Display Suggested Tips On Printed Receipt',
  ENFORCE_BEVERAGE_REQUIREMENT_BY_SEAT: 'Enforce beverage requirement by seat',
  ENFORCE_PIVOT_SEATING: 'Enforce pivot seating',
  DISABLE_ITEM_MEMOS: 'Disable Item Memos',
  DISABLE_MODIFIER_MEMOS: 'Disable Modifier Memos',
  SMS_GUEST_ON_ORDER_COMPLETE: 'SMS guest on order complete',
  LANDSCAPE_PAYMENT_MODE: 'Landscape Payment Mode',
  WARNING_TIME_ONE: 'Warning Time 1',
  WARNING_TIME_TWO: 'Warning Time 2',
  WARNING_TIME_THREE: 'Warning Time 3',
  DISPLAY_ORDER_TYPE: 'Display Order Type',
  DISPLAY_REVENUE_CENTER: 'Display Revenue Center',
  PRINT_ORDER_TICKET: 'Print Order Ticket',
  PRINT_ITEM_TICKET: 'Print Item Ticket',
  USE_ALPHANUMERIC_KEYPAD: 'Use Alphanumeric Keypad',
  ENABLE_SEND_QR_LINK: 'Enable Send QR Link',
  ENABLE_PRE_AUTH_COMPLETE: 'Enable Pre Auth Complete',
  GROUP_BY_SEAT: 'Group By Seat',
  PRINT_EXPO_ON_SEND: 'Print Expo On Send',
  ENABLE_TABLE_NUMBER: 'Enable Table Number Search',
  INCLUDE_MODIFIER_IN_ORDER_LABEL: 'Include modifiers in order label',
  AUTO_PRINT_JOIN_CHECK_QR: 'Auto Print Join Check QR',
  USE_TABLE_REVENUES_CENTERS: 'Use Table Revenue Center',
  KDS_SORT_TYPE: 'Sort type',
  BATCH_MODE_PREP_MODE: 'Batch Mode (Prep Station)',
  DISABLE_ORDERING: 'Disable Ordering',
  POWER_PAYMENT_MODE_ONLY: 'Power Payment Mode Only',
  ENABLE_LOGIN_WITH_CARD: 'Enable login with card',

  // AdminLanguages
  LANGUAGES: 'Languages',
  LANGUAGE: 'Language',
  LANGUAGE_ID: 'Language Id',
  LANGUAGE_LIST: 'Language List',
  MAKE_DEFAULT: 'Make Default',
  ADD_BRAND_LANGUAGE: 'Add Brand Language',
  SELECT_LANGUAGE: 'Select Language',
  SORT_LANGUAGE: 'Sort Language',
  MOBILE_WEB_LANGUAGES: 'Mobile Web Languages',
  SERVER_APP_LANGUAGES: 'Server App Languages',
  KIOSK_APP_LANGUAGES: 'Kiosk Languages',
  TABLE_TOP_APP_LANGUAGES: 'Table Top Languages',
  ORDER_UP_APP_LANGUAGES: 'Order Up Languages',


  // Store Production
  STORE_PRODUCTION: 'Store Production',
  ADD_STORE_PRODUCTION: 'Add Store Production',
  UDPATE_STORE_PRODUCTION: 'Update Store Production',
  INCLUDE_ON_PREMISE: 'Include On Premise',
  OVER_RIDE_PRODUCTION: 'Over Ride Production',
  TEMPORARY_OVERRIDE_FOR_DAY: 'Temporary Override For Day',
  PREP_WINDOW: 'Prep Window',
  TODAY_PREP_OVERRIDE: 'Today’s Prep Override',
  TODAY_PROD_OVERRIDE: 'Today’s Prod Override',
  TODAY_PRODUCTION_OVERRIDE: 'Today’s Production Override',
  EDIT_OVERRIDES: 'Edit Overrides',
  CLEAR_OVERRIDES: 'Clear Overrides',
  QUICK_ADD_STORE_PRODUCTION: 'Quick Add Store Production',
  QUICK_DELETE_STORE_PRODUCTION: 'Quick Delete Store Production',
  SLOT_INTERVAL: 'Slot Interval (Multiples of 5)',
  UPDATE_STORE_OVERRIDE_PRODUCTION: 'Update Store Override Production',
  OVERRIDE_REASON: 'Override Reason',

  // Receipt Screen
  EVENT_DETAILS: 'Event Details',
  CARD_NUMBER_RECEIPT: 'Card Number',

  // Cubby
  STORE_CUBBY: 'Store Cubby',
  ROW_COUNT: 'Row Count',
  COLUMN_COUNT: 'Column Count',
  ADD_CUBBY: 'Add Cubby',
  SLOTS: 'Slots',
  EDIT_CUBBY: 'Edit Cubby',
  AUTO_SEND_SMS_ON_ASSIGNMENT: 'Auto Send SMS On Assignment',

  // webhooks
  WISELY_WEBHOOKS: 'Wisely Webhooks',
  SPENDGO_WEBHOOKS: 'Spendgo Webhooks',
  SURVEY_PROGRAM_ID: 'Survey Program ID',
  WEBHOOKCLIENT_SECRET: 'Webhook Client Secret',
  WEBHOOK_CONTEXT: 'Webhook Context',
  SPENDGO_WEBHOOK: 'Spendgo Webhook',

  BUSSINESS_KEY: 'Bussiness Key',
  USE_POS_INTEGRATION: 'Use Pos Integration',
  HOST_GUEST_MODE: 'Host and guest mode',
  SUPPRESS_PAYMENT_BY_GUEST: 'Suppress Payment By Guest',
  ORDERING_AND_PAYMENT: 'Ordering and payment',
  // CAPTURE_GPS_LOCATION: 'Capture GPS Location On Checkout',
  ENFORCE_TIER_RESTRICTIONS: 'Enforce tier restrictions',
  ENABLE_PASSWORD_RESET: 'Enable password reset',
  REQUIRE_PASSWORD_FOR_SIGN_IN: 'Require password for sign in',
  LOYALTY_SIGN_UP_SMS_CONFIR: 'Loyalty sign up SMS confirmation',
  // SUPPRESS_OFFERS_FOR_SPLIT_BY_PERSONS: 'Suppress offers for split by persons',
  PERKS_CODE: 'Perks Code',
  CARD_TEMPLATE_CODE: 'Card Template Code',
  REDEMPTION_LABEL_TEXT: 'Redemption Label Text',
  REWARD_LABEL_TEXT: 'Reward Label Text',
  CHANNELS_COMMA_SEPARATED: 'Channels(comma separated)',
  ADMIN_KEY: 'Admin Key',
  POINTS_LABEL_TEXT: 'Points Label Text',
  TIER_LABEL_TEXT: 'Tier Label Text',
  LOYALTY_DISCLAIMER: 'Loyalty Disclaimer',
  SMS_OPT_IN_DISCLAIMER: 'SMS Opt In Disclaimer',
  EMAIL_OPT_IN_DISCLAIMER: 'Email Opt In Disclaimer',


  // Fiserv
  FISERV: 'Fiserv Gift Card',

  // Punchh Loyalty
  ACCOUNT_SIGNUP_METHODS: 'Account Signup methods',
  ACCOUNT_LOOKUP_METHODS: 'Account Lookup methods',
  ACCOUNT_OPT_IN_METHODS: 'Account OptIn methods',

  // Survey
  QUESTION_LIBRARY: 'Question Library',
  GUEST_SURVEY: 'Guest Survey',
  SORT_QUESTION: 'Sort Question',
  SELECT_QUESTION: 'Select Question',
  ENABLED_IN_LIBRARY: 'Enabled In Library',
  OPTIONAL_RULES: 'Optional Rules',

  // MENU Item
  BACK_IN_STOCK_ON: 'Back In Stock On',
  COMBO_UPSELL_AMOUNT: 'Combo Upsell Amount',
  COURSE: 'Course',
  OVERRIDE_ITEM_COURSE: 'Override item Course',

  // Manage Store
  MANAGE_STORE_MODIFIERS: 'Manage Store Modifier',
  MANAGE_STORE_MENU_ITEM: 'Manage Store MenuItem',
  SELECT_CATEGORIES: 'Select Categories',
  SELECT_MENU_ITEMS: 'Select MenuItem',
  MENU_ITEMS_BY_STORE: 'Menu Items By Store',
  MENU_ITEMS_BY_SCREEN: 'Menu Items By Screen',
  MODIFIERS_BY_STORE: 'Modifiers By Store',
  MODIFIERS_ACROSS_STORE: 'Modifiers Across Store',
  SELECT_MENU_SCREEN: 'Select Menu Screen',

  // EMPLOYEE APP
  MANAGER_APP: 'Manager App',
  VOID_ITEMS: 'Void Items',
  VOID_CHECK: 'Void Check',

  // Financial Accounts
  CHART_OF_ACCOUNTS: 'Chart of Accounts',
  STORE_ACCOUNTS: 'Store Accounts',
  ACCOUNT_TYPE: 'Account Type',
  BRAND_FINANCIAL_ACCOUNTS: 'Brand Financial Accounts',
  STORE_FINANCIAL_ACCOUNTS: 'Store Financial Accounts',
  EXTERNAL_NAME: 'External Name',
  EXTERNAL_CODE: 'External Code',
  BALANCE: 'Balance',
  TENDER_TYPE: 'Tender Type',
  STORE_ACCOUNT_BALANCE: 'Store Account Balance',
  ACCOUNT_BALANCES: 'Account Balances',
  ACCOUNT_NAME: 'Account Name',
  STORE_ACCOUNT_JOURNAL: 'Store Account Journal',
  ACCOUNT_JOURNAL: 'Account Journal',
  JOURNAL_DATE: 'Journal Date',
  DEBIT_ACCOUNT_TYPE: 'Debit Account Type',
  DEBIT_ACCOUNT_NAME: 'Debit Account Name',
  DEBIT_AMOUNT: 'Debit Amount',
  CREDIT_ACCOUNT_TYPE: 'Credit Account Type',
  CREDIT_ACCOUNT_NAME: 'Credit Account Name',
  CREDIT_AMOUNT: 'Credit Amount',
  JOURNAL_TYPE: 'Journal Type',

  // EmpApp Payment Adjustment
  VOID_REFUND_ADJUST: 'Void/Refund/Adjust',

  // Risk Rules
  RISK_RULES: 'Risk Rules',
  RISK_CONFIGURATION_SETUP: 'Risk Configuration Setup',
  ADD_RISK_CONFIGURATION: 'Add Risk Configuration',
  EDIT_RISK_CONFIGURATION: 'Edit Risk Configuration',
  RULES: 'Rules',
  RISK_CONFIGURATION: 'Risk Configuration',
  CLEAR: 'Clear',

  // Sift Fraud System
  SIFT: 'SIFT',
  SIFT_FRAUD_SYSTEM: 'SIFT Fraud System',
  SIFT_ACCOUNT_ID: 'Account ID',
  SIFT_API_KEY: 'API Key',
  BEACON_KEY: 'Beacon Key',
  ENABLE_DECISIONS: 'Enable Decisions',
  MAX_ACCEPT_SCORE: 'Max Accept Score',
  APPROVE_DECISION_IDS: 'Approve Decision ID\'s (separated by comma)',
  BLOCK_USER_DECISION_ID: 'Block User Decision ID',
  REPORT_CHARGE_BACK_DECISION_ID: 'Report Charge Back Decision ID',
  DATA_TRANSFER_ONLY: 'Data Transfer Only (Do Not Validate)',

  // Marketman
  MARKETMAN: 'Marketman',
  MARKETMAN_INVENTORY: 'MarketMan Inventory',

  // Apple pay
  UPDATE_APPLE_DOMAIN_VERIFICATION_FILE: 'Update Apple Domain Verification File',
  APPLE_PAY_HEADER_TEXT: 'Paste the domain verification file content exactly as received from Apple site',

  // Server App
  STORE_LAYOUT_BY_SENSOR_GROUP: 'Store Layout By Sensor Group',
  DISPLAY_SUGGESTED_TIP_ON_RECEIPT: 'Display suggested tip on receipt',

  // TSD Global Third party
  TSD_GLOBAL: 'TSD Global',
  DEVICE_ID: 'Device Id',
  TSD_LOCATION_IDENTIFIER: 'TSD Location Identifier',
  SELECTED_DEVICE: 'Select Device',

  // Custom menu item
  DELIVERY_VEHICLE_TYPE: 'Delivery Vehicle Type',
  CATERING: 'Catering',
  CALL_CENTER: 'Call Center',

  // Other setting(brand)
  PRE_ORDER_SETTINGS: 'Pre Order Settings',
  PRE_ORDER_DURATION: 'Pre Order Duration (minutes)',
  MIN_PRE_ORDER_FREQUENCY: 'Min Pre Order Frequency (minutes)',
  MAX_PRE_ORDER_QUANTITY: 'Max Pre Order Quantity',
  ALLOW_MODIFIER_GROUP_MIN_MAX_EDIT: 'Allow Modifier Group Min/Max Edit (only enable after due consideration)',
  RESTRICT_MANAGER_TO_SAME_DAY_PAYMENT_ADJUSTMENT: 'Restrict non-admin users to same day payment adjustments',
  ENABLE_SERVER_APP_RELAUNCH: 'Enabled server app relaunch',
  SERVER_APP_RELAUNCH_INTERVAL: 'Server app relaunch interval (minutes)',
  DISABLE_ORDER_SUBMIT_ON_PRiCE_CHECK_ERROR: 'Disable order submit on price check error',
  EMPLOYEE_NAME_IN_CHECK_NOTE: 'Employee name in check note',
  SEND_GUEST_CHECK_IN_MESSAGE_TO_POS: 'Send guest check-in message to POS (POSI only)',
  PRINT_NAGATIVE_SURVEY_LABEL: 'Print negative survey label',
  PRINT_GUEST_CHECK_IN_MESSAGE: 'Print guest check-in message',

  // MENU COURSES:
  COURSES: 'Courses',
  UPDATE_MENU_COURSE: 'Update Courses',
  ADD_MENU_COURSE: 'Add Course',
  BRAND_MENU_COURSE: 'Courses',
  SORT_COURSES: 'Sort Courses',
  DELAY: 'Delay',
  APPLICABLE_TO_SEAT_BEVERAGE_REQUIREMENT: 'Applicable to seat beverage requirement',
  APPLICABLE_TO_PIVOT_SEATING: 'Applicable to pivot seating',
  FIRE_NEXT_COURSE_WITHOUT_DELAY: 'Fire next course without delay',

  // heartland
  HEARTLAND_PAYMENT: 'Heartland Payment',
  PUBLIC_KEY: 'Public Key',

  // Discount
  JOB_VISIBILITY: 'Job Visibility',
  MANAGER: 'Manager',
  BARTENDER: 'Bartender',
  CASHIER: 'Cashier',

  // Off Premise Mapping
  PHONE_NO_MODIFIER: 'Phone Number Modifier',
  DELIVER_TO_MODIFIER: 'Deliver To Modifier',
  PICK_UP_TIME_MODIFIER: 'Pickup Time Modifier',
  VEHICLE_INFO_MODIFIER: 'Vehicle Info Modifier',
  MARKET_PLACE_NAME_MODIFIER: 'Marketplace name modifier',

  // Brand => Channels => Configure
  ASAP_ORDERS: 'ASAP Only',

  // Data Request Report
  MEEMBER_ID: 'Member Id',
  LAST_UPDATED_ON: 'Last Updated On',
  DATA_REQUEST_COMPLETED_BY: 'Data Request Completed By',
  DATA_REQUEST_COMPLETED_ON: 'Data Request Completed On',
  DELETE_DATA_COMPLETED_BY: 'Delete Data Completed By',
  DELETE_DATA_COMPLETED_ON: 'Delete Data Completed On',

  // UserWay
  USERWAY: 'UserWay',
  USERWAY_ACCESSIBILITY_WIDGET: 'UserWay Accessibility Widget',

  // Mobile Payment Setup - Global Payments
  APP_ID: 'App Id',
  APP_KEY: 'App Key',

  // cabbagepay
  CABBAGEPAY_SETUP: 'Cabbage Pay',
  CONNECTED_ACCOUNT_ID: 'Connected Account Id',

  // Menu-> marketplace State Restriction
  MARKETPLACE_STATE_RESTRICTION: 'Marketplace State Restriction',
  MARKETPLACE_STATE_RESTRICTIONS: 'Marketplace State Restrictions',

  // Otter
  OTTER: 'Otter',
  OTTER_SETUP: 'Otter Setup',
  PAIR: 'Pair',
  ENTER_EVENT_ID_FROM_OTTER_ONBOARDING_EMAIL: 'Enter event Id from Otter onboarding email',

  // Brand - Happy Hour
  HAPPY_HOUR: 'Happy Hour',
  ADD_HAPPY_HOUR_RULE: 'Add Rule',
  EDIT_HAPPY_HOUR_RULE: 'Edit Rule',
  ID_HAPPY_HOUR: 'Id',
  NAME_HAPPY_HOUR: 'Name',
  SCHEDULES_HAPPY_HOUR: 'Schedules',
  EDIT_HAPPY_HOUR: 'Edit',
  DELETE_HAPPY_HOUR: 'Delete',
  SAVE_HAPPY_HOUR: 'Save',
  NOTE_HAPPY_HOUR: 'Important: Please click the Save Button to persist the changes',
  ADD_SCHEDULE_HAPPY_HOUR: 'Add Schedule',
  DAY_OF_THE_WEEK_HAPPY_HOUR: 'Day of the week',
  START_TIME_HAPPY_HOUR: 'Start Time',
  END_TIME_HAPPY_HOUR: 'End Time',
  PRICE_LEVEL_HAPPY_HOUR: 'Price Level',
  RULE_NAME_HAPPY_HOUR: 'Rule Name',
  ADD_RULE_SCHEDULE: 'Add Rule Schedule',
  EDIT_RULE_SCHEDULE: 'Edit Rule Schedule',
  SCHEDULES_LIST_HAPPY_HOUR: 'Schedules List',

  // KDS Setup
  KDS: 'KDS',
  KDS_SETTINGS: 'Settings',
  UPDATE_KDS_SETTINGS: 'Update KDS Settings',
  KDS_ENABLED: 'Enabled',
  DUAL_BUMP: 'Dual Bump',
  PREP_TIME_SECONDS: 'Prep Time (seconds)',
  BUILD_TIME_SECONDS: 'Build Time (seconds)',
  ROUTE_PARENT: 'Route Parent',
  PREP_PARENT: 'Prep For Parent',
  STATION_TYPES: 'Station Types',
  EDIT_KDS_STATION: 'Edit KDS Station',
  ADD_KDS_STATION: 'Add KDS Station',
  UPDATE_KDS_STATION: 'Update KDS Station',
  LAYOUT_RULES: 'Layout Rules',
  KDS_LAYOUTS: 'KDS Layouts',
  ADD_KDS_LAYOUT: 'Add KDS Layout',
  UPDATE_KDS_LAYOUT: 'Update KDS Layout',
  PREP_STATION_CAPACITY: 'Prep Station Capacity',
  PREP_STATION: 'Prep Station',
  BUILD_STATION: 'Build Station',
  EXPO_STATION: 'Expo Station',
  // PREP_DEVICE: 'Prep Device',
  // BUILD_DEVICE: 'Build Device',
  // EXPO_DEVICE: 'Expo Device',
  KDS_APP_LANGUAGES: 'KDS Languages',
  KDS_LABELS: 'KDS Labels',
  RUN_BASKET_TESTER: 'Run Basket Tester',
  BASKET_TESTER: 'Basket Tester',
  SCHEDULE_TEST: 'Schedule Test',
  VIEW_TEST_RESULT: 'View Test Result',
  SCHEDULED: 'Scheduled',
  COMPLETE: 'Complete',
  COMPLETE_DATE: 'Complete Date',
  HAS_ERRORS: 'Has Errors',
  SIMULATE_KDS: 'Simulate KDS',
  REFRESH_STATUS: 'Refresh Status',
  KDS_RULES: 'KDS Rules',
  MENU_ID: 'Menu Id',
  MENU_NAME: 'Menu Name',
  ERRORS_ONLY: 'Errors only',
  MARKET_BASKET_TESTER: 'Market Basket Tester',
  MENU_ITEM_HIERARCHY: 'Menu item hierarchy',
  SCREEN_ID: 'Screen Id',
  SCREEN_NAME: 'Screen Name',
  PREP_ITEM: 'Prep Item',
  BUILD_ITEM: 'Build Item',
  PREP_PORTION_SIZE: 'Prep Portion Size',
  BUILD_PORTION_SIZE: 'Build Portion Size',
  RECALL_TIME: 'Recall Time (seconds)',
  SORT_KDS_LAYOUT: 'Sort KDS Layout',
  PRINT_RULES: 'Print Rules',
  ADD_KDS_PRINT_DEFINITION: 'Add KDS Print Definition',
  KDS_PRINT_DEFINITIONS: 'KDS Print Definitions',
  UPDATE_KDS_PRINT_DEFINITION: 'Update KDS Print Definition',
  KDS_PRINT_RULES: 'KDS Print Rules',
  PRIORITIZE_PARENT: 'Prioritize Parent',
  CONCURRENT_WITH_CHILDREN: 'Concurrent With Children',
  PRINT_SEPARATE_PREP_TICKET: 'Print Separate Prep Ticket',
  SUPPRESS_PREP_TICKET_PRINT: 'Suppress Printing Prep Ticket',
  CONSOLIDATE_ITEMS_TIME: 'Consolidate items time (seconds)',
  PRINT_OFF_PREMISE_EXPO_ON_SEND: 'Print Off Premise Expo On Send',
  ON_PREMISE_HOLD_TIME: 'On Premise Hold Time',
  OFF_PREMISE_HOLD_TIME: 'Off Premise Hold Time',
  CONSOLIDATE_EXPO_STATION: 'Consolidate Expo Station',


  // Amazon Pay
  AMAZON_PAY: 'Amazon Pay',
  PUBLIC_KEY_ID: 'Public Key Id',
  PRIVATE_KEY: 'Private Key',

  // Menu labels
  GENERAL: 'General',
  DISPLAY: 'Display',
  MENU_DISPLAY_NAME: 'Menu Display Name ',
  MENU_DESCRIPTION: 'Menu Description',
  MENU_DISCLAIMER: 'Menu Disclaimer',
  MENUSCREEN_DISPLAY_NAME: 'Menuscreen Display Name ',
  MENUSCREEN_DESCRIPTION: 'Menuscreen Description',
  MENUSCREEN_DISCLAIMER: 'Menuscreen Disclaimer',
  MENUITEM_DISPLAY_NAME: 'Menuitem Display Name',
  MENUITEM_DESCRIPTION: 'Menuitem Description',
  MENUITEM_DISCLAIMER: 'Menuitem Disclaimer',
  MODIFIER_GROUP_DISPLAY_NAME: 'Modifiergroup Display Name',
  MODIFIER_DISPLAY_NAME: 'Modifier Display Name ',
  MODIFIER_DESCRIPTION: 'Modifier Description',
  MODIFIER_DISCLAIMER: 'Modifier Disclaimer',
  // Menu Item Reorganisation
  KITCHEN: 'Kitchen',

  // modifier
  INCLUSIONS: 'Inclusion',

  // Menu -> Menu Item Clone
  ITEM_ONLY: 'Item Only',
  ITEM_MOIDIFIER_GROUP: 'Item + Modifier Groups',
  CLONE_MENU_ITEM: 'Clone menu item',

  // Menu -> Modifier clone
  MODIFIER_ONLY: 'Modifier Only',
  MODIFIER_MODIFIER_GROUP: 'Modifier + Modifier Groups',
  CLONE_MODIFIER: 'Clone Modifier',
  EDIT_MODIFIER_SCREEN: 'Edit Modifier',

  // Medallia
  MEDALLIA: 'Medallia',
  BASE_URL: 'Base URL',
  ONLINE_LABEL: 'Online Label',
  PRINT_LABEL: 'Print Label',
  CLICK_HERE: 'Click here',
  HELP_TEXT: 'To split the text into separate lines, include a “|” separator where you would like the line break.',

  // Mobile App
  MOBILE_APP_MEDIA: 'Media',
  MOBILE_APP_MEDIAS: 'Mobile App Medias',
  ID_IMAGE: 'Id',
  EDIT_MOBILE_APP_IMAGE: 'Edit',
  NOTE_MOBILE_APP: 'Important: Please click the Save Button to persist the changes',
  IMAGE_NAME: 'Name',
  PAGES: 'Pages',
  MOBILE_APP_PAGES: 'Mobile App Pages',
  MOBILE_APP_STYLE: 'Style',
  BUILT_IN_PAGE_TYPE: 'Built-in Page Type',
  PAGE_TYPE: 'Page Type',
  ADD_MOBILE_APP_PAGE: 'Add Page',
  EDIT_MOBILE_APP_PAGE: 'Edit Page',
  ADD_MEDIA: 'Add Media',
  UPDATE_MEDIA: 'Update Media',
  VIEWUPLOAD: 'View/Upload',
  MOBILE_APP_LAYOUT: 'Layout',
  MOBILE_APP_LAYOUTS: 'Layouts',
  MOBILE_APP_LAYOUTS_TITLE: 'Mobile App Layouts',
  ENABLE_FOR_PRODUCTION: 'Enable for Production',
  ENABLE_FOR_TEST: 'Enable for Test',
  DESIGN: 'Design',
  ADD_MOBILE_APP_LAYOUT: 'Add Layout',
  EDIT_MOBILE_APP_LAYOUT: 'Edit Layout',
  ENABLED_FOR_PRODUCTION: 'Enabled for Production',
  ENABLED_FOR_TEST: 'Enabled for Test',
  MOBILE_APP_TEST: 'Test',
  ADD_BUTTON: 'Add Button',
  DELETE_BUTTON: 'Delete Button',
  SELECT_IMAGE: 'Select Image',
  SELECT_PAGE: 'Select Page',
  LABEL: 'Label',
  ENABLE_ORDER_HISTROY: 'Enable Order History',
  ENABLE_PAYMENT_WALLET: 'Enable Payment Wallet',
  ENABLE_ADDRESS_BOOK: 'Enable Address Book',
  ENABLE_LOYALTY_WALLET: 'Enable Loyalty Wallet',
  DELETE_SELECTED_BUTTON: 'This will delete the button. Do you want to continue??',
  PAGE_EDITOR: 'Page Editor',
  TILE_EDITOR: 'Tile Editor',
  SECTION_EDITOR: 'Section Editor',
  MOBILE_ADD_TILE: 'Add Tile',
  MOBILE_DELETE_TILE: 'Delete Tile',
  TILE_MARGIN: 'Tile Margin',
  TILE_BORDER: 'Tile Border',
  MOBILE_APP_COLUMNS: 'Columns',
  MOBILE_APP_ADD_HEADING: 'Add Heading',
  MOBILE_APP_ADD_TEXT: 'Add Text',
  MOBILE_APP_ADD_IMAGE: 'Add Image',
  MOBILE_APP_ADD_SECTION: 'Add Section',
  MOBILE_APP_DELETE_SECTION: 'Delete Section',
  SECTION_MARGIN: 'Section Margin',
  SECTION_BORDER: 'Section Border',
  MOBILE_APP_ALIGNMENT: 'Alignment',
  MOBILE_APP_ACTION: 'Action',
  MOBILE_APP_SELECT_PAGE: 'Select Page',
  MOBILE_APP_ENTER_URL: 'Enter URL',
  MOBILE_APP_SELECT_IMAGE: 'Select Image',
  MOBILE_APP_NO_IMAGE_FOUND: 'No Image found',
  MOBILE_APP_ADD_BUTTON_LABEL: 'Add Button label',
  MOBILE_APP_ADD_COLLAPSIBLE_DATA: 'Add Heading or Text for collapsible',
  MOBILE_APP_PAGE: 'Mobile App Page',
  MOBILE_APP_PAGE_DESIGN: 'Mobile App Page Design',
  MOBILE_APP_LAYOUT_DESIGN: 'Mobile App Layout Design',
  MOBILE_APP_MARGIN: 'Margin',
  MOBILE_APP_PADDING: 'Padding',
  MOBILE_APP_TOP: 'Top',
  MOBILE_APP_BOTTOM: 'Bottom',
  MOBILE_APP_LEFT: 'Left',
  MOBILE_APP_RIGHT: 'Right',
  ELEMENTS: 'Elements',
  GOOGLE_MAPS_API_KEY: 'Google Maps API Key',
  DEFAULT_TILE_MARGIN: 'Default Tile Margin',
  DEFAULT_TILE_PADDING: 'Default Tile Padding',
  DEFAULT_SECTION_MARGIN: 'Default Section Margin',
  DEFAULT_SECTION_PADDING: 'Default Section Padding',


  // External Integrations
  EXTERNAL_INTEGRATIONS: 'External Integrations',
  EXTERNAL_INTEGRATIONS_LIST: 'External Integrations List',
  ADD_EXTERNAL_INTEGRATION: 'Add External Integration',
  API_ACCESS: 'API Access',
  OOS_WEBHOOK: 'OOS Webhook',
  WEBHOOK_URL: 'Webhook URL',
  REGENERATE: 'Regenerate',
  DELETE_SELECTED_EXTERNAL_INT: 'This will delete the current Integration. Existing systems may be impacted. Do you want to continue?',
  OUT_OF_STOCK_WEBHOOK: 'Out Of Stock Webhook',
  EXTERNAL_INTEGRATION_CREDENTIALS_PREVIEW_DISCLAIMER: 'Please note the Credentials displayed below. Credentials will only be displayed once on this screen and cannot be retrieved again.',
  API_AUTH: 'API Auth',
  WEBHOOK_API_KEY: 'Webhook API Key',
  WEBHOOK_SECRET: 'Webhook Secret',
  ADD_EXTERNAL_INTEGRATION_CONFIRMATION: 'This will setup a new External Integration. Do you want to continue?',
  API_KEY_EXTERNAL_INTEG: 'API Key',
  UPDATE_EXTERNAL_INTEGRATION: 'Update External Integration',
  UPDATE_EXTERNAL_INTEGRATION_CONFIRMATION: 'This will disable the Integration and may affect existing systems. Do you want to continue?',
  REGENERATE_EXTERNAL_INTEGRATION_CONFIRMATION: 'This will regenerate Credentials for the current Integration. Existing systems may be impacted. Do you want to continue?',
  CHECK_CLOSED_WEBHOOK: 'Check Updated Webhook',
  CU_WEBHOOK: 'CU Webhook',
  API_WEBHOOKS: 'API & Webhooks',
  API_WEBHOOKS_LIST: 'API & Webhooks List',
  WEBHOOKS: 'Webhooks',
  CHECK_UPDATED: 'Check Upated',
  // ORDER_UPDATED: 'Order Updated',
  OUT_OF_STOCK: 'Out Of Stock',
  ENABLE_API_ACCESS: 'Enable API Access',
  WEBHOOKTYPE: 'Webhook Type',

  // Happy Cog
  HAPPY_COG: 'Happy Cog',
  HAPPY_COG_SETUP: 'Happy Cog Setup',
  ENTER_EVENT_ID_FROM_HAPPY_COG_ONBOARDING_EMAIL: 'Enter event Id from Happy Cog onboarding email',
  HAPPY_COG_CONFIRMATION_MESSAGE: 'This will create a new location in Happy Cog. Do you want to continue?',

  // waitList
  WAIT_LIST_LANGUAGES: 'Wait List Languages',
  WAIT_LIST: 'Wait List',
  // orderUpTheme
  ORDER_UP_THEME: 'Order Up Theme',
  // Waitlist Theme
  WAITLIST: 'Wait List',
  WAITLIST_THEME: 'Wait List Theme',
  ASSIGN_TABLE: 'Assign Table Colors',
  CREATE_WAITLIST: 'Create Waitlist Colors',
  EMPLOYEE_LOOKUP: 'Employee Lookup Colors',
  WAITLIST_COLOR: 'Waitlist Colors',
  TABLE_PROPERTIES: 'Table Properties Colors',
  MERGE_TABLE: 'Merge Table Colors',
  TMS_MENU: 'TMS Menu Colors',
  HELP_POPUP: 'Help Popup Colors',
  CHECK_LOOKUP: 'Check Lookup Colors',
  SNACK_BAR: 'Snack Bar Colors',
  CHECK_DETAIL: 'Check Details Colors',
  ABOUT_POPUP: 'About Popup Colors',
  DESIGNER: 'Designer Colors',
  CONFIRMATION_POPUP: 'Confirmation Popup',


  // Comapany > Integration Configuration
  INTEGRATION_CONFIGURATION: 'Integration Configuration',
  BRAND_EXTERNAL_ID: 'Brand External Id',
  STORE_EXTERNAL_ID: 'Store External Id',

  // Portal version-4
  VERSION_4: 'Version 4.0',
  VIEW_COMPANY: 'Company List',
  APPLE_PAY_VERIFICATION: 'Apple Pay Verification',
  COMPANY_SEARCH_PLACEHOLDER: 'Search Company',
  COMPANY_DROPDOWN_PLACEHOLDER: 'Select Company',
  COMPANY_NO_DATA: 'No Companies found',
  BRAND_SEARCH_PLACEHOLDER: 'Search Brand',
  BRAND_DROPDOWN_PLACEHOLDER: 'Select Brand',
  BRAND_NO_DATA: 'No Brands found',
  STORES_NO_DATA: 'No Stores found',


  // Portal 4 - User settings
  LEFT_PANEL_OR_MENU: 'Left Panel / Menu',
  RIGHT_PANEL_OR_MENU: 'Right Panel / Menu',
  DROPDOWN: 'Dropdown',
  LIST: 'List',
  ACCORDION: 'Accordion',
  DETAIL_LEVEL_1: 'Name',
  DETAIL_LEVEL_2: 'Name + Number',
  DETAIL_LEVEL_3: 'Full details',
  COMPACT_VIEW: 'Compact',
  MEDIUM_VIEW: 'Medium',
  LARGE_VIEW: 'Large',
  SEARCH_STORES: 'Search Stores',

  // Portal 4 - Left section
  ALPHABET_C: 'C',
  ALPHABET_B: 'B',
  ALPHABET_S: 'S',

  // Portal 4 - Header
  ADMIN_SETTINGS: 'Admin Settings',
  HELP: 'Help',
  PROFILE_SETTINGS: 'Profile Settings',
  SIDE_MENU_FILTER: 'Side Menu Filter',

  // Device Group => Server App
  SEND_MODES: 'Send Modes (non-integrated only)',
  KEEP_IT_OPEN: 'Keep It Open',

  // Manager App
  APPLY_DISCOUNT: 'Apply Discount',
  SELECT_DISCOUNT: 'Select Discount',
  DAMOUNT: 'Amount',

  // Icons
  COLUMN_VIEW: 'Column View',
  // Mobile app fonts
  FONT_SIZE_MESSAGE: 'Would you like to change default Font size?',
  FONT_FAMILY_MESSAGE: 'Would you like to change default font style?',
  PAGE_TITLE_FONT_FAMILY: 'Page Title Font Family',
  PAGE_TITLE_FONT_SIZE: 'Page Title Font Size',
  BOTTOM_BAR_FONT_FAMILY: 'Bottom Bar Font Family',
  BOTTOM_BAR_FONT_SIZE: 'Bottom Bar Font Size',
  TEXT_VIEW_FONT_FAMILY: 'Text View Font Family',
  TEXT_VIEW_FONT_SIZE: 'Text View Font Size',
  BUTTON_FONT_FAMILY: 'Button Font Family',
  BUTTON_FONT_SIZE: 'Button Font Size',
  SECTION_HEADER_TEXT_FONT_FAMILY: 'Header Font Family',
  SECTION_HEADER_TEXT_FONT_SIZE: ' Header Font Size',
  BODY_TEXT_FONT_FAMILY: 'Body Text Font Family',
  BODY_TEXT_FONT_SIZE: 'Body Text Font Size',
  TEXT_FIELD_FONT_FAMILY: 'Text Font Family',
  TEXT_FIELD_FONT_SIZE: 'Text Font Size',
  PLACEHOLDER_FONT_FAMILY: 'Placeholder Font Family',
  PLACEHOLDER_FONT_SIZE: 'Placeholder Font Size',
  HEADER_TEXT_FONT_FAMILY: 'Header Font Family',
  HEADER_TEXT_FONT_SIZE: 'Header Font Size',
  TEXT_FONT_FAMILY: 'Text Font Family',
  TEXT_FONT_SIZE: 'Text Font Size',

  // Intercard ThirdParty
  INTERCARD: 'Intercard',
  GAME_CARD_MENU_ITEM: 'Game Card Menu Item',
  CASH_BONUS: 'Cash Bonus',
  CREDIT_TOKEN: 'Credit Token',
  CREDIT_TOKEN_BONUS: 'Credit Token Bonus',
  CREDIT_POINTS: 'Credit Points',
  TIME_PLAY_DURATION: 'Time Play Duration',
  ADD_MENU_ITEM_MAPPING: 'Add Menu Item Mapping',
  MENU_ITEM_MAPPINGS: 'Menu Item Mappings',
  MENU_ITEM_ADD: 'Add Menu Item',
  ENTRY_METHODS: 'Entry Methods',
  UPSELLS: 'Upsells',
  UPSELL_MAPPING: 'Upsell Mapping',
  ADD_UPSELL_MENU_ITEM_MAPPING: 'Add Upsell Menu Item Mapping',
  EDIT_UPSELL_MENU_ITEM_MAPPING: 'Edit Upsell Menu Item Mapping',

  // powercard ThirdParty
  POWERCARD: 'Powercard',
  RATE_CARD_ID: 'Rate Card Id',
  SUPERCHARGE_MODIFIER: 'Supercharge Modifier',
  GAME_CARD_MODIFIER: 'Game Card Modifier',
  GAME_ACTIVATE_MENU_ITEM: 'Activation Menu Item',
  MODIFIER_MAPPINGS: 'Modifier Mappings',
  ADD_MODIFIER_MAPPINGS: 'Add Modifier Mapping',
  MODIFIER_ITEM_ADD: 'Add Modifier Item',
  CASH_BONUS_MENU_ITEM: 'Cash Bonus Menu Item',
  CREDIT_TOKEN_BONUS_MENU_ITEM: 'Credit Token Bonus Menu Item',
  ACTIVATION: 'Activation',
  NEW_ACTIVATION: 'New Activation',
  PLEASE_CONTACT_SUPPORT_TO_SETUP: 'please contact support to setup',

  // IMPORT_GUESTS
  IMPORT_GUESTS: 'Import Opt-In',
  DOWNLOAD_SAMPLE_CSV: 'Download Sample CSV',
  IMPORT_CSV: 'Import CSV',
  SUCCESS: 'Success',
  ERROR: 'Error',

  // Gift card ThirdParty
  GIFTCARD: 'Gift Card',
  GIFT_CARD_SALE: 'Gift Card Sale',
  ADD_GIFT_CARD_MENU_ITEM: 'Add Gift Card Menu Item',
  GIFT_CARD_MENU_ITEM: 'Gift card Menu Item',
  BONUS_THRESHOLD: 'Bonus Threshold',
  BONUS_REQUIRES_SINGLE_PURCHASE: 'Bonus Requires Single Purchase',
  BONUS_START_DATE: 'Bonus Start Date',
  BONUS_END_DATE: 'Bonus End date',
  GIFT_CARD_MAPPINGS: 'Gift Card Mappings',
  BONUS_CARD_MAPPINGS: 'Bonus Card Mappings',
  ADD_BONUS_CARD_MENU_ITEM: 'Add Bonus Card Menu Item',
  BONUS_CARD_MENU_ITEM: 'Bonus card Menu Item',
  BONUS_AMOUNT: 'Bonus Amount',
  POS_AGENT_DOWNLOAD: 'Pos Agent Download',

  // ezCater
  EZCATER: 'ezCater',
  EZCATER_SETUP: 'ezCater Setup',
  EZCATER_LOCATION_ID: 'ezCater Location Id',
  EZCATER_LOCATIONS_LIST: 'ezCater Locations List',
  LOCATION_ID: 'Location ID',
  SELECT_LOCATION_TO_PAIR: 'Select location to Pair',

  // POS Menu Load
  MENU_LOAD: 'Menu Load',
  POS_MENU_LOAD: 'POS Menu Load',
  POS_MENU_LOAD_SETUP: 'POS Menu Load Setup',
  POSITOUCH: 'POSitouch',
  ALOHA: 'Aloha',
  USE_CELL_PRICE: 'Use Cell Price',
  SIMPHONY: 'Simphony',
  PRICE_LEVELS_COMMA: 'Price Levels (comma separated)',
  MOD_PRICING_BY_GROUP: 'Mod Pricing By Group',
  PCIDS_COMMA_SEPERATED: 'PCID\'s (comma separated)',
  QUICK_SERVICE: 'Quick Service',
  QUICK_SERVICE_PANELS: 'Quick Service Panels (comma separated)',
  QUICK_SERVICE_EXCLUDE_PANELS: 'Quick Service Exclude Panels (comma separated)',
  EXCLUDED_PANELS: 'Excluded Panels',
  PANELS: 'Panels',
  PCIDS: 'PCID\'s',
  PRICE_LEVELS: 'Price Levels',

  // rokt
  ROKT: 'ROKT',

  // Manager App Member Search
  SEARCH_GUEST: 'Search Guest',
  ADD_NEW_GUEST: 'Add New Guest',
  SELECT_GUEST: 'Select Guest',
  THIS_WILL_ADD_THE_GUEST: 'This will add the guest. Do you want to continue?',

  // Manager App - Check List screen
  SELECT_A_CHECK: 'Please select a check',

  // Manager App - Guest Recovery
  GUEST_RECOVERY: 'Guest Recovery',
  SELECT_CHECK: 'Select Check',
  CHANGE_CHECK: 'Change Check',
  CHANGE_GUEST: 'Change Guest',
  SELECT_OFFER: 'Select Offer',

  // Reports
  OMNIVORE_WEBHOOK_REPORT: 'Omnivore Webhook Report',
  MARKETPLACE_WEBHOOK_REPORT: 'Marketplace Webhook Report',
  PRE_AUTH_REPORT: 'Pre Auth Report',
  ITEM_SALES_REPORT: 'Item Sales Report',
  ORDER_DISTRIBUTION_REPORT: 'Order Distribution Report',
  ORDER_ITEMIZED_REPORT: 'Order Itemized Report',
  MARKETPLACE_ORDER_REPORT: 'Marketplace Orders Report',
  FUTURE_ORDERS_REPORT: 'Future Orders Report',
  DEVICE_REPORT: 'Device Report',
  // Receipt Template
  RECEIPT_TEMPLATE: 'Receipt Template',
  ENTER_TEXT: 'Enter text',
  ADD_LINE: 'Add Line',
  TEXT: 'Text',
  FOOTER: 'Footer',
  // Order Label Template
  ORDER_LABEL_TEMPLATE: 'Order Label Template',

  // D&B Loyalty
  DB_LOYALTY: 'D&B Loyalty',
  MULESOFT_CLIENT_ID: 'MuleSoft Client ID',
  MULESOFT_CLIENT_SECRET: 'MuleSoft Client Secret',
  SPARKFLY_IDENTITY: 'Sparkfly Identity',
  SPARKFLY_ACCESS_KEY: 'Sparkfly Access Key',

  // Company Roles
  ROLES: 'Roles',
  ADD_ROLE: 'Add Role',
  UPDATE_ROLE: 'Update Role',
  PERMISSIONS: 'Permissions',
  ROLE_PERMISSIONS: 'Role Permissions',
  SELECT_ROLE_TEMPLATE: 'Select a Role Template',

  // Role Templates
  EDIT_ROLE_TEMPLATE: 'Edit Role Template',
  ADD_ROLE_TEMPLATE: 'Add Role Template',
  ROLE_PERMISSION: 'Role Permission',

  // Permissions Templates
  PERMISSIONS_TEMPLATES: 'Permissions Templates',

  // Admin users
  ADMIN_USERS: 'Admin Users',
  ADD_ADMIN_USERS: 'Add Admin Users',
  UPDATE_ADMIN_USER: 'Update Admin User',
  ADMIN_USER_DELETE_CONFIRMATION: 'This will deactivate the user and cannot be undone. Do you want to continue?',
};
