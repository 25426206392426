
export default {
  // The URL we're connecting to
  hostname: process.env.REACT_APP_API_SERVER_HOST || 'https://lapi.dev.onedine.com:1443',
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    users: 'users',
    deleteUser: '/users',
    getUser: '/users',
    sendotp: '/api/accounts/password/forgot',
    verifyotp: '/api/accounts/password/reset',
    register: '/api/account/register',
    login: '/api/token/portal',
    loginWithApiKey: '/api/token/apiKey',
    changePassword: '/api/accounts/password/change',
    updateBasicDeails: '/api/accounts/profile/update',
    getBrandsByCompany: '/api/dashboard/brands/list/companyId',
    getTimeZones: '/api/lookups/timeZones',
    getStoresByBrand: '/api/dashboard/stores/list/brandId',
    addCompany: '/api/companies/add',
    updateCompany: '/api/companies/update',
    getCompanyById: '/api/companies/companyId',
    addBrand: '/api/brands/add',
    updateBrand: '/api/brands/update',
    getBrandById: '/api/brands/brandId',
    getCompaniesList: '/api/lookups/companies',
    getDevicesByStoreId: '/api/devices/storeId',
    addStore: '/api/stores/add',
    updateStore: '/api/stores/update',
    getStoreById: '/api/stores/storeId',
    getPrintersByStoreId: '/api/devices/printers/storeId',
    getCompaniesListData: '/api/dashboard/companies/list',
    getDeviceType: '/api/lookups/deviceTypes',
    registerDevice: '/api/devices/register',
    getBatchExecutions: '/api/admin/batchExecution/storeId',
    getBrandBatchExecutions: '/api/admin/batchExecution/brandId',
    getServerByStoreId: '/api/stores/serverAppFeatures/storeId',
    updateServerByStoreId: '/api/stores/serverAppFeatures/update/storeId',
    getDeviceById: '/api/devices/deviceId',
    updateDevice: '/api/devices/update',
    getGuestAppByStoreId: '/api/stores/guestAppFeatures/storeId',
    updateGuestAppByStoreId: '/api/stores/guestAppFeatures/update/storeId',
    // getUserRoles: '/api/lookups/roles',
    getUserByCompanyId: '/api/portalUsers/companyId',
    batchExecutionActivation: '/api/admin/batchExecution/activation',
    batchExecutionRun: '/api/admin/batchExecution/run',
    batchCycleService: '/api/admin/batchExecution/cycleService',
    setupBatchException: '/api/admin/batchExecution/setup/storeId',
    getPaymentSystemsGuestApp: '/api/lookups/paymentSystems/guestApp',
    getPaymentSystemsServerApp: '/api/lookups/paymentSystems/serverApp',
    getPaymentSystemConfig: '/api/stores/paymentSystemConfig/storeId',
    getBrandsByCompanyId: '/api/lookups/brands/companyId',
    getStoresByBrandId: '/api/lookups/stores/brandId',
    getCompanies: '/api/lookupS/companies',
    getPaymentSystems: '/api/lookups/paymentSystems',
    postBatchReport: '/api/reports/batchReport',
    postBatchReportAdmin: '/api/reports/batchReportAdmin',
    sensorReport: '/api/reports/sensorReport',
    // postBatchReportNew: '/api/reports/batchReportHeader',
    // postBatchSummaryReport: '/api/reports/batchReportSummary',
    postBatchReportNew: '/api/reports/batchReportHeader/compressed',
    postBatchSummaryReport: '/api/reports/batchReportSummary/compressed',
    postPreAuth: '/api/payments/preAuths',
    updateServerAppFeatures: '/api/stores/serverAppFeatures/update/storeId',
    addUsers: '/api/portalUsers/add',
    updateUsers: '/api/portalUsers/update',
    getUserById: '/api/portalUsers/userId',
    deactivateUser: '/api/portalUsers/deactivate/userId',
    unlockUser: '/api/portalUsers/unlock/userId',
    getMapByStoreId: '/api/stores/posMapping/storeId',
    updateMappingByStoreId: '/api/stores/posMapping/update/storeId',
    getStoreTree: '/api/dashboard/company/companyId',
    getStationByStoreId: '/api/stations/storeId',
    getTicketReasons: '/api/lookups/ticketReasons',
    getBusinessDate: '/api/lookups/businessDate/storeId',
    updateStorePaymentSystemConfig: '/api/stores/paymentSystemConfig/update/storeId',
    updateStationSeats: '/api/stations/seats',
    getStationTypes: '/api/lookups/stationTypes',
    updateStationPrinter: '/api/stations/printer',
    updateStationAvailability: '/api/stations/availability',
    getMenuItems: '/api/lookups/menuItems/storeId',
    getModifierItems: '/api/lookups/modifiers/storeId',
    processRefund: '/api/payments/refund',
    processRepost: '/api/payments/rePost',
    getBrandTips: '/api/brands/tips/brandId/',
    updateBrandTips: '/api/brands/tips/update/brandId',
    getOfferSystem: '/api/lookups/offerSystems',
    getOfferSytemConfig: '/api/brands/thirdPartyConfig/brandId',
    updatethirdPartyConfig: '/api/brands/thirdPartyConfig/update/brandId',
    getStoreBookingSystemConfig: '/api/stores/bookingSystemConfig/storeId',
    getBookingSystem: '/api/lookups/bookingSystems',
    updateStoreBookingSystemConfig: '/api/stores/bookingSystemConfig/update/storeId',
    getPosSytems: '/api/lookups/posSystems',
    getPosSubTypes: '/api/lookups/posSubTypes',
    getStorePosSystemConfig: '/api/stores/posSystemConfig/storeId',
    updateStorePosSystemConfig: '/api/stores/posSystemConfig/update/storeId',
    updateStationSection: '/api/stations/section',
    getDashboardStoreById: '/api/dashboard/store/storeId',
    getUserRoleList: '/api/authRoles/companyId',
    getRoleTypes: '/api/portalUsers/authRoleTypes',

    // Brand Surveys
    getSurveyByBrandId: '/api/surveys/brandId',
    getSurveySegments: '/api/lookups/surveySegments',
    addSurvey: '/api/surveys/add',
    updateSurvey: '/api/surveys/update',
    surveyWelcomeImage: '/api/surveys/image/surveyId',
    getSurveyByID: '/api/surveys/surveyId',
    getSurveyQuestionSubjects: '/api/lookups/surveyQuestionSubjects',
    getSurveyQuestionTypes: '/api/lookups/surveyQuestionTypes',
    getSurveyQuestionObjectTypes: '/api/lookups/surveyQuestionTargets',
    getSurveyQuestionWorkflowTypes: '/api/lookups/surveyQuestionWorkflowTypes',
    addQuestion: '/api/surveys/question/add',
    updateQuestion: '/api/surveys/question/update',
    deleteQuestion: '/api/surveys/question/delete/surveyQuestionId',
    getSurveyResponseSentiments: '/api/lookups/surveyResponseSentiments',
    addSurveyResponse: '/api/surveys/response/add',
    updateSurveyResponse: '/api/surveys/response/update',
    deleteSurveyResponse: '/api/surveys/response/delete/surveyResponseId',
    reorderSurveyQuestion: '/api/surveys/surveyQuestion/reorder/surveyId',
    reorderSurveyResponse: '/api/surveys/response/reorder/surveyQuestionId',
    getSurveyQuestionByBrand: '/api/surveys/question/brandId',
    getSurveyQuestionById: '/api/surveys/question/surveyQuestionId',
    updateSurveyQuestion: '/api/surveys/surveyQuestion/update',
    addSurveyQuestion: '/api/surveys/surveyQuestion/add',
    deleteSurveyQuestion: '/api/surveys/surveyQuestion/delete/surveyId',
    addQuickResponses: '/api/surveys/responses/add',
    getPaymentTypes: '/api/lookups/ticketPaymentTypes',

    // Device
    // reRegisterDevice: '/api/devices/reRegister/deviceId',
    reRegisterDevice: '/api/devices/reRegister',
    deRegisterDevice: '/api/devices/deRegister/deviceId',
    sendMessage: '/api/devices/sendMessage',
    getStoreStatus: '/api/lookups/storeStatuses',
    registerBulkDevices: '/api/devices/register/bulk',
    cardReaderProvision: '/api/devices/cardReader/provision',
    cardReaderDeProvision: '/api/devices/cardReader/deProvision',

    // Device Group Old URL's
    getDeviceGroupListByRole: '/api/devices/deviceGroups/brandId',
    // deleteDeviceGroup: '/api/devices/deviceGroups/delete/deviceGroupId',
    // addDeviceGroup: '/api/devices/deviceGroups/add',
    // getDeviceGroupById: 'api/devices/deviceGroups/deviceGroupId',
    // updateDeviceGroup: '/api/devices/deviceGroups/update',

    // Device Group New URL's
    getDeviceGroupList: '/api/deviceGroups/brandId',
    deleteDeviceGroup: '/api/deviceGroups/delete/deviceGroupId',
    addDeviceGroup: '/api/deviceGroups/add',
    getDeviceGroupById: 'api/deviceGroups/deviceGroupId',
    updateDeviceGroup: '/api/deviceGroups/update',
    getStartMode: '/api/lookups/startModes',
    CardReaderTypes: '/api/lookups/cardReaderTypes',
    getDistinctOrderTypes: 'api/lookups/orderTypes/distinct/brandId',
    getDistinctRevenueCenters: 'api/lookups/revenueCenters/distinct/brandId',
    testDeviceCardReader: 'api/devices/cardReader/test',
    getKDSSortTypes: 'api/lookups/kdsSortTypes',

    // Charity
    getStoresCharity: '/api/charities/storeId',
    getCharityById: '/api/charities/charityId',
    addCharity: '/api/charities/add',
    updateCharity: '/api/charities/update',
    getPosMenusByStoreId: '/api/menus/menu/storeId',
    updatePosMenusByStoreId: '/api/menus/menu/store/update',
    enableDisablePosMenu: '/api/menus/menu/enable/menuId',
    getDaysOfWeek: '/api/lookups/daysOfWeek',
    getMenuSchedule: '/api/menus/schedule/menuId',
    updateMenuSchedule: '/api/menus/schedule/update/menuId',
    addMenuSchedule: '/api/menus/schedule/add/menuId',
    deleteMenuSchedule: '/api/menus/schedule/delete/menuScheduleId',
    disableProcessing: '/api/menus/menu/disableProcessing/menuId',

    //  Custom Menus
    getAllMenuItemsByBrandId: '/api/menus/menuItem/brandId', // /10000002
    getAllModGroupsByBrandId: '/api/menus/modifierGroup/brandId', // /10000002/menuItemId/15910
    getAllModifiersByBrandId: '/api/menus/modifier/brandId', // /10000002/modifierGroupId/9626
    getAllModGroupsByBrandIdModifier: '/api/menus/modifierGroup/brandId', // /10000002/modifierId/843
    addMenuItemToScreen: '/api/menus/menuItem/add/menuScreenId', // ${menuScreenId}/menuItemId/${menuItemId}
    removeMenuItemFromScreen: '/api/menus/menuItem/delete/menuScreenId', // /${menuScreenId}/menuItemId/${menuItemId}
    addModGroupToMenuItem: '/api/menus/menuItem/add/menuItemId', // /16499/modifierGroupId/17166
    removeModGroupFromMenuItem: '/api/menus/menuItem/delete/menuItemId', // /16499/modifierGroupId/17166
    addModifierToModGroup: '/api/menus/modifierGroup/add/modifierGroupId', // /17168/modifierId/12212
    removeModifierFromModGroup: '/api/menus/modifierGroup/delete/modifierGroupId', // /17168/modifierId/12212
    addModGroupToModifier: '/api/menus/modifier/add/modifierId', // /12212/modifierGroupId/17168
    removeModGroupFromModifier: '/api/menus/modifier/delete/modifierId', // /12212/modifierGroupId/17167
    getMenuStores: '/api/menus/menu/stores/menuId',
    addMenuStore: '/api/menus/menu/store/add/menuId', // 1335/storeId/10000146
    deleteMenuStore: '/api/menus/menu/store/delete/menuId', // /1317/storeId/10000146
    addScreenLocation: '/api/menus/screen/store/add/menuScreenId',
    getScreenLocation: '/api/menus/screen/stores/menuScreenId',
    deleteScreenLocation: '/api/menus/screen/store/delete/menuScreenId',
    getBrandModifiers: '/api/lookups/modifiers/brandId',
    menuSortModes: '/api/lookups/menuSortModes',
    getTipsConfigList: '/api/brandtips/brandId',
    getAdvanceOrderTimeUnit: '/api/lookups/advanceOrderTimeUnits',

    getCustomMenusByBrandId: '/api/menus/menu/brandId',
    getBrowserMenuListByBrandId: '/api/menus/menu/browse/brandId',
    getScreenByMenuId: '/api/menus/screen/menuId',
    getMenuItemsByScreenId: '/api/menus/menuItem/menuScreenId',
    getModGroupsBymenuItemId: '/api/menus/modifierGroup/menuItemId',
    getModifiersByModGroupId: '/api/menus/modifier/modifierGroupId',
    getModGroupsByModifierId: '/api/menus/modifierGroup/modifierId',
    getModifiersByMenuItem: '/api/menus/modifier/menuItemId',
    addMenuItem: '/api/menus/menuItem/add',
    getManifest: '/api/accounts/wallet/cardManifest',
    getStoreOffPremiseSetup: '/api/stores/offPremise/list/brandId',
    generateOffPremise: '/api/stores/offPremise/setup/brandId',
    smsApiKey: '/api/portalUsers/smsApiKey/userId',
    revokeApiKey: '/api/portalUsers/revokeApiKey/userId',
    getOrderDisplay: '/api/orders/display/referenceCode',
    testPaymentSystem: '/api/stores/paymentSystemConfig/test/storeId',
    cardManifestByStore: '/api/accounts/wallet/cardManifest/storeId',
    brandImage: '/api/brands/image/brandId',
    brandFont: '/api/brands/font/brandId',
    brandHeaderFont: '/api/brands/font/header/brandId',

    // Tips
    getBrandTipsList: '/api/brandTips/brandId',
    getTipRuleTypes: '/api/lookups/tipRuleTypes',
    addTips: '/api/brandTips/add',
    updateTips: '/api/brandTips/update',
    deleteTipItem: '/api/brandTips/delete/brandTipId',
    getTipsByTipId: '/api/brandTips/brandTipId',
    addTipsRules: '/api/brandtips/rule/add',
    updateTipRules: '/api/brandtips/rule/update',
    deleteTipRuleItem: '/api/brandtips/rule/delete/brandTipRuleId',

    // BrandCharity
    getCharitiesList: '/api/brandcharities/brandId',

    // Menu
    addMenu: '/api/menus/menu/add',
    updateMenu: '/api/menus/menu/update',
    deleteMenu: '/api/menus/menu/delete/menuId',
    cloneMenu: '/api/menus/menu/clone/menuId',
    getPriceCheckStatusMenu: '/api/menus/priceCheckTest/status',
    getPriceCheckScheduleMenu: '/api/menus/priceCheckTest/schedule',

    // Charity
    getBrandCharityList: '/api/brandcharities/brandId',
    addCharityImage: '/api/brandcharities/image/brandCharityId',
    deleteBrandCharity: '/api/brandcharities/delete/brandCharityId',
    addBrandCharity: '/api/brandcharities/add',
    getBrandCharityById: '/api/brandcharities/brandCharityId',
    updateBrandCharity: '/api/brandcharities/update',

    // Menu_Screen
    addMenuScreen: '/api/menus/screen/add',
    updateMenuScreen: '/api/menus/screen/update',
    deleteMenuScreen: '/api/menus/screen/delete/menuScreenId',
    addScreenImage: '/api/menus/menuScreen/menuScreenId',
    cloneScreen: '/api/menus/screen/clone/menuScreenId',

    // Menu_Item
    updateMenuItem: '/api/menus/menuItem/update',
    updateBrandMenuItem: '/api/menus/brandMenuItem/update',
    addMenuItemImage: '/api/menus/menuItem/menuItemId',
    getServiceModeLookUps: '/api/lookups/serviceModes',
    getdeliveryVehicleTypes: '/api/lookups/deliveryVehicleTypes',
    getMenuItemCardTypes: 'api/lookups/menuItemCardTypes',


    // Reports
    transactionReport: '/api/reports/transactionReport',
    postTransactionReportNew: '/api/reports/transactionReportHeader',
    reconciliationReport: '/api/reports/reconciliationReport',
    surveyQuestionReport: '/api/reports/execute',
    surveyReportTicket: '/api/reports/surveyReport/ticket',
    itemSalesReport: '/api/reports/itemSalesReport',
    // paymentAdjustmentReport: '/api/reports/transactionReport',
    paymentAdjustmentReport: '/api/payments/transactions',
    getAdminDataRequest: '/api/admin/dataRequest/list',
    updateAdminDataRequest: '/api/admin/dataRequest/update',
    getDataRequestResolutions: '/api/lookups/dataRequestResolutions',
    getDataRequestGuestConfirm: '/api/admin/dataRequest/requestData/update',
    recordingChargeback: '/api/payments/chargeBack',
    getChargebackReasons: '/api/lookups/chargeBackTypes',
    blockingUsers: '/api/payments/blockUser',
    getDataRequestReport: '/api/admin/dataRequest/guestData/dataRequestId',
    deleteDateRequestData: '/api/admin/dataRequest/deleteData/update',
    managerPaymentAdjustmentReport: '/api/employeeApp/payment/transactions',
    managerRecordingChargeback: '/api/employeeApp/payment/chargeBack',
    managerBlockingUsers: '/api/employeeApp/payment/blockUser',


    // ModGroups
    getModFunctionByBrandId: '/api/menus/modFunctions/brandId',
    // addMenuItemModifierGroup: '/api/menus/modifierGroup/menuItem/add',
    addMenuItemModifierGroup: '/api/menus/modifierGroup/add',
    updateMenuItemModifierGroup: '/api/menus/modifierGroup/menuItem/update',
    updateBrandModifierGroup: '/api/menus/modifierGroup/update',
    updateModifierModifierGroup: '/api/menus/modifierGroup/modifier/update',
    cloneModGroup: '/api/menus/modifierGroup/clone/menuItemId',

    // Modifiers
    updateModifier: '/api/menus/modifier/update',
    addModifer: '/api/menus/modifier/add',
    modifierIncludeds: '/api/lookups/modifierIncludeds',
    cloneModifier: '/api/menus/modifierGroup/clone/modifierId',

    // Reorder
    screenReorder: '/api/menus/screen/reorder/menuId',
    menuReorder: '/api/menus/menuItem/reorder/menuScreenId',
    modifierReorder: '/api/menus/modifierGroup/reorder/menuItemId',
    modiferGroupModiferReorder: '/api/menus/modifier/reorder/modifierGroupId',
    reorderModifierGroupModifier: '/api/menus/modifierGroup/reorder/modifierId',
    systemStatus: '/api/admin/systemStatus',
    reOrderMenu: '/api/menus/menu/reorder',
    modifierGroupLinkReorder: '/api/menus/modifierGroup/links/reorder/modifierGroupId',
    menuItemLinkReorder: '/api/menus/menuItem/links/reorder/menuItemId',
    sortedTiles: '/api/brandTiles/reorder',
    saveCarouselImages: '/api/brandTiles/carousel/image/brandTileId',
    deleteCarouselImage: '/api/brandTiles/carousel/image/brandTileId',
    getOrderMenusList: '/api/lookups/menu/order/brandId',
    getBrowseMenusList: '/api/lookups/menu/browse/brandId',

    storeImage: '/api/stores/image/storeId',
    getStoreHours: '/api/storeHours/storeId',
    getBrandHolidays: '/api/brandHolidays/brandId',
    addBrandHoliday: '/api/brandholidays/add',
    updateBrandHoliday: '/api/brandholidays/update',
    deleteBrandHoliday: '/api/brandholidays/delete/brandHolidayId',
    updateStoreHours: '/api/storeHours/update',
    // taxesLookUp: '/api/lookups/taxes',
    getStoreTaxes: '/api/brands/taxes/brandId',
    // updateStoreTaxes: '/api/brands/taxes/update',
    badgesLookUp: '/api/lookups/menuBadges',
    menuItemLinkTypesLookUp: '/api/lookups/menuItemLinkTypes',
    tagCategories: '/api/lookups/tagCategories',

    // taxs
    taxesLookUp: 'api/brandTaxes/brandId',
    addBrandTax: 'api/brandTaxes/add',
    getBrandTaxById: 'api/brandTaxes/brandTaxId',
    updateBrandTax: '/api/brandTaxes/update',

    // prepTime
    getPrepTime: '/api/brands/prepTime/brandId',
    addPrepTime: '/api/brands/prepTime/add',
    updatePrepTime: '/api/brands/prepTime/update',
    deletePrepTime: '/api/brands/prepTime/delete/brandPrepTimeId',

    // countries
    getCountryList: '/api/lookups/countries',

    // Charity Images
    charityImage: '/api/charities/image/charityId',

    // customMenu EditImage
    storeMenuImage: '/api/menus/menu/menuId',

    // station
    getStationGroupsByStoreId: '/api/stations/groups/storeId',
    updateStationGroups: '/api/stations/group',

    // Jobs
    getJobsByStoreId: '/api/jobs/storeId',
    getJobById: '/api/jobs/jobId',
    updateJob: '/api/jobs/update',
    getMenuJobs: '/api/menus/jobType/menuId',
    enableMenuJob: '/api/menus/jobType/enable/menuId',
    getJobTypes: '/api/lookups/jobTypes',
    getJobs: '/api/lookups/jobs/storeId',
    addJobs: '/api/jobs/add',
    checkVisibility: '/api/lookups/checkVisibilities',

    // stationGroups
    getTableGroupsByStore: '/api/stations/groups/storeId',
    addTableGroup: '/api/stations/groups/add',
    updateTableGroup: '/api/stations/groups/update',
    deleteTableGroup: '/api/stations/groups/delete/groupId',
    getTableGroups: '/api/tms/stationGroup/storeId',

    // receiptData
    getReceipt: '/api/receipts/referenceCode',
    checkReceipt: '/api/receipts/check/referenceCode',
    giftCardReceipt: '/api/receipts/giftCard/referenceCode',

    // scanner
    getStationsByStoreId: '/api/stations/storeId',
    registerSensor: '/api/sensors/register',
    getPrinterTypes: '/api/lookups/printerTypes',
    // Sensor Group
    getSensorGroupByRoleId: '/api/sensors/sensorGroups/brandId',
    getSensorGroupByBrandId: '/api/sensorGroups/brandId',
    assignSensorGroup: '/api/sensors/sensorId',
    addSensorGroup: '/api/sensorGroups/add',
    updateSensorGroup: '/api/sensorGroups/update',
    deleteSensorGroup: '/api/sensorGroups/delete/sensorGroupId',
    getSensorGroupById: '/api/sensorGroups/sensorGroupId',

    // Store setup options
    getStoreCategories: '/api/categories/storeId',
    getStoreDiscounts: '/api/discounts/storeId',
    getStoreOrderTypes: '/api/orderTypes/storeId',
    getStoreRevenueCenters: '/api/revenueCenters/storeId',
    getStoreTenderTypes: '/api/tenderTypes/storeId',
    getRevenueCentersByStoreId: '/api/lookups/revenueCenters/storeId',
    getStoreDrawers: '/api/storeDrawers/storeId',
    getDiscountTypes: '/api/lookups/discountTypes',
    getStoreSections: '/api/storeSections/storeId',
    getJobTeams: '/api/jobs/team/storeId',
    getStoreServiceCharges: '/api/serviceCharges/storeId',


    addStoreCategories: '/api/categories/add',
    addStoreDiscounts: '/api/discounts/add',
    addStoreOrderTypes: '/api/orderTypes/add',
    addStoreRevenueCenters: '/api/revenueCenters/add',
    addStoreTenderTypes: '/api/tenderTypes/add',
    addStoreStations: '/api/stations/add',
    addStoreDrawers: '/api/storedrawers/add',
    addStoreSections: '/api/storeSections/add',
    addJobTeam: '/api/jobs/team/add',
    addStoreServiceCharge: '/api/serviceCharges/add',

    updateStoreCategories: '/api/categories/update',
    updateStoreDiscounts: '/api/discounts/update',
    updateStoreOrderTypes: '/api/orderTypes/update',
    updateStoreRevenueCenters: '/api/revenueCenters/update',
    updateStoreTenderTypes: '/api/tenderTypes/update',
    updateStoreStations: '/api/stations/update',
    updateStoreDrawers: '/api/storedrawers/update',
    updateStoreSections: '/api/storeSections/update',
    updateJobTeam: '/api/jobs/team/update',
    updateStoreServiceCharge: '/api/serviceCharges/update',

    quickAddTables: '/api/stations/quickAdd',

    // loyalty screen
    getLoyaltyMethods: '/api/lookups/loyaltyMethods',
    getGiftCardMethods: '/api/lookups/giftCardMethods',

    // reports
    getChecksByStore: '/api/checks/storeId',
    wareHouseReports: '/api/reports/execute',
    menuItemAvailability: '/api/reports/availabilityReport/menuItem',
    modifierItemAvailability: '/api/reports/availabilityReport/modifier',

    // check details
    getCheckById: '/api/checks/checkHeaderId',

    // brand channel config
    getChannelConfig: '/api/brands/channelConfig/brandId',
    updateChannelConfig: '/api/brands/channelConfig/update',
    getStoresByCompanyId: '/api/lookups/stores/companyId',

    // store manager
    getFireboardNumber: '/api/stores/fireboardNumber/storeId',
    clearFireBoardNumber: '/api/stores/fireboardNumber/clear/storeId',
    textToPay: '/api/orders/textToPay',
    updateFireboardNumber: '/api/stores/fireboardNumber/update/storeId',
    generateChannelSetup: '/api/brands/channelSetup/generate/brandId',
    getChannelSetup: '/api/brands/channelSetup/brandId',

    // menus
    getModifiersByBrandId: '/api/menus/modifier/brandId',
    getModifierGroupByBrandId: '/api/menus/modifierGroup/brandId',

    // Enterprise setup
    getEnterpriseMenuItemsByMenuItemId: '/api/menus/enterprise/menuItem/menuItemId',
    updateEnterpriseMenuItemsByMenuItemId: '/api/menus/enterprise/menuItem/update',
    getEnterpriseModifiersByModifierId: '/api/menus/enterprise/modifier/modifierId',
    updateEnterpriseModifiersByModifierId: '/api/menus/enterprise/modifier/update',

    // Merge
    getMergeMenuItemsByMenuItemId: '/api/menus/merge/menuItem/menuItemId',
    updateMergeMenuItemsByMenuItemId: '/api/menus/merge/menuItem/update',
    getMergeModifiersByModifierId: '/api/menus/merge/modifier/modifierId',
    updateMergeModifiersByModifierId: '/api/menus/merge/modifier/update',
    // mall
    addMall: '/api/venues/add',
    updateMall: '/api/venues/update',
    getMallById: '/api/venues/venueId',
    getMallsListData: '/api/venues/list/companyId',
    deleteMallStore: '/api/venues/store/delete/venueId',
    addMallStore: '/api/venues/store/add/venueId',
    mallLogoImage: '/api/venues/image/venueId',
    getVenuesById: '/api/venues/stores/venueId',

    // Storetiles
    getBrandTiles: '/api/brandTiles/brandId',
    addUpdateBrandTile: '/api/brandTiles', // /add or /update
    deleteBrandTile: '/api/brandTiles/delete/brandTileId',
    getBrandTileById: '/api/brandTiles/brandTileId',
    // DownloAD App link
    getAppDownloadUrl: '/api/admin/app/download',
    getMDMDownloadUrl: '/api/admin/MDM/download',
    wearApp: '/api/admin/wear/download',
    simulated: '/api/admin/app/download/simulated',
    nextGen: '/api/admin/app/download/nextgen',
    nextGenSimulated: '/api/admin/app/download/nextgen/simulated',
    getMauiNextGen: '/api/admin/app/download/maui',
    getMauiNextGenSimulated: '/api/admin/app/download/maui/simulated',

    // Mobile Payment Setup
    getStorePaymentSystemConfigMobile: '/api/stores/paymentSystemConfig/mobile/storeId',
    updateStorePaymentSystemConfigMobile: '/api/stores/paymentSystemConfig/mobile/update/storeId',
    getStorePaymentSystemConfigMobileByVendor: '/api/stores/paymentSystemConfig/mobile/storeId',

    // Ingenico
    getStorePaymentSystemConfigIngenico: '/api/stores/paymentSystemConfig/tablet/storeId',
    updateStorePaymentSystemConfigIngenico: '/api/stores/paymentSystemConfig/tablet/update/storeId',
    testStorePaymentSystemConfig: '/api/stores/paymentSystemConfig/tablet/test/storeId',

    // paypal setup
    getStorePaymentSystemConfigPaypal: '/api/stores/paymentSystemConfig/paypal/storeId',
    updateStorePaymentSystemConfigPaypal: '/api/stores/paymentSystemConfig/paypal/update/storeId',
    onBoardingUrlPayPal: '/api/stores/paymentSystemOnBoarding/paypal/setup/storeId',

    // stripe setup
    getStorePaymentSystemConfigStripe: '/api/stores/paymentSystemConfig/stripe/storeId',
    updateStorePaymentSystemConfigStripe: '/api/stores/paymentSystemConfig/stripe/update/storeId',

    // Spreedly for Toast
    getStorePaymentSystemConfigSpreedly: '/api/stores/paymentSystemConfig/spreedlyToast/storeId',
    upateStorePaymentSystemConfigSpreedly: '/api/stores/paymentSystemConfig/spreedlyToast/update/storeId',

    // loyalty
    getPnp: '/api/brands/thirdPartyConfig/oms/brandId',
    getPaytronix: '/api/brands/thirdPartyConfig/paytronixLoyalty/brandId',
    updatePaytronix: '/api/brands/thirdPartyConfig/paytronixLoyalty/update/brandId',
    getPaytronixGiftCard: '/api/brands/thirdPartyConfig/paytronixGiftCard/brandId',
    updatePaytronixGiftCard: '/api/brands/thirdPartyConfig/paytronixGiftCard/update/brandId',
    updatePnp: '/api/brands/thirdPartyConfig/oms/update/brandId',
    getClutchLoyalty: '/api/brands/thirdPartyConfig/clutch/brandId',
    updateClutchLoyalty: '/api/brands/thirdPartyConfig/clutch/update/brandId',

    // screen schedule
    getMenuScreenScheduleByMenuId: '/api/menus/screen/schedule/menuScreenId',
    addMenuScreenSchedule: '/api/menus/screen/schedule/add/menuScreenId',
    updateMenuScreenSchedule: '/api/menus/screen/schedule/update/menuScreenId',

    // Employee
    getEmployeesByStoreId: '/api/employees/storeId',
    getEmployeeById: '/api/employees/employeeId',
    // updateEmployee: '/api/employees/updateCustomFields',
    updateEmployee: '/api/employees/update',
    addEmployee: '/api/employees/add',
    unlinkEmployee: '/api/employees/member/unlink/employeeId',
    getLinkMembers: '/api/employees/member/link/storeId',
    updateEmployeeLink: '/api/employees/member/link/employeeId',
    getManagerEmployeesByStoreId: '/api/employeeApp/employee/storeId',
    getManagerLinkMembers: '/api/employeeApp/employee/member/link/storeId',
    addManagerEmployee: '/api/employeeApp/employee/add',


    // POSlookup
    getPosIntegrations: '/api/lookups/posIntegrations',

    // NamedSensors
    getSensorsByStoreIdType: '/api/sensors/storeId',
    deRegisterSensor: '/api/sensors/deRegister/externalId',

    getBrandTheme: '/api/brands/theme/mobileApp/brandId',
    saveBrandTheme: '/api/brands/theme/mobileApp/update',
    getBrandCustomize: '/api/brands/customize/mobileApp/brandId',
    saveBrandAppCustomize: '/api/brands/appCustomization/mobileApp/update',

    // Mweb customization theme language and labels

    getBrandCustomizationsLanguage: '/api/brandCustomizations/language/mWeb/brandId',
    saveBrandCustomizationsLanguage: '/api/brandCustomizations/language/mWeb/setDefault/brandId',
    saveBrandCustomizationsReorder: '/api/brandCustomizations/language/mWeb/reorder/brandId',
    deleteBrandCustomizations: '/api/brandCustomizations/language/mWeb/delete/brandId',
    updateBrandCustomizationsLanguage: '/api/brandCustomizations/language/mWeb/update',

    getBrandCustomizationsLabels: '/api/brandCustomizations/labels/mWeb/brandId',
    updateBrandCustomizationsLabels: '/api/brandCustomizations/labels/mWeb/update',

    // Mweb customization theme
    getBrandCustomizationsTheme: '/api/brandCustomizations/theme/mWeb/brandId',
    saveBrandCustomizationsTheme: '/api/brandCustomizations/theme/mWeb/update',

    // Brand Other Settings
    getOtherSettings: '/api/brands/otherSettings/brandId',
    updateOtherSettings: '/api/brands/otherSettings/update',

    // server app theme
    getBrandServerAppCustomize: '/api/brands/customize/serverApp/brandId',
    saveServerAppCustomize: '/api/brands/appCustomization/serverApp/update',
    getBrandServerAppTheme: '/api/brands/theme/serverApp/brandId',
    saveBrandServerAppTheme: '/api/brands/theme/serverApp/update',

    // Store Other Settings
    getStoreOtherSettings: '/api/stores/otherSettings/storeId',
    updateStoreOtherSettings: '/api/stores/otherSettings/update',
    posAgentDownload: '/api/stores/posAgentDownload/storeId',

    // Seat Order Approval
    getSeatOrderApproval: '/api/orders/seat/approvalRequest',
    updateSeatOrderApproval: '/api/orders/seat/approvalResponse',

    getTileTypes: '/api/lookups/tileTypes',
    getAppLinkTypes: '/api/lookups/appLinkTypes',
    getTileTargets: '/api/lookups/tileTargets',

    mdmPolicy: '/api/mdm',
    tmsSetup: '/api/tms',
    tileImageSave: '/api/brandTiles/image/brandTileId',
    tileImageDelete: '/api/brandTiles/image/brandTileId',

    // TMS Store tags
    getStoreTag: '/api/tms/storeTag/storeId',
    addTMSStoreTag: '/api/tms/storeTag/add',
    updateTMSStoreTag: '/api/tms/storeTag/update',

    // TMS station tag
    getTmsStationsByStoreId: '/api/tms/stations/storeId',
    addStationTag: '/api/tms/stationTag/add/stationId',
    deleteStationtag: '/api/tms/stationTag/delete/stationId',

    getTmsStoreSetup: '/api/tms/store/setup/storeId/',
    updateTmsStoreSetup: '/api/tms/store/setup',
    getStationGroup: '/api/tms/stationGroup/storeId',
    getStations: '/api/tms/stations/storeId',
    updateStations: '/api/tms/stations/update',

    getTmsStationGroupByStoreId: '/api/tms/stationGroup/storeId',
    addStationgroup: '/api/tms/stationGroup/add',
    updateStationGroup: '/api/tms/stationGroup/update',
    deleteStationgroup: '/api/stations/groups/delete/groupId',
    getStationShapes: '/api/lookups/stationShapes',

    // Occasions setup
    getOccasionsSetup: '/api/tms/storeOccasion/storeId',
    addOccasion: '/api/tms/storeOccasion/add',
    updateOccasion: '/api/tms/storeOccasion/update',

    // heartland setup
    getHeartlandConfig: '/api/brands/thirdPartyConfig/heartland/brandId',
    postHeartlandConfig: '/api/brands/thirdPartyConfig/heartland/update/brandId',
    getSparkFlySetup: '/api/brands/thirdPartyConfig/sparkfly/brandId',
    updateSparkFlySetup: '/api/brands/thirdPartyConfig/sparkfly/update/brandId',
    keyboardStyles: '/api/lookups/keyboardStyles',
    uploadTilePdf: '/api/brandTiles/pdf/brandTileId',
    deleteTilePdf: '/api/brandTiles/pdf/brandTileId',

    // spendgo
    getSpendgo: '/api/brands/offerSystemConfig/spendgo/brandId',
    updateSpendgo: '/api/brands/offerSystemConfig/spendgo/update/brandId',

    // Release Notes

    getReleaseNote: '/api/dashboard/releaseNote/list',
    getReleaseNoteProducts: '/api/lookups/releaseNoteProducts',
    getReleaseNoteTypes: '/api/lookups/releaseNoteTypes',
    getReleaseNoteStatuses: '/api/lookups/releaseNoteStatuses',
    uploadReleaseNotePdf: '/api/admin/releaseNote/pdf/upload/releaseNoteId',
    deleteReleaseNotePdf: '/api/admin/releaseNote/pdf/delete/releaseNoteId',

    addReleaseNote: '/api/admin/releaseNote/add',
    updateReleaseNote: '/api/admin/releaseNote/update',
    addReleaseNoteItem: '/api/admin/releaseNoteItem/add',
    updateReleaseNoteItem: '/api/admin/releaseNoteItem/update',

    deleteReleaseNote: '/api/admin/releaseNote/delete/releaseNoteId',
    deleteReleaseNoteItem: '/api/admin/releaseNoteItem/delete/releaseNoteItemId',

    // look loyalty
    getLookLoyalty: '/api/brands/thirdPartyConfig/lookLoyalty/brandId',
    updateLookLoyalty: '/api/brands/thirdPartyConfig/lookLoyalty/update/brandId',

    //  svs setup
    getSvsConfig: '/api/brands/thirdPartyConfig/svs/brandId',
    updateSvsConfig: '/api/brands/thirdPartyConfig/svs/update/brandId',

    // punchh setup
    getPunchhConfig: '/api/brands/thirdPartyConfig/punchh/brandId',
    updatePunchhConfig: '/api/brands/thirdPartyConfig/punchh/update/brandId',

    // spendgo setup
    getSpendgoConfig: '/api/brands/thirdPartyConfig/spendgo/brandId',
    updateSpendgoConfig: '/api/brands/thirdPartyConfig/spendgo/update/brandId',

    // fishbowl setup
    getFishbowlConfig: '/api/brands/thirdPartyConfig/fishbowl/brandId',
    updateFishbowlConfig: '/api/brands/thirdPartyConfig/fishbowl/update/brandId',

    // Third party delivery setup
    getDeliveryConfig: '/api/brands/thirdPartyConfig/delivery/brandId',
    getDeliverySystems: '/api/lookups/deliverySystems',
    updateDeliveryConfig: '/api/brands/thirdPartyConfig/delivery/update/brandId',
    getBrandTenderTypes: '/api/lookups/tenderTypes/brandId',
    getBrandOrderTypes: '/api/lookups/orderTypes/brandId',
    getBrandRevenueCenters: '/api/lookups/revenueCenters/brandId',
    getItemOrderTypesList: '/api/lookups/itemOrderTypes/brandId',

    // Brand third party restaurant magic setup
    getRestaurantMagicConfig: '/api/brands/thirdPartyConfig/restaurantMagic/brandId',
    updateRestaurantMagicConfig: '/api/brands/thirdPartyConfig/restaurantMagic/update/brandId',

    // tattle survey setup
    getTattleSurveyListConfig: '/api/brands/thirdPartyConfig/tattle/brandId',
    updateTattleSurveyListConfig: '/api/brands/thirdPartyConfig/tattle/update/brandId',

    // Brand third party Chowly setup
    getChowlyConfig: '/api/brands/thirdPartyConfig/chowly/brandId',
    updateChowlyConfig: '/api/brands/thirdPartyConfig/chowly/update/brandId',

    // Brand third party Otter setup
    getOtterConfig: '/api/brands/thirdPartyConfig/otter/brandId',
    updateOtterConfig: '/api/brands/thirdPartyConfig/otter/update/brandId',

    // memberReleaseNotes
    memberReleaseNotes: '/api/admin/memberReleaseNotes/display',
    // What's new
    releaseNotesViewed: '/api/accounts/releaseNotes/viewed',
    // manageMenuLink
    getMenuItemLinks: '/api/menus/menuItem/links/menuItemId',
    updateMenuItemLinks: '/api/menus/menuItem/links/update',

    // preferences
    getPreferences: '/api/accounts/preferences',
    updatePreferences: '/api/accounts/preferences/update',

    // Menu Sensors
    addMenuSensorGroup: '/api/menus/menu/sensorGroup/add',
    getMenuSensorGroup: '/api/menus/menu/sensorGroups/menuId',
    deleteMenuSensorGroup: '/api/menus/menu/sensorGroup/delete',

    // OfferManagement
    getOffers: '/api/oms/execute',
    // OMS
    omsSetup: '/api/oms/execute',
    getModifierGroupLinks: '/api/menus/modifierGroup/links/modifierGroupId',
    updateModifierGroupLinks: '/api/menus/modifierGroup/links/update',
    getSendHoldTypes: '/api/lookups/sendHoldTypes',

    getBrandOMSOffer: '/api/brands/thirdPartyConfig/oms/brandId',
    updateBrandOMSOffer: '/api/brands/thirdPartyConfig/oms/update/brandId',

    //  checkDetail
    sync: '/api/checks/sync/checkHeaderId',
    close: '/api/checks/update/close/checkHeaderId',

    // Admin Tags
    adminTagsList: '/api/admin/tag/list',
    adminTagTypes: '/api/lookups/tagTypes',
    addAdminTags: '/api/admin/tag/add',
    updateAdminTags: '/api/admin/tag/update',
    tagImageSave: '/api/admin/tag/image/tagId',
    tagImageDelete: '/api/admin/tag/image/tagId',


    // MDM APis
    getMDMBrandPolicy: '/api/mdm/policy/brand/brandId',
    getMDMStorePolicy: '/api/mdm/policy/store/storeId',
    updateMDMBrandPolicy: '/api/mdm/policy/brand/update/brandId',
    updateMDMStorePolicy: '/api/mdm/policy/store/update/storeId',

    // Delivery Screens
    deliveryList: '/api/brandDeliveries/brandId',
    deleteDeliveryItem: '/api/brandDeliveries/delete/brandDeliveryId',
    addDeliveryScreen: '/api/brandDeliveries/add',
    updateDeliveryScreen: '/api/brandDeliveries/update',
    getBrandDeliveryById: '/api/brandDeliveries/brandDeliveryId',
    getDeliveryZoneTypes: '/api/lookups/deliveryZoneTypes',
    addDeliveryRule: '/api/brandDeliveries/tier/add',
    updateDeliveryRule: '/api/brandDeliveries/tier/update',
    deleteDeliveryRule: '/api/brandDeliveries/tier/delete/brandDeliveryTierId',
    addDeliverySlot: '/api/brandDeliveries/slot/add',
    updateDeliverySlot: '/api/brandDeliveries/slot/update',
    deleteDeliverySlot: '/api/brandDeliveries/slot/delete/brandDeliverySlotId',
    getDelivertRuleTypes: '/api/lookups/deliveryRuleTypes',

    // Campaigns
    campaignList: '/api/campaigns/brandId',
    campaignClass: '/api/lookups/campaignClasses',
    campaignMarketingTypes: '/api/lookups/marketingCampaignTypes',
    getmenuItemsbyBrandId: '/api/lookups/menuItems/brandId',
    campaignRewardTypes: '/api/lookups/campaignRewardTypes',
    getRedemptionMethods: '/api/lookups/redemptionMethods',
    campaignSubjects: '/api/lookups/campaignSubjects',

    // getOfferTrigger: '/api/lookups/offerTriggers',
    getOfferTrigger: '/api/lookups/triggerMethods',
    getEventTrigger: '/api/lookups/eventTriggers',
    saveCampaignReward: '/api/campaigns/campaignReward/save',
    deleteCampaignReward: '/api/campaigns/campaignReward/delete/campaignRewardId',
    offerCampaignTypes: '/api/lookups/offerCampaignTypes',
    addCampaign: '/api/campaigns/add',
    addLocation: '/api/campaigns/stores/update',
    deleteCampaignItem: '/api/campaings/delete',
    campaignById: '/api/campaigns/campaignId',
    updateCampaign: '/api/campaigns/update',
    externalCampaign: '/api/brands/externalCampaign/brandId',
    updateExternalCampaignScript: '/api/brands/externalCampaign/update',
    campaignImageSave: '/api/campaigns/image/campaignId',
    campaignImageDelete: '/api/campaigns/image/campaignId',
    getCampaignEmailTemplates: '/api/email/brandEmailTemplate/campaign/brandId',
    getCampaignDiscountMaster: '/api/lookups/discounts/brandId',

    // Campaign Rule
    getCampaignRuleTypes: '/api/lookups/campaignRuleTypes',
    getCategoriesByBrandId: '/api/lookups/categories/brandId',
    getTicketPaymentTypes: '/api/lookups/ticketPaymentTypes',
    getChannels: '/api/lookups/channels',
    getRedemptionLimitTypes: '/api/lookups/redemptionLimitTypes',
    saveCampaignRule: '/api/campaigns/campaignRule/save',
    deleteCampaignRule: '/api/campaigns/campaignRule/delete/campaignRuleId',

    // Tags
    getTags: '/api/lookups/tags',
    getTagTypes: '/api/lookups/tagtypes',

    // batchFilters
    getBatchTypes: '/api/lookups/batchTypes',
    getBatchStatuses: '/api/lookups/batchStatuses',

    // brandTags
    getBrandTags: '/api/brandTags/brandId',
    updateBrandTag: '/api/brandTags/update',
    deleterBrandTag: '/api/brandTags/delete/brandId',
    brandTagImage: '/api/brandTags/image/brandId',

    // SMS Templates
    smsTemplateList: '/api/sms/brandSmsTemplate/brandId',
    deleteSmsTemplate: '/api/sms/brandSmsTemplate/delete/brandSmsTemplateId',
    smsCustomizable: '/api/sms/smsTemplate/customizable',
    addSmsTemplate: '/api/sms/brandSmsTemplate/add',
    smsTemplateStatus: '/api/lookups/templateStatuses',
    smsTemplateById: '/api/sms/brandSmsTemplate/brandSmsTemplateId',
    smsMergeFields: '/api/sms/mergeFields/smsType',
    smsTypes: '/api/lookups/smsTypes',
    sensorList: '/api/lookups/sensorTypes',
    updateSmsTemplate: '/api/sms/brandSmsTemplate/update',

    // Category
    getCategoryList: '/api/menus/category/brandId',
    addCategoryList: '/api/menus/category/add',
    updateCategory: '/api/menus/category/update',
    prepTimeValue: '/api/lookups/prepTimeUnits',

    // System Notifications
    systemNotificationList: '/api/admin/systemNotification/list',
    severities: '/api/lookups/severities',
    systemNotificationTypes: '/api/lookups/systemNotificationTypes',
    systemNotificationListById: '/api/admin/systemNotification/systemNotificationId',
    addSystemNotification: '/api/admin/systemNotification/add',
    updateSystemNotification: '/api/admin/systemNotification/update',
    deleteSystemNotification: '/api/admin/systemNotification/delete/systemNotificationId',
    publishSystemNotification: '/api/admin/systemNotification/publish/systemNotificationId',

    // Segment
    getSegmentList: '/api/segments/brandId',
    getSegementById: '/api/segments/segmentId',
    addSegment: '/api/segments/add',
    updateSegment: '/api/segments/update',
    getSegmentRuleTypes: '/api/lookups/segmentRuleTypes',
    getSegmentDateTypes: '/api/lookups/segmentDateTypes',
    getSegmentPeriodTypes: '/api/lookups/segmentPeriodTypes',
    getSegmentMetricTypes: '/api/lookups/segmentMetricTypes',
    getSegmentMetricValueTypes: '/api/lookups/segmentMetricValueTypes',
    getSegmentMetricOperatorTypes: '/api/lookups/segmentMetricOperatorTypes',
    saveBrandSegmentRule: '/api/segments/segmentRule/save',
    deleteSegementRuleId: '/api/segments/segmentRule/delete/segmentRuleId',
    previewBySegmentId: '/api/segments/members/segmentId',
    segmentOffers: '/api/offers',
    inactivateSegmentOffer: '/api/offers/deactivate',
    reissueSegmentOffer: '/api/offers/reissue',
    issueSegmentOffer: '/api/offers/issue',
    addSegmentGuest: '/api/segments/members/add',
    deleteSegmentGuests: '/api/segments/members/delete',
    importCSVSegmentGuests: '/api/segments/members/import/segmentId',

    // campaign Occurrence
    getCamapignOccurrence: '/api/campaigns/campaignOccurrence/campaignId',
    addCampaignOccurrence: '/api/campaigns/campaignOccurrence/add',
    updateCampaignOccurrence: '/api/campaigns/campaignOccurrence/update',
    getCampaignOccurrenceById: '/api/campaigns/campaignOccurrence/campaignOccurrenceId',
    addCampaignOccurrenceRule: '/api/campaigns/campaignOccurrence/campaignOccurrenceRule/save',
    deleteCampaignOccurrenceRule: '/api/campaigns/campaignOccurrence/campaignOccurrenceRule/delete/campaignOccurrenceRuleId',
    campaignStatus: '/api/lookups/campaignStatuses',
    campaignPublish: '/api/campaigns/campaignOccurrence/campaignOccurrenceId',

    // Camapign Trigger

    getCamapignTrigger: '/api/campaigns/campaignTrigger/brandId',
    getCampaignTriggersTypes: '/api/lookups/campaignTriggers',
    deleteCampaignTrigger: '/api/campaigns/campaignTrigger/delete/campaignTriggerId',
    campaignTriggerOccurence: '/api/campaigns/campaignOccurrence/brandId',
    addCampaignTrigger: '/api/campaigns/campaignTrigger/add',
    updateCampaignTrigger: '/api/campaigns/campaignTrigger/update',
    campaignTriggerById: '/api/campaigns/campaignTrigger/campaignTriggerId',

    // Brand Email Templets
    getBrandEmailTemplateList: '/api/email/brandEmailTemplate/brandId',
    getEmailTypes: '/api/lookups/emailTypes',
    getTemplateStatus: '/api/lookups/templateStatuses',
    deleteEmailTemplate: '/api/email/brandEmailTemplate/delete/brandEmailTemplateId',
    getCustomizableEmail: '/api/email/emailTemplate/customizable',
    getEmailMergeFields: '/api/email/mergeFields/emailType',
    addBrandEmailTemplate: '/api/email/brandEmailTemplate/add',
    getBrandEmailListById: '/api/email/brandEmailTemplate/brandEmailTemplateId',
    updateBrandEmailTemaplate: '/api/email/brandEmailTemplate/update',
    // emailEditor
    getBrandEmailTemplateById: '/api/email/emailEditor/brandEmailTemplate/brandEmailTemplateId',
    getEmailTemplateById: '/api/email/emailTemplate/emailTemplateId',
    getEmailEditorConfiguration: '/api/email/emailEditor/configuration/brandId',
    getCustomizable: '/api/email/emailTemplate/customizable',
    getMergeFieldsByEmailType: '/api/email/mergeFields/emailType',
    uploadImageUrl: '/api/email/emailEditor/uploadImage',
    folderContentsUrl: '/api/email/emailEditor/folderContents',
    uploadImageEditorImageUrl: '/api/email/emailEditor/uploadImageEditorImage',
    addBrandEmailTemplateUrl: '/api/email/brandEmailTemplate/add',
    updateBrandEmailTemplateUrl: '/api/email/emailEditor/brandEmailTemplate/update',
    sendPreviewEmailUrl: '/api/email/brandEmailTemplate/sendPreviewEmail',

    // Brand Receipt Templates
    getBrandReceiptTemplateList: '/api/brands/receiptTemplate/brandId',
    updateBrandReceiptTemaplate: '/api/brands/receiptTemplate/update',

    // Order Label Templates
    getOderLabelTemplateList: '/api/brands/orderLabelTemplate/brandId',
    updateOrderLabelTemaplate: '/api/brands/orderLabelTemplate/update',

    // modifireImage
    addModifierImage: '/api/menus/modifier/modifierId',

    // givex setup
    getGivexConfig: '/api/brands/thirdPartyConfig/givex/brandId',
    postGivexConfig: '/api/brands/thirdPartyConfig/givex/update/brandId',

    // Data Request
    getDataRequest: '/api/admin/dataRequest/list',
    getDataRequestById: '/api/admin/dataRequest/dataRequestId',
    updateDataRequest: '/api/admin/dataRequest/resolution/update',
    dataResolutions: '/api/lookups/dataRequestResolutions',

    // charges
    getChargeList: '/api/brandCharges/brandId',
    deleteChargeById: '/api/brandCharges/delete/brandChargeId',
    getChargesById: '/api/brandCharges/brandChargeId',
    addCharge: '/api/brandCharges/add',
    updateCharge: '/api/brandCharges/update',
    deleteChargeTierById: '/api/brandCharges/tier/delete/brandChargeTierId',
    getChargeType: '/api/lookups/chargeTypes',
    addChargeTier: '/api/brandCharges/tier/add',
    updateChargeTier: '/api/brandCharges/tier/update',

    // printCategory
    printCategoryList: '/api/brandPrintCategories/brandId',
    addPrintCategory: '/api/brandPrintCategories/add',
    updatePrintCategory: '/api/brandPrintCategories/update',
    deletePrintCategory: '/api/brandPrintCategories/delete/brandPrintCategoryId',
    getPrinterDevices: '/api/devices/printers/brandId',
    getKDSExpoStationByBrand: '/api/devices/kdsExpoStations/brandId',
    getKDSPrepStationByBrand: '/api/devices/kdsPrepStations/brandId',

    apiLogSourceTypes: '/api/lookups/apiLogSourceTypes',

    deactivate: '/api',
    // POS Agent
    getPosAgentStatus: '/api/admin/posAgent/status/list',
    getFormattedHealthInfo: '/api/admin/posAgent/formattedHealthInfo/storeId',
    posAgentCheckForUpdate: '/api/admin/posAgent/checkForUpdate/storeId',
    posAgentReactivate: '/api/admin/posAgent/reactivate/storeId',
    posAgentRestart: '/api/admin/posAgent/restart/storeId',
    posAgentRefreshCache: '/api/admin/posAgent/refreshCache/storeId',
    posAgentDownloadMenuFile: '/api/admin/posAgent/menu/storeId',
    posAgentDownloadCurrentLogFile: '/api/admin/posAgent/currentLog/storeId',
    posAgentDownloadLogs: '/api/admin/posAgent/logSegment/storeId',
    posAgentHealthInfo: '/api/admin/posAgent/healthInfoStatisticsHistory/storeId',

    // Analytics
    getAnalyticsSsoUrl: '/api/analytics/ssoUrl',

    // Vouchers
    getVouchersList: '/api/vouchers/brandId',
    getVouchersById: '/api/vouchers/voucherId',
    addVouchers: '/api/vouchers/add',
    updateVouchers: '/api/vouchers/update',
    addBulkVoucher: '/api/vouchers/date/bulkAdd',
    addVoucherDate: '/api/vouchers/date/add',
    updateVoucherDate: '/api/vouchers/date/update',
    deleteVoucherDate: '/api/vouchers/date/delete/voucherDateId',
    addVoucherTime: '/api/vouchers/time/add',
    updateVoucherTime: '/api/vouchers/time/update',
    deleteVoucherTime: '/api/vouchers/time/delete/voucherTimeId',
    voucherImage: '/api/vouchers/image/voucherId',
    getVoucherSendModes: '/api/lookups/voucherSendModes',

    // Sensors
    getTableSensorsByStoreId: '/api/sensors/table/storeId',
    getStoreNamedSensorByStoreId: '/api/sensors/storeNamed/storeId',
    getVenueNamedSensorByStoreId: '/api/sensors/venueNamed/storeId',
    getOffPremiseSensorByStoreId: '/api/sensors/offPremise/storeId',
    getOffPremiseSensorTypes: '/api/lookups/offPremiseSensorTypes',

    // Admin Language
    getLanguageList: '/api/admin/language/list',
    getLanguageListLookup: '/api/lookups/languages',
    getLangaugeDefaultsById: '/api/admin/language/appCustomization/mobileApp/languageId',
    saveAdminLanguageDefault: '/api/admin/language/appCustomization/mobileApp/save',
    getServerAppLangaugeDefaultsById: '/api/admin/language/appCustomization/serverApp/languageId',
    saveServerAppAdminLanguageDefault: '/api/admin/language/appCustomization/serverApp/save',
    getKioskAppLangaugeDefaultsById: '/api/admin/language/appCustomization/kiosk/languageId',
    saveKioskAppAdminLanguageDefault: '/api/admin/language/appCustomization/kiosk/save',
    getTableTopAppLangaugeDefaultsById: '/api/admin/language/appCustomization/tableTop/languageId',
    saveTableTopAppAdminLanguageDefault: '/api/admin/language/appCustomization/tableTop/save',
    getKdsAppLanguageDefaultsById: '/api/admin/language/appCustomization/kds/languageId',
    saveKdsAppAdminLanguageDefault: '/api/admin/language/appCustomization/kds/save',
    getOrderUpAppLangaugeDefaultsById: '/api/admin/language/appCustomization/orderUp/languageId',
    saveOrderUpAppAdminLanguageDefault: '/api/admin/language/appCustomization/orderUp/save',
    getWaitListAppLangaugeDefaultsById: '/api/admin/language/appCustomization/waitList/languageId',
    saveWaitListAppAdminLanguageDefault: '/api/admin/language/appCustomization/waitList/save',
    getMwebAppLangaugeDefaultsById: '/api/admin/customization/labels/mWeb/languageId', // new mWeb UI API
    saveMwebAppAdminLanguageDefault: '/api/admin/customization/labels/mWeb/save',
    // data: '/api/admin/customization/labels/mWeb/languageId',

    // application language(mobileApp)
    getApplicationLanguage: '/api/brands/language',

    // language(kiosk) customization
    getBrandKioskCustomize: '/api/brands/customize/kiosk/brandId',
    saveKioskCustomize: '/api/brands/appCustomization/kiosk/update',

    // language(tableTop)customization
    getBrandTableTopCustomize: '/api/brands/customize/tableTop/brandId',
    saveTableTopCustomize: '/api/brands/appCustomization/tableTop/update',
    getAppCustomizationByLanguageId: '/api/admin/language/appCustomization/mobile/languageId',

    // language(kds) customization
    getBrandKdsCustomize: '/api/brands/customize/kds/brandId',
    saveKdsCustomize: '/api/brands/appCustomization/kds/update',

    // language(orderUp)customization
    getBrandOrderUpCustomize: '/api/brands/customize/orderUp/brandId',
    saveOrderUpCustomize: '/api/brands/appCustomization/orderUp/update',

    // Store Prod
    getProductionRateByStoreId: '/api/storeProductions/storeId',
    addProductionRate: '/api/storeProductions/add',
    updateProductionRate: '/api/storeProductions/update',
    deleteProductionRate: '/api/storeProductions/delete/storeProductionId',
    overrideProductionRate: '/api/storeProductions/override',
    quickAddProductionRate: '/api/storeProductions/quickAdd',
    quickDeleteProductionRate: '/api/storeProductions/quickDelete',
    getProductionOverrideReason: 'api/lookups/productionOverrideReasons',

    // kioskTheme
    getBrandKioskTheme: '/api/brands/theme/kiosk/brandId',
    updateBrandKioskTheme: '/api/brands/theme/kiosk/update',

    // TableTopTheme
    getBrandTableTopTheme: '/api/brands/theme/tableTop/brandId',
    updateBrandTableTopTheme: '/api/brands/theme/tableTop/update',

    // Cubby
    getCubby: '/api/storecubbies/storeId',
    addCubby: '/api/storecubbies/add',
    updateCubby: '/api/storecubbies/update',
    getCubbyById: '/api/storecubbies/storeCubbyId',
    addSlot: '/api/storecubbies/slots/add',
    updateSlot: '/api/storecubbies/slot/update',

    // WiselyWebhooks
    getWiselyWebhooksListConfig: '/api/brands/thirdPartyConfig/wiselyWebHook/brandId',
    updateWiselyWebhooksListConfig: '/api/brands/thirdPartyConfig/wiselyWebHook/update/brandId',

    // spendgoWebhooks
    getspendgoWebhooksListConfig: '/api/brands/thirdPartyConfig/spendgoWebHook/brandId',
    updatespendgoWebhooksListConfig: '/api/brands/thirdPartyConfig/spendgoWebHook/update/brandId',

    // Finserv
    getFiserv: '/api/brands/thirdPartyConfig/fiserv/brandId',
    updateFiserv: '/api/brands/thirdPartyConfig/fiserv/update/brandId',

    // Manage Store Items
    mangeStoreItemByType: '/api/menus/enterprise',
    getEnterpriseMenuItemsByScreenId: '/api/menus/menuItem/master/menuScreenId',
    getEnterpriseMenuItemsByStoreId: '/api/menus/enterprise/menuItem/storeId',
    getMenuByStoreId: '/api/menus/menu/brandId',

    // Menus Language Customization
    getLanguageEntity: '/api/brands/language/entityLabels/brandId',
    updateLanguageEntity: '/api/brands/language/entityLabels/brandId',

    // Financial Account
    getBrandAccountsByBrandId: '/api/brandAccounts/getOrCreate/brandId',
    getAccountTypesLookUps: '/api/lookups/accountTypes',
    getBrandAccountDataById: '/api/brandAccounts/brandAccountId',
    updateBrandAccounts: '/api/brandAccounts/update',

    getStoreAccountsByStoreId: '/api/storeAccounts/getOrCreate/storeId',
    getStoreAccountDataById: '/api/storeAccounts/storeAccountId',
    updateStoreAccounts: '/api/storeAccounts/update',
    getManagerStoreAccountsByStoreId: '/api/employeeApp/storeAccount/getOrCreate/storeId',
    // getStoreAccountDataById: '/api/storeAccounts/storeAccountId',
    // updateStoreAccounts: '/api/storeAccounts/update',


    getStoreAccountsBalance: '/api/storeAccounts/balance/storeId',
    getStoreAccountsJournal: '/api/storeAccounts/journal/storeId',
    getAccountJournalLookUps: '/api/lookups/accountJournalTypes',
    getManagerStoreAccountsBalance: '/api/employeeApp/storeAccount/balance/storeId',
    // getAccountJournalLookUps: '/api/lookups/accountJournalTypes',


    // EMELOPYEE APP
    getEmpStoreList: '/api/employeeApp/employee/link/store',
    getActiveEmpList: '/api/employeeApp/employee/active/storeId',
    getStoreDrawersList: '/api/storeDrawers/storeId',
    getStoreDrawerBalance: '/api/employeeApp/financial/account/balance/storeDrawer/storeDrawerId',
    getSafeBalance: '/api/employeeApp/financial/safe/balance',
    getEmployeeBalance: '/api/employeeApp/financial/employee/bank/balance',
    getCashBalance: '/api/employeeApp/financial/cash/balance',
    getCashOutBalance: '/api/employeeApp/financial/employee/cashOut/balance',
    getActiveEmpServerList: '/api/employeeApp/employee/active/server/storeId',
    getActiveEmpCashierList: '/api/employeeApp/employee/active/cashier/storeId',
    getCashoutSummary: '/api/employeeApp/financial/employee/cashOut/summary',
    getCashDepositBalance: '/api/employeeApp/financial/cash/deposit/balance',
    getVoidChecksByStoreId: '/api/employeeApp/check/compressed/storeId',
    getDiscountListByStoreId: '/api/employeeApp/discount/storeId',

    saveBankLoadEmployee: '/api/employeeApp/financial/employee/bank/load',
    saveBankReloadEmployee: '/api/employeeApp/financial/employee/bank/load',
    saveCashDropEmployee: '/api/employeeApp/financial/employee/cash/drop',
    savePaidInDeposit: '/api/employeeApp/financial/paidIn',
    savePaidOutWithdraw: '/api/employeeApp/financial/paidOut',
    saveCashDeposit: '/api/employeeApp/financial/cash/deposit',
    saveSafeDeposit: '/api/employeeApp/financial/safe/deposit',
    saveSafeWithdraw: '/api/employeeApp/financial/safe/withdraw',
    saveServerCashOut: '/api/employeeApp/financial/employee/server/cashOut/update',
    saveCashierCashOut: '/api/employeeApp/financial/employee/cashier/cashOut/update',
    saveCashSafeTransfer: '/api/employeeApp/financial/cash/safe/transfer',
    saveCashDepositTransfer: '/api/employeeApp/financial/cash/deposit/transfer',
    saveChecksVoid: '/api/employeeApp/check/void',
    saveChecksVoidItem: '/api/employeeApp/check/item/void',
    saveDiscountChecks: '/api/employeeApp/check/discount/post',
    saveMembers: '/api/employeeApp/member/brand/add',
    getEmpCampaignList: 'api/employeeApp/campaign/brandId',
    managerProcessRefund: '/api/employeeApp/payment/refund',
    issueManagerOffer: '/api/employeeApp/offer/issue',
    managerSearchGuest: '/api/employeeApp/member/brand/search',
    getManagerSensorGroupByRoleId: '/api/employeeApp/sensorGroup/brandId',
    getManagerStationByStore: '/api/employeeApp/station/storeId',
    getManagerSensorByStore: '/api/employeeApp/sensor/table/storeId',
    assignManagerSensorGroup: '/api/employeeApp/sensor/sensorId',
    sensorDeRegister: '/api/employeeApp/sensor/deRegister/externalId',
    managerSensorRegister: '/api/employeeApp/sensor/register',


    // reorder
    sortedData: '/api/orderTypes/reorder',
    reorderRevenueCenter: '/api/revenueCenters/reorder',

    // riskrule

    getRiskRules: '/api/brandRisks/brandId',
    deleteRiskRules: '/api/brandRisks/delete/brandRiskId',
    addRiskRule: '/api/brandRisks/add',
    updateRiskRule: '/api/brandRisks/update',
    getRiskRuleById: '/api/brandRisks/brandRiskId',
    getRiskRuleTypes: '/api/lookups/brandRiskRuleTypes',
    saveRiskRule: '/api/brandRisks/brandRiskRule/save',
    deleteRiskRule: '/api/brandRisks/brandRiskRule/delete/brandRiskRuleId',
    searchSegmentGuest: '/api/members/brand/search',

    // SIFT Fraud
    getSiftConfig: '/api/brands/thirdPartyConfig/sift/brandId',
    updateSiftConfig: '/api/brands/thirdPartyConfig/sift/update/brandId',

    // Marketman
    getMarketmanConfig: '/api/brands/thirdPartyConfig/marketMan/brandId',
    updateMarketmanConfig: '/api/brands/thirdPartyConfig/marketMan/update/brandId',

    // Apple Pay
    applePayVerification: 'api/admin/appleDomainVerification',

    // ValueTec Gift Card
    getValueTecConfig: '/api/brands/thirdPartyConfig/valuetec/brandId',
    updateValueTecConfig: '/api/brands/thirdPartyConfig/valuetec/update/brandId',

    // TSD Global
    getTSDGlodal: '/api/brands/thirdPartyConfig/tsdGlobal/brandId',
    updateTSDGlobal: '/api/brands/thirdPartyConfig/tsdGlobal/update/brandId',
    getDeviceCallCenterList: '/api/devices/callCenter/brandId',

    // Mappings
    getMappingByStoreId: '/api/lookups/posMappings/storeId',
    updateMapByStoreId: '/api/stores/posMapping/store/update/storeId',
    updateTenderTypeByStoreId: '/api/stores/posMapping/tenderType/update/storeId',
    updateOffPremiseByStoreId: '/api/stores/posMapping/offPremise/update/storeId',
    updatePOSSmsByStoreId: '/api/stores/posMapping/autoSms/update/storeId',

    // Menu Courses
    getMenuCourses: '/api/menus/course/brandId',
    addMenuCourses: '/api/menus/course/add',
    updateMenuCourses: '/api/menus/course/update',
    deleteMenuCourses: '/api/menus/course/delete/courseId',
    reorderMenuCourses: '/api/menus/course/reorder',

    // UserWay
    getUserWayConfig: '/api/brands/thirdPartyConfig/userWay/brandId',
    updateUserWayConfig: '/api/brands/thirdPartyConfig/userWay/update/brandId',

    // Cabbage Pay
    getStorePaymentSystemConfigCabbagePay: '/api/stores/paymentSystemConfig/cabbagePay/storeId',
    updateStorePaymentSystemConfigCabbagePay: '/api/stores/paymentSystemConfig/cabbagePay/update/storeId',

    // lookups
    getStatesByCountry: '/api/lookups/provinces/country',

    // Brand Marketplace
    getMarketPlaceSetup: '/api/brands/thirdPartyConfig/marketplace/brandId',
    updateMarketPlaceSetup: '/api/brands/thirdPartyConfig/marketplace/update/brandId',
    getServiceChargeByBrandId: '/api/lookups/serviceCharges/brandId',
    getStoreMenuItemsByBrandId: '/api/lookups/storeMenuItems/brandId',
    getMarketPlaceVendors: '/api/lookups/marketplaceVendors',
    getbrandItemOrderType: '/api/lookups/itemOrderTypes/brandId',

    // Happy Hour Rule
    getHappyHourByBrandId: '/api/lookups/happyHourRules/brandId',
    getHappyHours: '/api/brands/happyHour/brandId',
    updateHappyHours: '/api/brands/happyHour/update',

    // Brand KDS
    getKDSSettings: '/api/brands/kdsSettings/brandId',
    updateKDSSettings: '/api/brands/kdsSettings/update',
    getKDSStationTypes: '/api/lookups/kdsStationTypes',
    getKDSStationTypeData: '/api/brandKdsStations/brandId',
    addKDSStationTypeData: '/api/brandKdsStations/add',
    updateKDSStationTypeData: '/api/brandKdsStations/update',
    deleteKDSStationTypeData: '/api/brandKdsStations/delete/brandKdsStationId',
    getKDSStationTypeDataById: '/api/brandKdsStations/brandKdsStationId',
    getKDSLayoutsByStoreId: '/api/brands/kds/layouts/storeId',
    getKDSPrepStations: '/api/lookups/prepStations/brandId',
    getKDSBuildStations: '/api/lookups/buildStations/brandId',
    getKDSExpoStations: '/api/lookups/expoStations/brandId',
    getKDSLayoutsData: '/api/brandKdsLayouts/brandId',
    getKDSLayoutById: '/api/brandKdsLayouts/brandKdsLayoutId',
    addKDSLayout: '/api/brandKdsLayouts/add',
    updateKDSLayout: '/api/brandKdsLayouts/update',
    deleteKDSLayout: '/api/brandKdsLayouts/delete/brandKdsLayoutId',
    getKDSPrepDevices: '/api/devices/kdsPrepStations/brandId',
    getKDSBuildDevices: '/api/devices/kdsBuildStations/brandId',
    getKDSExpoDevices: '/api/devices/kdsExpoStations/brandId',
    getKDSDefaultSetup: '/api/brandKdsLayouts/setDefault/brandKdsLayoutId',
    getOrderTypeMasterByBrandId: '/api/lookups/orderTypeMasters/brandId',
    getRevenueCenterMasterByBrandId: '/api/lookups/revenueCenterMasters/brandId',
    saveKDSRule: '/api/brandKdsLayouts/rule/save',
    deleteKDSRule: '/api/brandKdsLayouts/rule/delete/brandKdsLayoutRuleId',
    getKDSPrintByBrandId: '/api/brandKdsPrints/brandId',
    getKDSPrintByStoreId: '/api/brandKdsPrints/storeId',
    getKDSPrintDataById: '/api/brandKdsPrints/brandKdsPrintId',
    addKDSPrint: '/api/brandKdsPrints/add',
    updateKDSPrint: '/api/brandKdsPrints/update',
    deleteKDSPrint: '/api/brandKdsPrints/delete/brandKdsPrintId',
    getKDSPrintDefaultSetup: '/api/brandKdsPrints/setDefault/brandKdsPrintId',
    saveKDSPrintRule: '/api/brandKdsPrints/rule/save',
    deleteKDSPrintRule: '/api/brandKdsPrints/rule/delete/brandKdsPrintRuleId',
    sortKDSLayout: '/api/brandKdsLayouts/sort',

    // Amazon Pay
    getAmazonPayPaymentConfigByStoreId: '/api/stores/paymentSystemConfig/amazonPay/storeId',
    updateAmazonPayPaymentConfigByStoreId: '/api/stores/paymentSystemConfig/amazonPay/update/storeId',

    // Brand Tile Sensors
    addTileSensorGroup: '/api/brandtiles/sensorGroup/add',
    getTileSensorGroup: '/api/brandTiles/sensorGroups/brandTileId',
    deleteTileSensorGroup: '/api/brandtiles/sensorGroup/delete',

    // KDSTheme
    getBrandKDSTheme: '/api/brands/theme/kds/brandId',
    updateBrandKDSTheme: '/api/brands/theme/kds/update',

    // Medallia
    getMedalliaConfig: '/api/brands/thirdPartyConfig/medallia/brandId',
    updateMedalliaConfig: '/api/brands/thirdPartyConfig/medallia/update/brandId',

    // Mobile App
    getMobileAppMedia: '/api/brands/mobileApp/medias/brandId',
    getMobileAppMediaById: '/api/brands/mobileApp/media/mediaId',
    addMobileAppMediaSetup: '/api/brands/mobileApp/media/add',
    updateMobileAppMediaSetup: '/api/brands/mobileApp/media/update',
    uploadMobileAppMediaImage: '/api/brands/mobileApp/media/upload/image/mediaId',
    getMobileAppPagesByBrandId: '/api/brands/mobileApp/pages/brandId',
    addMobileAppPage: '/api/brands/mobileApp/page/add',
    updateMobileAppPage: '/api/brands/mobileApp/page/update',
    mobileAppPageDataByPageID: '/api/brands/mobileApp/page/pageId',
    mobileAppPageType: '/api/lookups/mobileApp/pageTypes',
    mobileAppBuiltInPageTypes: '/api/lookups/mobileApp/builtInPageTypes',
    getMobileAppLayoutsByBrandId: '/api/brands/mobileApp/layouts/brandId',
    getMobileAppLayout: '/api/brands/mobileApp/layout/layoutId',
    addMobileAppLayout: '/api/brands/mobileApp/layout/add',
    updateMobileAppLayout: '/api/brands/mobileApp/layout/update',
    enableProdMobileAppLayout: '/api/brands/mobileApp/layout/enableForProduction/layoutId',
    enableTestMobileAppLayout: '/api/brands/mobileApp/layout/enableForTest/layoutId',
    getMobileAppLayoutDesign: '/api/brands/mobileApp/layout/design/layoutId',
    updateMobileAppLayoutDesign: '/api/brands/mobileApp/layout/design/save',
    getMobileAppPageDetail: '/api/brands/mobileApp/page/design/pageId',
    updateMobileAppPageDesign: '/api/brands/mobileApp/page/design/save',
    deleteMobileAppLayout: '/api/brands/mobileApp/layout/delete/layoutId',
    deleteMobileAppMediaSetup: '/api/brands/mobileApp/media/delete/mediaId',
    deleteMobileAppPage: '/api/brands/mobileApp/page/delete/pageId',


    // External Integrations
    getExternalIntegrations: '/api/admin/externalIntegration/companyId',
    deleteExternalIntegrations: '/api/admin/externalIntegration/delete/externalIntegrationId',
    addExternalIntegration: '/api/admin/externalIntegration/add',
    updateExternalIntegration: '/api/admin/externalIntegration/update',
    regenerateExternalIntegration: '/api/admin/externalIntegration/regenerate/externalIntegrationId',
    getExternalIntegrationTypes: '/api/lookups/webhookTypes',

    // Brand Third party - Happy Cog
    getHappyCogConfig: '/api/brands/thirdPartyConfig/happyCog/brandId',
    updateHappyCogConfig: '/api/brands/thirdPartyConfig/happyCog/update/brandId',

    // OrderUpTheme
    getBrandOrderUpTheme: '/api/brands/theme/orderUp/brandId',
    updateBrandOrderUpTheme: 'api/brands/theme/orderUp/update',

    // Mobile App Theme
    getBrandMobileAppTheme: '/api/brands/theme/customMobileApp/brandId',
    updateBrandMobileAppTheme: 'api/brands/theme/customMobileApp/update',

    // Waitlist Theme
    getBrandWaitListTheme: '/api/brands/theme/waitList/brandId',
    updateBrandWaitListTheme: '/api/brands/theme/waitList/update',

    // language(waitlist) customization
    getBrandWaitListCustomize: '/api/brands/customize/waitList/brandId',
    saveWaitListCustomize: '/api/brands/appCustomization/waitList/update',

    // Comapany > Integration Configuration
    getIntegrationConfigureByComapanyId: '/api/admin/externalIntegration/identifiers/companyId',

    // menuDeviceGroup

    addMenuDeviceGroup: '/api/menus/menu/deviceGroup/add',
    getMenuDeviceGroup: '/api/menus/menu/deviceGroups/menuId',
    deleteMenuDeviceGroup: '/api/menus/menu/DeviceGroup/delete',

    // menuItem Recipe
    getRecipePdf: '/api/menus/menuItem/recipe/pdf/menuItemId',
    uploadRecipePdf: '/api/menus/menuItem/recipe/pdf/menuItemId',
    deleteRecipePdf: '/api/menus/menuItem/recipe/pdf/menuItemId',

    // modifer Recipe
    getModiferRecipePdf: '/api/menus/modifier/recipe/pdf/modifierId',
    uploadModiferRecipePdf: '/api/menus/modifier/recipe/pdf/modifierId',
    deleteModiferRecipePdf: '/api/menus/modifier/recipe/pdf/modifierId',

    // Brand Intercard ThirdParty
    getIntercardConfig: '/api/brands/thirdPartyConfig/interCard/brandId',
    updateIntercardConfig: '/api/brands/thirdPartyConfig/interCard/update/brandId',

    // Brand POWERCARD ThirdParty
    getPowerCardConfig: '/api/brands/thirdPartyConfig/powerCard/brandId',
    updatePowerCardConfig: '/api/brands/thirdPartyConfig/powerCard/update/brandId',

    // guests
    importGuestOptIn: 'api/members/bulkUpdateOptIn/brandId',

    // Brand GIFTCARD Third Party
    getGiftCardConfig: '/api/brands/thirdPartyConfig/giftCard/brandId',
    updateGiftCardConfig: '/api/brands/thirdPartyConfig/giftCard/update/brandId',

    // ezCater
    getEzCaterLocations: 'api/brands/thirdPartyConfig/ezCater/brandId',
    getEzCardConfig: 'api/brands/thirdPartyConfig/ezCater/brandId',
    updateEzCardConfig: 'api/brands/thirdPartyConfig/ezCater/update/brandId',

    // POS Menu Load
    getPOSMenuSystem: '/api/brands/thirdPartyConfig/posSystem/brandId',
    updatePOSMenuSystem: '/api/brands/thirdPartyConfig/posSystem/update/brandId',

    // ROKT
    getRoktConfig: '/api/brands/thirdPartyConfig/rokt/brandId',
    updateRoktConfig: '/api/brands/thirdPartyConfig/rokt/update/brandId',

    // D&B Loyalty
    getDBLoyalty: '/api/brands/thirdPartyConfig/dnbLoyalty/brandId',
    updateDBLoyalty: 'api/brands/thirdPartyConfig/dnbLoyalty/update/brandId',

    // Role Templates
    getRoleTemplates: '/api/authRoleTemplates/list',
    createRoleTemplate: '/api/authRoleTemplates/create',
    updateRoleTemplate: '/api/authRoleTemplates/update',
    deleteRoleTemplate: '/api/authRoleTemplates/delete/authRoleTemplateId',
    getPermissions: '/api/authRoleTemplates/permissions',
    getAuthRoleTemplate: '/api/authRoleTemplates/authRoleTemplateId',
    updatePermissions: '/api/authRoleTemplates/update/permissions',

    // Company Role Template
    getCompanyRoleTemplate: '/api/authRoles/roleTemplates',
    getCompanyRolePermissions: '/api/authRoles/permissions',
    getRolesByCompanyId: '/api/authRoles/companyId',
    addCompanyRole: '/api/authRoles/create',
    updateCompanyRole: '/api/authRoles/update',
    deleteCompanyRole: '/api/authRoles/delete/authRoleId',
    getRoleById: '/api/authRoles/authRoleId',
    updateRolePermissions: '/api/authRoles/update/permissions',
    getRoleTemplateByRoleId: '/api/authRoles/authRoleTemplateId',

    // Permissions Role Template
    getPermissionsTemplates: '/api/authRoleTemplates/permissions/all',
    updatePermissionsTemplate: '/api/authRoleTemplates/permissions/update',

    LoginValidation: '/api/token/portal/validate',
    ReportValidation: '/api/dashboard/report/menu',

    // Admin Users
    getAdminUsersRoleTypes: '/api/adminUsers/authRoleTypes',
    getAdminUsers: '/api/adminUsers/list',
    adminUserDetail: '/api/adminUsers/userId',
    createAdminUsers: '/api/adminUsers/add',
    updateAdminUsers: '/api/adminUsers/update',
    deleteAdminUsers: '/api/adminUsers/deactivate/userId',
  },
};
